import { Button } from '@/components/ui/button'
import { PSGenMediaShape, track, useEditor } from '@troph-team/tldraw'
import RadixIconsArrowLeft from '~icons/radix-icons/arrow-left'
import RadixIconsArrowRight from '~icons/radix-icons/arrow-right'
import RadixIconsImage from '~icons/radix-icons/image'

export const InFrontOfTheCanvasGenMedia = track(() => {
  const editor = useEditor()

  const current = (() => {
    const selected = editor.getSelectedShapes()
    const selectedShape = selected.find((shape) => shape.type === 'genmedia') as
      | PSGenMediaShape
      | undefined
    if (!selectedShape) return null
    const currentAssetId = selectedShape.props.currentAssetId
    const currentIndex = selectedShape.props.assetIds.findIndex(
      (assetId) => assetId === currentAssetId
    )
    return {
      index: currentIndex,
      total: selectedShape.props.assetIds.length,
    }
  })()

  const setAsset = (index: number) => {
    const selected = editor.getSelectedShapes()
    const selectedShape = selected.find((shape) => shape.type === 'genmedia') as
      | PSGenMediaShape
      | undefined
    if (!selectedShape) return
    const assetId = selectedShape.props.assetIds[index]
    editor.updateShape({
      id: selectedShape.id,
      type: 'genmedia',
      props: { currentAssetId: assetId },
    })
  }

  return (
    <div className="flex h-10 w-64 items-center justify-center gap-1 rounded bg-muted/80 py-1 pl-2 pr-1 shadow-lg">
      <div className="flex items-center justify-center gap-1">
        <RadixIconsImage className="size-4 shrink-0" />
      </div>
      <div className="flex-1" />
      {current && (
        <div className="flex items-center justify-center gap-2">
          <Button
            variant="outline"
            className="size-8 p-0"
            onClick={() => setAsset((current.index - 1 + current.total) % current.total)}
          >
            <RadixIconsArrowLeft className="size-4" />
          </Button>

          <div className="shrink-0 text-sm tabular-nums">
            {current.index + 1} / {current.total}
          </div>

          <Button
            variant="outline"
            className="size-8 p-0"
            onClick={() => setAsset((current.index + 1) % current.total)}
          >
            <RadixIconsArrowRight className="size-4" />
          </Button>
        </div>
      )}

      <div className="flex-1" />
    </div>
  )
})
