import { usePrompt } from '@/components/ui/AlertDialogProvider'
import { useAuthDialog } from '@/states/AuthDialogProvider'
import { GenerationStateForm, useGenerationService } from '@/states/Generation'
import { ensureAssetMediaId } from '@/utils/assets/ensureAssetMediaId'
import { convertBlobToPng } from '@/utils/image/convert'
import invariant from '@/utils/invariant'
import { renderShapes } from '@/utils/shapes/renderShape'
import { uploadMedia } from '@/utils/uploadMedia'
import {
  AssetRecordType,
  Box2d,
  Editor,
  PSGenMediaShape,
  PoseShapeUtil,
  TLDefaultShape,
  TLImageShape,
  TLShapeId,
  Vec2d,
  exportAs,
  useEditor,
} from '@troph-team/tldraw'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import IonAccessibility from '~icons/ion/accessibility'
import MaterialSymbolsBackgroundReplace from '~icons/material-symbols/background-replace'
import MaterialSymbolsMerge from '~icons/material-symbols/merge'
import RadixIconsArrowDown from '~icons/radix-icons/arrow-down'
import RadixIconsArrowUp from '~icons/radix-icons/arrow-up'
import RadixIconsDownload from '~icons/radix-icons/download'
import RadixIconsFace from '~icons/radix-icons/face'
import RadixIconsHand from '~icons/radix-icons/hand'
import RadixIconsOpenInNewWindow from '~icons/radix-icons/open-in-new-window'
import RadixIconsRulerSquare from '~icons/radix-icons/ruler-square'
import RadixIconsShuffle from '~icons/radix-icons/shuffle'
import RadixIconsSize from '~icons/radix-icons/size'
import RadixIconsTrash from '~icons/radix-icons/trash'
import TeenyiconsMessageTextAlt from '~icons/teenyicons/message-text-alt-outline'
import TeenyiconsRefreshAltOutline from '~icons/teenyicons/refresh-alt-outline'

async function downloadAsset(editor: Editor, shape: TLImageShape | PSGenMediaShape) {
  const assetId = shape.type === 'genmedia' ? shape.props.currentAssetId : shape.props.assetId
  invariant(assetId, 'Asset ID not found')

  const asset = editor.getAsset(assetId)
  invariant(asset, 'Asset not found')
  invariant(asset.props.src, 'Asset src not found')

  const blob = await (await fetch(asset.props.src)).blob()
  const pngBlob = await convertBlobToPng(blob)

  invariant(pngBlob, 'Failed to convert blob to PNG')
  const url = URL.createObjectURL(pngBlob)

  const a = document.createElement('a')
  a.href = url
  a.download = `pixstudio-exported_${assetId}.png`
  a.click()

  URL.revokeObjectURL(url)
}

async function mergeInpaint(editor: Editor, firstShape: PSGenMediaShape) {
  invariant(firstShape.props.currentAssetId, 'Inpaint asset ID not found')
  const inpaintAsset = editor.getAsset(firstShape.props.currentAssetId)
  invariant(inpaintAsset, 'Inpaint asset not found')
  invariant(inpaintAsset.type === 'image', 'Inpaint asset is not an image')
  invariant(inpaintAsset.props.src, 'Inpaint asset src not found')

  invariant(typeof firstShape.meta.inpaintOfShape === 'string', 'Base shape ID not found')
  const base = editor.getShape<PSGenMediaShape>(firstShape.meta.inpaintOfShape as TLShapeId)
  invariant(base, 'Base shape not found')
  invariant(base.type === 'genmedia', 'Base shape is not a genmedia shape')

  invariant(base.props.currentAssetId, 'Base shape current asset ID not found')
  const baseAsset = editor.getAsset(base.props.currentAssetId)
  invariant(baseAsset, 'Base shape asset not found')
  invariant(baseAsset.type === 'image', 'Base shape asset is not an image')
  invariant(baseAsset.props.src, 'Base shape asset src not found')

  const baseImage = new Image()
  baseImage.crossOrigin = 'anonymous'
  baseImage.src = baseAsset.props.src
  await new Promise((resolve) => {
    baseImage.onload = resolve
  })

  const inpaintImage = new Image()
  inpaintImage.crossOrigin = 'anonymous'
  inpaintImage.src = inpaintAsset.props.src
  await new Promise((resolve) => {
    inpaintImage.onload = resolve
  })

  const canvas = document.createElement('canvas')
  canvas.width = baseAsset.props.w
  canvas.height = baseAsset.props.h
  const ctx = canvas.getContext('2d')
  invariant(ctx, 'Canvas context not found')

  ctx.drawImage(baseImage, 0, 0)

  invariant(firstShape.props.crop, 'Inpaint should have crop')
  const topLeft = Vec2d.From(firstShape.props.crop.topLeft)
  const bottomRight = Vec2d.From(firstShape.props.crop.bottomRight)
  topLeft.mulV({ x: inpaintImage.width, y: inpaintImage.height })
  bottomRight.mulV({ x: inpaintImage.width, y: inpaintImage.height })
  ctx.drawImage(
    inpaintImage,
    topLeft.x,
    topLeft.y,
    bottomRight.x - topLeft.x,
    bottomRight.y - topLeft.y,
    topLeft.x,
    topLeft.y,
    bottomRight.x - topLeft.x,
    bottomRight.y - topLeft.y
  )

  const base64 = canvas.toDataURL('image/png')

  const assetId = AssetRecordType.createId()
  editor
    .createAssets([
      {
        id: assetId,
        type: 'image',
        typeName: 'asset',
        props: {
          name: 'Merged Image ' + assetId,
          src: base64,
          w: baseAsset.props.w,
          h: baseAsset.props.h,
          mimeType: 'image/png',
          isAnimated: false,
        },
        meta: {},
      },
    ]) // FIXME history
    .deleteShape(firstShape.id) // FIXME history
    .updateShape(
      {
        id: base.id,
        type: base.type,
        props: {
          currentAssetId: assetId,
          assetIds: base.props.assetIds.map((a) => (a === base.props.currentAssetId ? assetId : a)),
        },
      },
      { squashing: true }
    )
    .deleteAssets([firstShape.props.currentAssetId, base.props.currentAssetId]) // FIXME history
    .select(base.id)
}

type Action = {
  label: string
  disabled?: boolean
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element

  onClick: () => Promise<unknown> | unknown
}

export const useSelectedShapeActions = () => {
  const { t } = useTranslation()

  const prompt = usePrompt()
  const editor = useEditor()
  const generation = useGenerationService()
  const shapes = editor.getSelectedShapes()
  const firstShape = shapes?.[0] as TLDefaultShape | undefined
  const { ensureAuthenticated } = useAuthDialog()
  const form = useFormContext<GenerationStateForm>()

  if (!shapes.length || !firstShape) return []

  const actions: Action[] = []
  const assetIdOfFirstShape =
    firstShape.type === 'genmedia'
      ? firstShape.props.currentAssetId
      : firstShape.type === 'image'
      ? firstShape.props.assetId
      : undefined
  const firstShapePending = firstShape.type === 'genmedia' ? !!firstShape.props.pending : false

  if (!['genbox', 'marker', 'marker-lasso'].includes(firstShape.type)) {
    actions.push(
      {
        label: t('action.bring-forward'),
        Icon: RadixIconsArrowUp,
        onClick: () => editor.bringForward(shapes.map((shape) => shape.id)),
      },
      {
        label: t('action.send-backward'),
        Icon: RadixIconsArrowDown,
        onClick: () => editor.sendBackward(shapes.map((shape) => shape.id)),
      }
    )
  }

  if (
    assetIdOfFirstShape &&
    (firstShape.type === 'image' || (firstShape.type === 'genmedia' && !firstShape.props.isInpaint))
  ) {
    const assumedFirstShape = firstShape as PSGenMediaShape | TLImageShape
    actions.push(
      {
        label: t('action.use-as-ipa-reference'),
        Icon: RadixIconsOpenInNewWindow,
        disabled: firstShapePending,
        onClick: async () => {
          const mediaId = await ensureAssetMediaId(editor, assetIdOfFirstShape)
          const mediaSrc = editor.getAsset(assetIdOfFirstShape)?.props.src
          invariant(mediaSrc, 'Media src not found')
          const currentMedias = form.getValues('ipAdapter.referenceImageMedias')
          if (currentMedias.some((media) => media.mediaId === mediaId)) {
            toast.info('Image already added as reference for IP Adapter')
            return
          }

          form.setValue(
            'ipAdapter.referenceImageMedias',
            [
              ...currentMedias,
              {
                id: assetIdOfFirstShape,
                previewUrl: mediaSrc,
                mediaId,
              },
            ].slice(-3)
          )
          toast.success('Added image as reference for IP Adapter')
        },
      },
      {
        label: t('action.enlarge'),
        Icon: RadixIconsSize,
        disabled: firstShapePending,
        onClick: async () => {
          const mediaId = await ensureAssetMediaId(editor, assetIdOfFirstShape)
          generation.submitEnlargeTask(assumedFirstShape, {
            enlarge: 2.0,
            enlargeModel: 'R-ESRGAN 4x+ Anime6B',
            mediaId,
          })
        },
      },
      {
        label: t('action.creative-upscale'),
        Icon: RadixIconsRulerSquare,
        disabled: firstShapePending,
        onClick: async () => {
          const generationTask = firstShape.meta.generationTask as
            | {
                parameters: {
                  modelId: string
                  prompts: string
                }
              }
            | undefined
          const mediaId = await ensureAssetMediaId(editor, assetIdOfFirstShape)
          let modelId = '1709400693561386681'
          if (generationTask && generationTask.parameters && generationTask.parameters.modelId) {
            modelId = generationTask.parameters.modelId
          }
          const prompt = generationTask?.parameters?.prompts

          generation.submitCreativeUpscaleTask(assumedFirstShape, {
            mediaId,
            modelId,
            prompt,
            creative: 0.2,
          })
        },
      },
      {
        label: t('action.estimate-pose'),
        Icon: IonAccessibility,
        disabled: firstShapePending,
        onClick: async () => {
          await ensureAuthenticated({
            subtitle: t('auth:reason.generation'),
          })
          await generation.submitSynchronousEstimatePoseTask(assumedFirstShape)
        },
      },
      {
        label: t('action.face-enhance'),
        Icon: RadixIconsFace,
        disabled: firstShapePending,
        onClick: async () => {
          await ensureAuthenticated({
            subtitle: t('auth:reason.generation'),
          })
          const mediaId = await ensureAssetMediaId(editor, assetIdOfFirstShape)
          const prompts = await prompt({
            title: 'Face Enhance',
            body: 'Face enhance prompt. Leave empty to use "best quality, clear face".',
          })
          if (prompts === null || typeof prompts === 'boolean') {
            toast.info('Face enhance task cancelled')
            return
          }
          await generation.submitFaceEnhanceTask(assumedFirstShape, {
            mediaId,
            prompts: prompts ?? 'best quality, clear face',
            steps: 25,
            strength: 0.5,
          })
        },
      },
      {
        label: t('action.remove-background'),
        Icon: MaterialSymbolsBackgroundReplace,
        disabled: !!firstShapePending,
        onClick: async () => {
          await ensureAuthenticated({
            subtitle: t('auth:reason.generation'),
          })
          await generation.submitRemoveBackgroundTask(assumedFirstShape)
        },
      }
    )
  }

  if (
    firstShape.type === 'genmedia' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !(firstShape?.meta?.generationTask as any)?.parameters?.workflow &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !(firstShape?.meta?.generationTask as any)?.parameters?.enlarge
  ) {
    actions.push(
      {
        label: t('action.use-as-reference'),
        Icon: TeenyiconsMessageTextAlt,
        onClick: async () => {
          const generationTask = firstShape.meta.generationTask as
            | {
                parameters: {
                  modelId: string
                  lora: Record<string, number>
                  prompts: string
                  width: number
                  height: number
                  samplingSteps: number
                  samplingMethod?: string
                  negativePrompts?: string
                  cfgScale?: number
                }
              }
            | undefined
          if (!generationTask || !generationTask.parameters) {
            toast.error(t('action.use-as-reference.error'))
            return
          }
          form.setValue('modelId', generationTask.parameters.modelId)
          form.setValue('modelType', undefined)
          form.setValue(
            'loras',
            Object.entries(generationTask.parameters.lora).map(([key, value]) => ({
              modelVersionId: key,
              weight: value,
            }))
          )
          form.setValue('prompts', generationTask.parameters.prompts)
          form.setValue('width', generationTask.parameters.width)
          form.setValue('height', generationTask.parameters.height)
          form.setValue('steps', generationTask.parameters.samplingSteps)
          form.setValue('samplingMethod', generationTask.parameters.samplingMethod || 'Euler a')
          form.setValue(
            'negativePrompts',
            generationTask.parameters.negativePrompts ||
              'worst quality, low quality, manga, portrait, low contrast, painting, surrealism, camera, anime, illustration, low poly, plain, simple, monochrome, industrial, mechanical, geometric patterns, drab, boring, moody, serious'
          )
          form.setValue('cfgScale', generationTask.parameters.cfgScale || 7)
        },
      },
      {
        label: t('action.re-roll'),
        Icon: TeenyiconsRefreshAltOutline,
        onClick: async () => {
          await ensureAuthenticated({
            subtitle: t('auth:reason.generation'),
          })
          await generation.submitRegenerationTask(firstShape)
        },
      },
      {
        label: t('action.variation'),
        Icon: RadixIconsShuffle,
        onClick: async () => {
          await ensureAuthenticated({
            subtitle: t('auth:reason.generation'),
          })
          await generation.submitVariationTask(firstShape)
        },
      }
    )
  }

  // meta.inpaintOfShape is mandatory to use. Don't use props.isInpaint
  if (firstShape.type === 'genmedia' && firstShape.meta.inpaintOfShape) {
    actions.push({
      label: t('action.merge-inpaint'),
      Icon: MaterialSymbolsMerge,
      disabled: !!firstShape.props.pending || !!firstShape.props.alert,
      onClick: () => mergeInpaint(editor, firstShape),
    })
  }

  if (firstShape.type === 'genmedia') {
    // check if the shape has been completely contained by another "genmedia" or "image" shape
    const containedBy = editor.getShapeAtPoint(
      {
        x: firstShape.x + firstShape.props.w / 2,
        y: firstShape.y + firstShape.props.h / 2,
      },
      {
        filter: (shape) => shape.id !== firstShape.id && ['genmedia', 'image'].includes(shape.type),
      }
    )
    if (containedBy) {
      actions.push({
        label: t('action.diffusion-merge'),
        Icon: MaterialSymbolsMerge,
        disabled: !!firstShape.props.pending || !!firstShape.props.alert,
        onClick: async () => {
          const backgroundShape = containedBy as TLImageShape | PSGenMediaShape
          const backgroundAssetId =
            backgroundShape.type === 'genmedia'
              ? backgroundShape.props.currentAssetId
              : backgroundShape.props.assetId
          invariant(backgroundAssetId, 'Background asset ID not found')

          const backgroundAsset = editor.getAsset(backgroundAssetId)
          invariant(backgroundAsset, 'Background asset not found')
          invariant(backgroundAsset.type === 'image', 'Background asset is not an image')

          const backgroundMediaId = await ensureAssetMediaId(editor, backgroundAssetId)

          const foregroundBlob = await renderShapes(editor, [firstShape.id], 'png', {
            bounds: new Box2d(
              backgroundShape.x,
              backgroundShape.y,
              backgroundShape.props.w,
              backgroundShape.props.h
            ),
            background: false,
            padding: 0,
            scale: 1,
          })
          invariant(foregroundBlob, 'Foreground blob not found')

          const id = toast.loading('Uploading asset...')
          const foregroundMedia = await uploadMedia(
            new File([foregroundBlob], 'foreground.png', { type: 'image/png' }),
            {
              isEphemeral: true,
              onProgress: (progress) => {
                toast.loading(`Uploading asset... ${Math.round(progress * 100)}%`, {
                  id,
                })
              },
            }
          )
          toast.dismiss(id)
          invariant(foregroundMedia.mediaId, 'Foreground media ID not found')

          await generation.submitDiffusionMergeTask(firstShape, backgroundShape, {
            foregroundMediaId: foregroundMedia.mediaId,
            background: {
              mediaId: backgroundMediaId,
              height: backgroundAsset.props.h,
              width: backgroundAsset.props.w,
            },
          })
        },
      })
    }
  }

  if (firstShape.type === 'pose') {
    const poseShapeUtil = editor.getShapeUtil(firstShape) as PoseShapeUtil
    actions.push(
      firstShape.props.handLeft
        ? {
            label: t('action.remove-left-hand'),
            Icon: (props) => (
              <RadixIconsHand {...props} className={clsx(props.className, '-scale-x-100')} />
            ),
            onClick: () => {
              editor.updateShape({
                id: firstShape.id,
                type: firstShape.type,
                props: {
                  handLeft: null,
                },
              })
            },
          }
        : {
            label: t('action.add-left-hand'),
            Icon: (props) => (
              <RadixIconsHand {...props} className={clsx(props.className, '-scale-x-100')} />
            ),
            onClick: () => {
              editor.updateShape(poseShapeUtil.attachDefaultHand(firstShape, 'left'))
            },
          },
      firstShape.props.handRight
        ? {
            label: t('action.remove-right-hand'),
            Icon: RadixIconsHand,
            onClick: () => {
              editor.updateShape({
                id: firstShape.id,
                type: firstShape.type,
                props: {
                  handRight: null,
                },
              })
            },
          }
        : {
            label: t('action.add-right-hand'),
            Icon: RadixIconsHand,
            onClick: () => {
              editor.updateShape(poseShapeUtil.attachDefaultHand(firstShape, 'right'))
            },
          }
    )
  }

  if (firstShape.type !== 'genbox') {
    actions.push({
      label: t('action.delete'),
      Icon: RadixIconsTrash,
      onClick: () => {
        editor.updateShapes(
          shapes.map((shape) => ({
            id: shape.id,
            type: shape.type,
            isLocked: false,
          }))
        )
        editor.deleteShapes(shapes.map((shape) => shape.id))
      },
    })
  }

  if (firstShape.type === 'genmedia' || firstShape.type === 'image') {
    // download the image directly (do not render the shape)
    actions.push({
      label: t('action.download'),
      Icon: RadixIconsDownload,
      disabled:
        !!firstShape.props.pending || firstShape.type === 'image' || !!firstShape.props.alert,
      onClick: () => downloadAsset(editor, firstShape),
    })
  } else if (firstShape.type === 'pose') {
    // download the pose as JSON
    const poseShapeUtil = editor.getShapeUtil(firstShape) as PoseShapeUtil
    actions.push({
      label: t('action.download-pose'),
      Icon: RadixIconsDownload,
      onClick: () => {
        const json = poseShapeUtil.exportJson(firstShape)
        const blob = new Blob([JSON.stringify(json)], {
          type: 'application/json',
        })
        const url = URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        a.download = `pose_${firstShape.id}.json`
        a.click()

        URL.revokeObjectURL(url)
      },
    })
  } else if (!['genbox', 'marker', 'marker-lasso'].includes(firstShape.type)) {
    actions.push({
      label: t('action.download'),
      Icon: RadixIconsDownload,
      onClick: () =>
        exportAs(
          editor,
          shapes.map((shape) => shape.id),
          'png',
          {
            padding: 0,
            background: firstShape.type === 'frame', // enable background for frame
          }
        ),
    })
  }

  return actions
}
