import { NestedPanel } from '@/components/global/NestedPanel'
import { ButtonBase } from '@/components/ui/button'
import { GenerationStateForm } from '@/states/Generation'
import { useRightDrawerState } from '@/states/RightDrawerStateProvider'
import { useGenBoxPoses } from '@/states/useGenBoxPoses'
import { getTemplatePoses, poseToSvg } from '@/utils/shapes/pose'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const poses = getTemplatePoses()

export const GenBoxPanelPoseNestedPanel = () => {
  const { t } = useTranslation()
  const { addPoseShape } = useGenBoxPoses()
  const { closeActiveOverlay } = useRightDrawerState()
  const { getValues, setValue } = useFormContext<GenerationStateForm>()

  return (
    <NestedPanel className="flex flex-col items-start justify-start gap-2">
      <div className="flex flex-col justify-start">
        <h1 className="text-lg font-bold">{t('gen.pose.label.add')}</h1>
      </div>

      <div className="size-full space-y-2 overflow-y-auto">
        <div className="grid grid-cols-2 gap-2">
          {poses.map((pose, idx) => {
            return (
              <ButtonBase
                key={idx}
                onClick={() => {
                  pose.people.forEach((person) => {
                    addPoseShape({
                      body: person.body,
                    })
                  })
                  setValue('prompts', getValues('prompts') + ', ' + pose.tags.join(', '))
                  closeActiveOverlay()
                }}
                className="group relative cursor-pointer text-clip rounded-md shadow"
              >
                <img
                  loading="lazy"
                  src={pose.exampleMediaUrl}
                  alt="pose"
                  className="w-full object-cover"
                  draggable={false}
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 transition duration-75 group-hover:bg-opacity-80" />
                {pose.people.map((person, i) => {
                  return (
                    <div className="absolute inset-0" key={i}>
                      {poseToSvg({
                        body: person.body,
                        width: pose.width,
                        height: pose.height,
                      })}
                    </div>
                  )
                })}
                <div className="absolute bottom-2 left-2">
                  <div className="flex flex-wrap gap-1 opacity-60">
                    {pose.tags.map((tag, i) => (
                      <div key={i} className="rounded-full bg-black px-2 py-1 text-2xs text-white">
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </ButtonBase>
            )
          })}
        </div>
      </div>
    </NestedPanel>
  )
}
