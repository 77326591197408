import { Button } from '@/components/ui/button'
import { FormActivationIndicator } from '@/components/ui/FormActivationIndicator'
import { Label } from '@/components/ui/label'
import { useRightDrawerState } from '@/states/RightDrawerStateProvider'
import { useGenBoxPoses } from '@/states/useGenBoxPoses'
import { track } from '@troph-team/tldraw'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { GenBoxPanelPoseNestedPanel } from './pose/GenBoxPanelPoseNestedPanel'

export const GenBoxPanelPose = track(() => {
  const { t } = useTranslation()

  const { activeOverlay, openActiveOverlay } = useRightDrawerState()
  const { poseShapes } = useGenBoxPoses()

  return (
    <>
      <AnimatePresence>
        {activeOverlay === 'pose' && <GenBoxPanelPoseNestedPanel />}
      </AnimatePresence>
      <div className="flex h-8 w-full items-center gap-2">
        <Label>{t('gen.pose.label')}</Label>
        <div className="flex-1" />
        {poseShapes.length > 0 && (
          <FormActivationIndicator
            active
            label={t('gen.pose.label.active', {
              count: poseShapes.length,
            })}
          />
        )}
        <Button onClick={() => openActiveOverlay('pose')} size="sm" variant="secondary">
          {t('gen.pose.label.add')}
        </Button>
      </div>
    </>
  )
})
