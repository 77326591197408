import { GenBoxPanel } from '@/components/canvas/shape/genbox/GenBoxPanel'
import { ShapeStylesPanel } from '@/components/canvas/ShapeStylesPanel'
import { withCustomizedErrorBoundary } from '@/components/global/ErrorBoundary'
import { Label } from '@/components/ui/label'
import { clamp, coerceToNumber } from '@/lib/numbers'
import { cn } from '@/lib/utils'
import { useEditor } from '@/states/EditorProvider'
import { useRightDrawerState } from '@/states/RightDrawerStateProvider'
import { LOCALSTORAGE_KEYS } from '@/utils/consts'
import { joinReactElementArray } from '@/utils/react'
import { Editor, TLDefaultShape, TLShape, stopEventPropagation, track } from '@troph-team/tldraw'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import RadixIconsArrowLeft from '~icons/radix-icons/arrow-left'
import RadixIconsHeight from '~icons/radix-icons/height'
import RadixIconsInfoCircled from '~icons/radix-icons/info-circled'
import RadixIconsOpacity from '~icons/radix-icons/opacity'
import RadixIconsRotateCounterClockwise from '~icons/radix-icons/rotate-counter-clockwise'
import RadixIconsWidth from '~icons/radix-icons/width'
import { useIsMd } from '../../utils/breakpoint'
import { PropertyInput } from '../ui/PropertyInput'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion'

export const ShapeBoundProperties = ({ shape }: { shape: TLShape }) => {
  return (
    <div className="p-4 pt-2">
      <div className="text-lg font-bold">{shape.type}</div>
      <div className="text-sm">
        <span className="font-mono">{shape.id}</span>
      </div>
      <textarea
        readOnly
        className="max-h-[200vh] min-h-[80vh] w-full select-text overflow-x-auto border border-zinc-200 p-2 font-mono text-2xs"
        value={JSON.stringify(shape, null, 2)}
      />
    </div>
  )
}

interface ShapeConfigurable {
  layer: boolean
  position: boolean
  size: boolean
  rotation: boolean
  opacity: boolean
}

const SHAPE_CONFIGURABLE: Record<string, ShapeConfigurable> = {
  genbox: {
    layer: false,
    position: false,
    size: false,
    rotation: false,
    opacity: false,
  },
  draw: {
    layer: false,
    position: false,
    size: false,
    rotation: false,
    opacity: false,
  },
}

const UncontrolledCollapsibleTransform: FC<PropsWithChildren<object>> = ({ children }) => {
  return (
    <Accordion collapsible type="single" className="border-b px-4" defaultValue="transform">
      <AccordionItem value="transform">
        <AccordionTrigger>
          <Label className='font-bold'>Transform of (selected object)</Label>
        </AccordionTrigger>
        <AccordionContent className="mt-2 flex flex-col gap-3">{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

const renderTransformProperties = ({ editor }: { editor: Editor }) => {
  const selectedShapes = editor.getSelectedShapes()
  const firstShape = selectedShapes?.[0] as TLShape | undefined
  const firstShapeConfigurable = SHAPE_CONFIGURABLE[firstShape?.type as string] ?? {
    layer: true,
    position: true,
    size: true,
    rotation: true,
    opacity: true,
  }

  const shapeProps = firstShape?.props as { h?: number; w?: number }

  if (selectedShapes.length > 1) {
    return [
      <div
        className="flex flex-col items-center justify-center gap-2 px-4 py-8 text-secondary-foreground"
        key="multiple"
      >
        <RadixIconsInfoCircled />
        <div className="text-sm">Multiple Objects</div>
      </div>,
    ]
  }

  if (firstShape) {
    const configurableProperties = (() => {
      const properties = []
      if (firstShapeConfigurable.position) {
        properties.push(
          <PropertyInput<number>
            key="x"
            label="X"
            value={firstShape.x}
            transformFromT={(v) => v.toFixed(2)}
            transformToT={(v) => coerceToNumber(v)}
            onValueChange={(v) => {
              editor.updateShapeById(firstShape.id, {
                x: Math.round(v),
              })
            }}
          />,
          <PropertyInput<number>
            key="y"
            label="Y"
            value={firstShape.y}
            transformFromT={(v) => v.toFixed(2)}
            transformToT={(v) => coerceToNumber(v)}
            onValueChange={(v) => {
              editor.updateShapeById(firstShape.id, {
                y: Math.round(v),
              })
            }}
          />
        )
      }

      if (firstShapeConfigurable.size && shapeProps.h !== undefined && shapeProps.w !== undefined) {
        properties.push(
          <PropertyInput<number>
            key="height"
            label={<RadixIconsHeight />}
            value={shapeProps.h as number}
            transformFromT={(v) => v.toFixed(2)}
            transformToT={(v) => coerceToNumber(v)}
            onValueChange={(v) => {
              editor.updateShapeById(firstShape.id, {
                props: {
                  ...firstShape.props,
                  h: Math.round(v),
                },
              })
            }}
          />,
          <PropertyInput<number>
            key="width"
            label={<RadixIconsWidth />}
            value={shapeProps.w as number}
            transformFromT={(v) => v.toFixed(2)}
            transformToT={(v) => coerceToNumber(v)}
            onValueChange={(v) => {
              editor.updateShapeById(firstShape.id, {
                props: {
                  ...firstShape.props,
                  w: Math.round(v),
                },
              })
            }}
          />
        )
      }

      if (firstShapeConfigurable.rotation) {
        properties.push(
          <PropertyInput<number>
            key="rotation"
            label={<RadixIconsRotateCounterClockwise />}
            value={firstShape.rotation * (180 / Math.PI)}
            transformFromT={(v) => v.toFixed(1)}
            transformToT={(v) => (coerceToNumber(v) * Math.PI) / 180}
            renderValue={(v) => `${v.toFixed(1)}°`}
            onValueChange={(v) => {
              editor.updateShapeById(firstShape.id, {
                rotation: v,
              })
            }}
          />
        )
      }

      if (firstShapeConfigurable.opacity) {
        properties.push(
          <PropertyInput<number>
            key="opacity"
            label={<RadixIconsOpacity />}
            value={firstShape.opacity * 100}
            transformFromT={(v) => v.toFixed(0)}
            transformToT={(v) => clamp(coerceToNumber(v) / 100, 0, 100)}
            renderValue={(v) => `${v.toFixed(0)}%`}
            onValueChange={(v) => {
              editor.updateShapeById(firstShape.id, {
                opacity: v,
              })
            }}
          />
        )
      }

      return properties
    })()

    const elements = []

    if (configurableProperties.length > 0)
      elements.push(
        <UncontrolledCollapsibleTransform key="transform">
          <div className="grid grid-cols-2 gap-4 p-4 pt-2" key="configurable">
            {configurableProperties}
          </div>
        </UncontrolledCollapsibleTransform>
      )

    return elements
  }

  return []
}

type RightDrawerContentTab = 'generation' | 'debug' | 'style-geo'

type TabConfig = {
  id: RightDrawerContentTab
  name: string
  elements: ReactElement[]
  className?: string
}

export const RightDrawerContent = withCustomizedErrorBoundary(
  track(() => {
    const { t } = useTranslation()

    const [tab, setTab] = useState<RightDrawerContentTab>('generation')
    const editor = useEditor()
    const selectedShapes = editor.getSelectedShapes()
    const [, startTransition] = useTransition()

    const tabs = useMemo(
      () =>
        (
          [
            {
              id: 'generation',
              name: t('gen.title'),
              elements: [<GenBoxPanel key="genbox" />],
            },
            // {
            //   id: 'layers',
            //   name: t('layers.title'),
            //   elements: [layerListContainer],
            // },
            {
              id: 'style-geo',
              name: t('style-panel.geo'),
              elements: [
                ...renderTransformProperties({ editor }),
                <ShapeStylesPanel key="shape-styles" />,
              ],
              className:
                'bg-purple-200 data-[state=active]:bg-purple-600 data-[state=active]:text-white',
            },
            {
              id: 'debug',
              name: t('debug.title'),
              elements: selectedShapes.map((shape) => (
                <ShapeBoundProperties key={shape.id} shape={shape} />
              )),
              className:
                'bg-purple-200 data-[state=active]:bg-purple-600 data-[state=active]:text-white',
            },
          ].filter(Boolean) as TabConfig[]
        ).filter((t) => t.elements.length),
      [editor, selectedShapes, t]
    )

    useEffect(() => {
      setTab((tab) => {
        if (tabs.some(({ id }) => id === tab)) return tab
        return tabs[0].id
      })
    }, [tabs])

    useEffect(() => {
      if (selectedShapes && selectedShapes.length > 0) {
        const shape = selectedShapes[0] as TLDefaultShape
        if (['genbox'].includes(shape.type)) {
          startTransition(() => {
            setTab('generation')
          })
        } else {
          startTransition(() => {
            setTab('style-geo')
          })
        }
      } else {
        startTransition(() => {
          setTab('generation')
        })
      }
    }, [selectedShapes])

    const activeTabContent = (
      <div className="flex-1 overflow-y-auto pb-8" key={tab}>
        {joinReactElementArray(
          tabs.find(({ id }) => id === tab)?.elements ?? [],
          <div className="h-px w-full bg-border" />
        )}
      </div>
    )

    return (
      <section className="flex size-full flex-col" onPointerDown={stopEventPropagation}>
        <div className="relative flex h-10 w-full items-center justify-start gap-1 rounded-md bg-gray-100/80 p-1 text-muted-foreground shadow-sm">
          {tabs.map(({ id, name, className }) => (
            <button
              key={id}
              value={id}
              className={clsx(
                'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-normal ring-offset-background transition-all data-[state=active]:flex-1 data-[state=active]:bg-background data-[state=active]:font-medium data-[state=active]:text-foreground data-[state=active]:shadow-sm hover:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 active:opacity-50 active:shadow-sm disabled:pointer-events-none disabled:opacity-50',
                className
              )}
              data-state={id === tab ? 'active' : 'inactive'}
              onClick={() => {
                startTransition(() => {
                  setTab(id)
                })
              }}
            >
              {name}
            </button>
          ))}
        </div>
        <div className="h-px w-full bg-border" />
        {activeTabContent}
      </section>
    )
  })
)
RightDrawerContent.displayName = 'track(RightDrawerContent)'

const RIGHT_DRAWER_COLLAPSED_WIDTH = 40
const DEFAULT_WIDTH = 320

export const RightDrawer = track(() => {
  const { activeOverlay, closeActiveOverlay } = useRightDrawerState()
  const [_width, setWidth] = useLocalStorage(LOCALSTORAGE_KEYS.RIGHT_DRAWER_WIDTH, 320)
  const width = _width ?? 320
  const isCollapsed = width === RIGHT_DRAWER_COLLAPSED_WIDTH
  const [resizing, setResizing] = useState(false)
  const isMdBreakpoint = useIsMd()

  useEffect(() => {
    if (isMdBreakpoint) {
      setWidth(RIGHT_DRAWER_COLLAPSED_WIDTH)
    }
  }, [isMdBreakpoint, setWidth])

  useEffect(() => {
    if (isCollapsed) {
      setResizing(false)
      closeActiveOverlay()
    }
  }, [isCollapsed])

  const nestedLayers = activeOverlay ? 1 : 0

  return (
    <motion.div
      className={clsx(
        'absolute right-outer-padding z-canvas shrink-0 origin-left rounded shadow-lg backdrop-blur-lg transition-[right,filter]',
        isCollapsed
          ? 'top-[50%] flex -translate-y-1/2 items-center bg-zinc-800/85 hover:bg-zinc-700/80 active:bg-zinc-600/75'
          : 'inset-y-outer-padding overflow-y-auto bg-background/85',
        activeOverlay && 'pointer-events-none'
      )}
      style={{ width: width }}
      animate={{
        scale: 1 - nestedLayers / 25,
        x: nestedLayers * -42 * (1 - (nestedLayers / 25) * 2),
        filter: `brightness(${1 - nestedLayers / 5})`,
      }}
      transition={{
        type: 'spring',
        stiffness: 350,
        damping: 30,
      }}
    >
      {isCollapsed ? (
        <button
          className="flex aspect-square items-center justify-center"
          style={{ width: RIGHT_DRAWER_COLLAPSED_WIDTH }}
          onPointerDown={stopEventPropagation}
          onClick={() => setWidth(DEFAULT_WIDTH)}
        >
          <RadixIconsArrowLeft className="h-6 w-auto text-white" />
        </button>
      ) : (
        <RightDrawerContent />
      )}

      {/* drag handler that can change width */}
      <div
        className={cn(
          'absolute inset-y-0 left-0 h-full w-1 cursor-ew-resize select-none overflow-hidden transition duration-75 hover:bg-border hover:brightness-75 pointer-coarse:w-2.5',
          resizing && '!bg-primary !brightness-100'
        )}
        onPointerDown={(e) => {
          e.stopPropagation()
          setResizing(true)
          const startX = e.clientX
          const startWidth = width
          const onPointerMove = (e: MouseEvent) => {
            // because this is pinned at the right, we need to subtract the change in x
            const w = startWidth + startX - e.clientX
            if (w < RIGHT_DRAWER_COLLAPSED_WIDTH * 2) {
              // '*2' is to leave some space for the cursor
              setWidth(RIGHT_DRAWER_COLLAPSED_WIDTH)
            } else {
              const newWidth = Math.round(clamp(w, 320, 500))
              setWidth(newWidth)
            }
          }
          const onPointerUp = () => {
            window.removeEventListener('pointermove', onPointerMove)
            window.removeEventListener('pointerup', onPointerUp)
            setResizing(false)
          }
          window.addEventListener('pointermove', onPointerMove)
          window.addEventListener('pointerup', onPointerUp)
        }}
      />

      <button
        className={clsx(
          'absolute inset-y-0 left-0 flex h-full w-12 select-none items-center justify-center overflow-hidden bg-secondary transition [writing-mode:vertical-rl] hover:brightness-90 active:brightness-75',
          nestedLayers > 0
            ? 'pointer-events-auto translate-x-0 opacity-100'
            : 'pointer-events-none translate-x-12 opacity-0'
        )}
        onPointerDown={stopEventPropagation}
        onClick={() => {
          closeActiveOverlay()
        }}
      >
        <RadixIconsArrowLeft className="w-auto" />
        <span>Back</span>
      </button>
    </motion.div>
  )
})
RightDrawer.displayName = 'track(RightDrawer)'
