import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { GenerationStateForm } from '@/states/Generation'
import { useLayoutEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
export const GenBoxPanelNegativePrompt = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext<GenerationStateForm>()
  const prompts = watch('prompts')

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  useLayoutEffect(() => {
    const t = textareaRef.current
    if (!t) return
    t.style.height = 'auto'
    t.style.height = t.scrollHeight + 'px'
  }, [prompts])

  return (
    <FormField
      control={control}
      name="negativePrompts"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t('gen.negative-prompts.label')}</FormLabel>
          <FormControl>
            <div className="flex flex-col">
              <Textarea
                ref={textareaRef}
                placeholder={t('gen.negative-prompts.placeholder')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
