import { Media } from '@/services/__generated__/graphql'

export const getPublicUrl = (media?: Media | null) =>
  media?.urls?.find((url) => url.variant === 'PUBLIC')?.url ?? undefined

export const getThumbnailUrl = (media?: Media | null) =>
  media?.urls?.find((url) => url.variant === 'THUMBNAIL')?.url ?? undefined

export const getStillThumbnailUrl = (media?: Media | null) =>
  media?.urls?.find((url) => url.variant === 'STILL_THUMBNAIL')?.url ?? undefined

// getEphemeralQueryParam gets the value of a query parameter from the URL, and removes it from the URL
// by using history.replaceState. This is useful for ephemeral query parameters that should only be used
// once and should take action to prevent the user from using the same query parameter again.
export const getThenRemoveQueryParam = (key: string): string | undefined => {
  // detect if URLSearchParams is supported
  if (!window.URLSearchParams) {
    return undefined
  }
  const url = new URL(window.location.href)
  const value = url.searchParams.get(key)
  if (value) {
    url.searchParams.delete(key)
    window.history.replaceState({}, '', url.toString())
  }
  return value ?? undefined
}
