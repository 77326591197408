export const toTitleCase = (str: string) => {
  return str.replace(/\b\w/g, (c) => c.toUpperCase())
}

export const randomId = (length = 24) => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let id = ''
  for (let i = 0; i < length; i++) {
    id += chars[Math.floor(Math.random() * chars.length)]
  }
  return id
}

// 1234 -> 1.23K
// 1234567 -> 1.23M
export const humanizeNumbers = (value: number) => {
  if (value < 1000) {
    return value.toLocaleString()
  } else if (value < 1000000) {
    return (value / 1000).toFixed(2) + 'K'
  } else {
    return (value / 1000000).toFixed(2) + 'M'
  }
}
