import { AccountAvatar } from '@/components/AccountAvatar'
import { Logo } from '@/components/Logo'
import { SettingsDialog } from '@/components/settings/SettingsDialog'
import { Button } from '@/components/ui/button'
import {
  EASINGS,
  MAX_ZOOM,
  MIN_ZOOM,
  stopEventPropagation,
  track,
  useEditor,
} from '@troph-team/tldraw'
import MaterialSymbolsRedo from '~icons/material-symbols/redo'
import MaterialSymbolsUndo from '~icons/material-symbols/undo'
import MaterialSymbolsZoomIn from '~icons/material-symbols/zoom-in'
import MaterialSymbolsZoomOut from '~icons/material-symbols/zoom-out'
import RadixIconsGear from '~icons/radix-icons/gear'
import RadixIconsRulerHorizontal from '~icons/radix-icons/ruler-horizontal'

import clsx from 'clsx'
import LayersPanel from '../LayersPanel'

export const CanvasCTA = track(() => {
  return (
    <>
      <div
        // 22rem: RightDrawer (w-20rem + right-1rem) + Toolbar (left-1rem)
        className="absolute bottom-outer-padding left-outer-padding"
        onPointerDown={stopEventPropagation}
      >
        <div className="relative z-canvas">
          <div className="flex flex-col">
            <LayersPanel />
            <div className="flex h-10 shrink items-end gap-4">
              {/* rounded-r-[36px]: setting this to rounded-r-full will cause the rounded-l to become unusable due to the excessive radius at the right side */}
              <div className="flex h-full items-center gap-1 overflow-hidden rounded-l rounded-r-[36px] bg-zinc-800 pr-1">
                <SettingsDialog>
                  <button className="group flex h-full items-center gap-1 rounded p-3 hover:bg-zinc-700 active:bg-zinc-900">
                    <Logo className="w-20 text-white text-opacity-90 group-hover:text-opacity-100 group-active:text-opacity-90" />
                    <RadixIconsGear className="size-4 text-white text-opacity-50 group-hover:text-opacity-100 group-active:text-opacity-90" />
                  </button>
                </SettingsDialog>

                <AccountAvatar />
              </div>

              <div className="flex h-full items-center gap-4">
                <Zoom />

                <UndoRedo />

                <SnapToggle />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

const Zoom = track(() => {
  const editor = useEditor()
  const zoomLevel = editor.getZoomLevel()
  return (
    <div className="flex items-center">
      <Button
        className="size-8 rounded-r-none p-0"
        variant="secondary"
        disabled={zoomLevel === MIN_ZOOM}
        onClick={() =>
          editor.zoomOut(undefined, {
            duration: 200,
            easing: EASINGS.easeInOutQuint,
          })
        }
      >
        <MaterialSymbolsZoomOut className="size-5" />
      </Button>

      <div className="h-full w-px bg-zinc-200" />

      <Button
        className="h-8 w-12 rounded-none text-xs tabular-nums"
        variant="secondary"
        disabled={zoomLevel === 1}
        onClick={() =>
          editor.resetZoom(undefined, {
            duration: 200,
            easing: EASINGS.easeInOutQuint,
          })
        }
      >
        {(zoomLevel * 100).toFixed(0)}%
      </Button>

      <div className="h-full w-px bg-zinc-200" />

      <Button
        className="size-8 rounded-l-none p-0"
        variant="secondary"
        disabled={zoomLevel === MAX_ZOOM}
        onClick={() =>
          editor.zoomIn(undefined, {
            duration: 200,
            easing: EASINGS.easeInOutQuint,
          })
        }
      >
        <MaterialSymbolsZoomIn className="size-5" />
      </Button>
    </div>
  )
})

const UndoRedo = track(() => {
  const editor = useEditor()

  return (
    <div className="flex items-center">
      <Button
        className="size-8 rounded-r-none p-0"
        variant="secondary"
        disabled={!editor.getCanUndo()}
        onClick={() => editor.undo()}
      >
        <MaterialSymbolsUndo className="size-6" />
      </Button>

      <div className="h-full w-px bg-zinc-200" />

      <Button
        className="size-8 rounded-l-none p-0"
        variant="secondary"
        disabled={!editor.getCanRedo()}
        onClick={() => editor.redo()}
      >
        <MaterialSymbolsRedo className="size-6" />
      </Button>
    </div>
  )
})

const SnapToggle = track(() => {
  const editor = useEditor()
  const value = editor.user.getIsSnapMode()

  return (
    <div className="flex h-full items-center gap-1">
      <Button
        className={clsx('h-8 gap-1.5 rounded px-3 py-1', !value && 'text-white drop-shadow')}
        variant={value ? 'secondary' : 'ghost'}
        onClick={() => editor.user.updateUserPreferences({ isSnapMode: !value })}
      >
        <RadixIconsRulerHorizontal className="size-6" />

        <div className="leading-none">Snap: {value ? 'On' : 'Off'}</div>
      </Button>
    </div>
  )
})
