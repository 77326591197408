import { ampli } from '@/ampli'
import { LOCALSTORAGE_KEYS } from '@/utils/consts'
import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'

export const authTokenSubject = new BehaviorSubject<string | null>(
  localStorage.getItem(LOCALSTORAGE_KEYS.PIXAI_JWT) ?? null
)
authTokenSubject.subscribe((token) => {
  if (token !== null) {
    localStorage.setItem(LOCALSTORAGE_KEYS.PIXAI_JWT, token)
  } else {
    localStorage.removeItem(LOCALSTORAGE_KEYS.PIXAI_JWT)
    ampli.client.reset()
  }
})

export const useAuthToken = () => {
  const [lastAuthToken, setLastAuthToken] = useState<string | null>(() => authTokenSubject.value)

  useEffect(() => {
    const subscription = authTokenSubject.subscribe(setLastAuthToken)
    return () => subscription.unsubscribe()
  }, [])

  return [lastAuthToken, (token: string | null) => authTokenSubject.next(token)] as const
}

export const getAuthToken = () => authTokenSubject.value
