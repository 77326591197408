import { HelpIcon } from '@/components/HelpIcon'
import { BufferedInput } from '@/components/ui/buffered-input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { InputSlider } from '@/components/ui/input-slider'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { GenerationStateForm } from '@/states/Generation'
import { MODEL_VERSION_IDS } from '@/utils/consts'
import { AnimatePresence, motion } from 'framer-motion'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const GenBoxPanelStyleMix = () => {
  const { t } = useTranslation()

  const { control, watch, setValue } = useFormContext<GenerationStateForm>()
  const value = watch('styleMix')
  const isStyleMixSupported = watch('modelId') === MODEL_VERSION_IDS.Y10

  return (
    <>
      <div className="flex w-full items-center gap-2">
        <Label htmlFor="style-mix" className="flex items-center gap-2">
          <span>{t('gen.style-mix.label')}</span>
          <HelpIcon content={t('gen.style-mix.help')} />
        </Label>
        <div className="flex-1" />
        <Switch
          id="style-mix"
          name="styleMix"
          checked={value.enabled}
          onCheckedChange={(v) => {
            setValue('styleMix.enabled', v)
          }}
          disabled={!isStyleMixSupported}
        />
      </div>

      <AnimatePresence mode="popLayout">
        {value.enabled && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <FormField
              control={control}
              name="styleMix.seed"
              render={({ field: { value, onChange, ...field } }) => (
                <FormItem className="my-1">
                  <FormLabel>{t('gen.style-mix.seed.label')}</FormLabel>
                  <FormControl>
                    <BufferedInput<string>
                      ref={field.ref}
                      value={value === undefined ? '' : value.toString()}
                      transformFromT={(v) => v}
                      transformToT={(v) => v}
                      onValueChange={(v) => {
                        if (v === '') {
                          onChange(undefined)
                        } else {
                          onChange(parseInt(v))
                        }
                      }}
                      onBlur={field.onBlur}
                      placeholder="Leave empty for random seed"
                      selectAllOnClick
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name="styleMix.weight"
              render={({ field: { value, onChange, ...field } }) => (
                <FormItem className="my-1">
                  <FormLabel>{t('gen.style-mix.weight.label')}</FormLabel>
                  <FormControl>
                    <InputSlider
                      ref={field.ref}
                      value={value}
                      onValueChange={onChange}
                      onBlur={field.onBlur}
                      min={0.0}
                      max={1.0}
                      step={0.05}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
