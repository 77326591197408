import invariant from '@/utils/invariant'
import { uploadMedia } from '@/utils/uploadMedia'
import { Editor, TLAsset, TLAssetId, TLImageAsset, TLVideoAsset } from '@troph-team/tldraw'
import { toast } from 'sonner'

type TLMediaAsset = TLImageAsset | TLVideoAsset

function assertAssetIsImageOrVideo(asset: TLAsset): asserts asset is TLMediaAsset {
  invariant(['image', 'video'].includes(asset.type), 'Asset is not an image or video')
}

export const ensureAssetMediaId = async (editor: Editor, assetId: TLAssetId) => {
  const asset = editor.getAsset(assetId)
  invariant(asset, 'Asset not found')
  assertAssetIsImageOrVideo(asset)

  if (asset.meta?.mediaId) {
    return asset.meta.mediaId as string
  }

  const src = asset?.props.src
  invariant(src, 'Unable to find media src')
  const res = await fetch(src)
  const blob = await res.blob()

  const id = toast.loading('Uploading asset...')
  const uploaded = await uploadMedia(new File([blob], asset.type, { type: asset.type + '/*' }), {
    onProgress: (progress) => {
      toast.loading(`Uploading asset... ${Math.round(progress * 100)}%`, {
        id,
      })
    },
  })
  toast.dismiss(id)
  invariant(uploaded.mediaId, 'No mediaId found')

  editor.updateAssets([
    {
      type: asset.type,
      id: assetId,
      meta: {
        mediaId: uploaded.mediaId,
      },
    },
  ])
  return uploaded.mediaId
}
