/**
 * Convert HSV to HEX
 * @see https://stackoverflow.com/a/54024653
 * @param h [0, 360]
 * @param s [0, 1]
 * @param v [0, 1]
 */
const hsvToHex = (h: number, s: number, v: number) =>
  '#' +
  [5, 3, 1]
    .map((n) => {
      const k = (n + h / 60) % 6
      const r = v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)
      return ((r * 255) | 0).toString(16).padStart(2, '0')
    })
    .join('')

/** @public */
export interface PartMeta {
  keypoints: string[]
  keypointColors: string[]
  keypointRadius: number
  displayIgnoreKeypoints?: number[]
  connections?: [number, number][]
  connectionColors?: string[]
}

const bodyColors = [
  '#ff0000',
  '#ff5500',
  '#ffaa00',
  '#ffff00',
  '#aaff00',
  '#55ff00',
  '#00ff00',
  '#00ff55',
  '#00ffaa',
  '#00ffff',
  '#00aaff',
  '#0055ff',
  '#0000ff',
  '#5500ff',
  '#aa00ff',
  '#ff00ff',
  '#ff00aa',
  '#ff0055',
]

/** @public */
export const bodyMeta: PartMeta = {
  keypointRadius: 4,
  keypoints: [
    'nose',
    'neck',
    'right_shoulder',
    'right_elbow',
    'right_wrist',
    'left_shoulder',
    'left_elbow',
    'left_wrist',
    'right_hip',
    'right_knee',
    'right_ankle',
    'left_hip',
    'left_knee',
    'left_ankle',
    'right_eye',
    'left_eye',
    'right_ear',
    'left_ear',
  ],
  connections: [
    [1, 2],
    [1, 5],
    [2, 3],
    [3, 4],
    [5, 6],
    [6, 7],
    [1, 8],
    [8, 9],
    [9, 10],
    [1, 11],
    [11, 12],
    [12, 13],
    [1, 0],
    [0, 14],
    [14, 16],
    [0, 15],
    [15, 17],
  ],
  keypointColors: bodyColors,
  connectionColors: bodyColors,
}

const handKeypoints = [
  'wrist',
  // thumb_0, thumb_1, etc.
  ...['thumb', 'index', 'middle', 'ring', 'pinky'].flatMap((finger) =>
    Array.from({ length: 4 }, (_, i) => finger + '_' + (i + 1))
  ),
]
const handConnections: [number, number][] = [
  [0, 1],
  [1, 2],
  [2, 3],
  [3, 4],
  [0, 5],
  [5, 6],
  [6, 7],
  [7, 8],
  [0, 9],
  [9, 10],
  [10, 11],
  [11, 12],
  [0, 13],
  [13, 14],
  [14, 15],
  [15, 16],
  [0, 17],
  [17, 18],
  [18, 19],
  [19, 20],
]
/** @public */
export const handMeta: PartMeta = {
  keypointRadius: 2,
  keypoints: handKeypoints,
  connections: handConnections,
  keypointColors: new Array(handKeypoints.length).fill('#0000ff'),
  connectionColors: handConnections.map((_, i) =>
    hsvToHex((i / handConnections.length) * 360, 1, 1)
  ),
  displayIgnoreKeypoints: [0],
}

const faceKeypoints = [
  ...(
    [
      ['face_outline', 17],
      ['left_eyebrow', 5],
      ['right_eyebrow', 5],
      ['nose_bridge', 4],
      ['nose_bottom', 5],
      ['left_eye_outline', 6],
      ['right_eye_outline', 6],
      ['mouth_outer_bound', 12],
      ['mouth_inner_bound', 8],
    ] as const
  ).flatMap(([name, length]) => Array.from({ length }, (_, i) => name + '_' + (i + 1))),
  'left_eyeball',
  'right_eyeball',
]
/** @public */
export const faceMeta: PartMeta = {
  keypointRadius: 2,
  keypoints: faceKeypoints,
  keypointColors: new Array(faceKeypoints.length).fill('#ffffff'),
  displayIgnoreKeypoints: [faceKeypoints.length - 2, faceKeypoints.length - 1],
}
