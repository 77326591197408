import * as SliderPrimitive from '@radix-ui/react-slider'
import * as React from 'react'

import { BufferedInput } from '@/components/ui/buffered-input'
import { clamp, coerceToNumber } from '@/lib/numbers'
import { RangeSlider } from './range-slider'

const InputRangeSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  Omit<React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>, 'value' | 'onValueChange'> & {
    value: number[]
    onValueChange: (value: number[]) => void
  }
>(({ value, onValueChange, ...props }, ref) => (
  <div className="flex items-center gap-2">
    <RangeSlider
      {...props}
      value={value}
      onValueChange={(v) => onValueChange?.(v)}
      ref={ref}
      className="w-full"
    />

    <BufferedInput<number>
      className="w-16"
      value={value[0]}
      transformFromT={(v) => v.toLocaleString()}
      transformToT={(v) => clamp(coerceToNumber(v), props.min ?? 0, value[1])}
      onValueChange={(v) => onValueChange?.([v, value[1]])}
    />
    {' - '}
    <BufferedInput<number>
      className="w-16"
      value={value[1]}
      transformFromT={(v) => v.toLocaleString()}
      transformToT={(v) => clamp(coerceToNumber(v), value[0], props.max ?? 100)}
      onValueChange={(v) => onValueChange?.([value[0], v])}
    />
  </div>
))
InputRangeSlider.displayName = SliderPrimitive.Root.displayName

export { InputRangeSlider }
