import { InputSlider } from '@/components/ui/input-slider'
import { Label } from '@/components/ui/label'
import { PSSpeedLinesShape, useEditor } from '@troph-team/tldraw'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const SpeedLinesStylesControls: FC<{
  shape: PSSpeedLinesShape
}> = ({ shape }) => {
  const { t } = useTranslation()

  const editor = useEditor()

  return (
    <div className="mt-2 flex flex-col gap-1">
      <Label>{t('style-panel.speedlines.minLength')}</Label>

      <InputSlider
        onValueChange={(value) => {
          editor.updateShape({
            id: shape.id,
            type: shape.type,
            props: {
              length: value,
            },
          })
        }}
        value={shape.props.length}
        min={0}
        max={1}
        step={0.1}
      />
    </div>
  )
}
