import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react'

type RightDrawerOverlay = 'lora' | 'pose'

type RightDrawerState = {
  activeOverlay: RightDrawerOverlay | null
  openActiveOverlay: (overlay: RightDrawerOverlay) => void
  // closeActiveOverlay: pass in overlay to close, if not passed, close any overlay
  closeActiveOverlay: (overlay?: RightDrawerOverlay) => void
}

export const RightDrawerStateContext = createContext<RightDrawerState | null>(null)

export const RightDrawerStateProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [activeOverlay, setActiveOverlay] = useState<RightDrawerOverlay | null>(null)

  const memoized = useMemo(
    () => ({
      activeOverlay,
      openActiveOverlay: (overlay: RightDrawerOverlay) => {
        setActiveOverlay(overlay)
      },
      closeActiveOverlay: (overlay?: RightDrawerOverlay) => {
        if (overlay === undefined || activeOverlay === overlay) {
          setActiveOverlay(null)
        }
      },
    }),
    [activeOverlay]
  )

  return (
    <RightDrawerStateContext.Provider value={memoized}>{children}</RightDrawerStateContext.Provider>
  )
}

export const useRightDrawerState = () => {
  const context = useContext(RightDrawerStateContext)
  if (!context) {
    throw new Error('useRightDrawerState must be used within a RightDrawerStateProvider')
  }
  return context
}
