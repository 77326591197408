import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { GenerationStateForm } from '@/states/Generation'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const SAMPLING_METHODS = [
  'Euler a',
  'Euler',
  'LMS',
  'Heun',
  'DPM2 Karras',
  'DPM2 a Karras',
  'DDIM',
  'DPM++ 2M Karras',
  'DPM++ 2S a Karras',
  'DPM++ SDE Karras',
  'DPM++ 2M SDE Karras',
]

export const GenBoxPanelSamplingMethod = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<GenerationStateForm>()

  return (
    <FormField
      control={control}
      name="samplingMethod"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t('gen.sampling-method.label')}</FormLabel>
          <FormControl>
            <Select value={field.value} onValueChange={field.onChange}>
              <SelectTrigger>
                <SelectValue placeholder="Fetching models..." />
              </SelectTrigger>
              <SelectContent>
                {SAMPLING_METHODS.map((samplingMethod) => {
                  return (
                    <SelectItem value={samplingMethod} textValue={samplingMethod}>
                      <div className="flex flex-1 items-center gap-2 text-left">
                        <div className="leading-none text-secondary-foreground">
                          {samplingMethod}
                        </div>
                      </div>
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
