import { useEditor } from '@/states/EditorProvider'
import {
  DefaultFillStyle,
  DefaultFontStyle,
  GeoShapeGeoStyle,
  track,
  useValue,
} from '@troph-team/tldraw'
import clsx from 'clsx'
import { FC, ReactNode, SVGProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { WorkspaceListContainer } from '@/components/canvas/page/WorkspaceListContainer'
import { ToolboxInpaint } from '@/components/canvas/toolbox/ToolboxMarker'
import { Drawer, DrawerContent } from '@/components/ui/drawer'
import { Kbd } from '@/components/ui/kbd'
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { ELEMENT_IDS } from '@/utils/consts'
import { useGenBoxOnPage } from '@/utils/shapes/genbox'
import { PopoverPortal } from '@radix-ui/react-popover'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { match } from 'ts-pattern'
import IonAccessibility from '~icons/ion/accessibility'
import RadixIconBox from '~icons/radix-icons/box'
import RadixIconsChatBubble from '~icons/radix-icons/chat-bubble'
import RadixIconsChevronRight from '~icons/radix-icons/chevron-right'
import RadixIconsCircle from '~icons/radix-icons/circle'
import RadixIconsCursorArrow from '~icons/radix-icons/cursor-arrow'
import RadixIconsEraser from '~icons/radix-icons/eraser'
import RadixIconsFrame from '~icons/radix-icons/frame'
import RadixIconsHand from '~icons/radix-icons/hand'
import RadixIconsImage from '~icons/radix-icons/image'
import RadixIconsPencil2 from '~icons/radix-icons/pencil-2'
import RadixIconsText from '~icons/radix-icons/text'
import RadixIconsTokens from '~icons/radix-icons/tokens'
import RadixIconsTransparencyGrid from '~icons/radix-icons/transparency-grid'
import TeenyIconsDividerLine from '~icons/teenyicons/divider-line-outline'
import FluentRhombusIcon from '~icons/fluent/rhombus-24-regular'
import FluentOvalIcon from '~icons/fluent/oval-24-regular'
import IconParkTrapezoid from '~icons/icon-park-outline/trapezoid'

import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Diamond,
  Hexagon,
  Octagon,
  Pentagon,
  Shapes,
  Star,
  Triangle,
} from 'lucide-react'

export function useInsertMedia() {
  const editor = useEditor()
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    const input = window.document.createElement('input')
    input.type = 'file'
    input.accept = 'image/jpeg,image/png,image/gif,image/svg+xml,video/mp4,video/quicktime'
    input.multiple = true
    inputRef.current = input
    async function onchange(e: Event) {
      const fileList = (e.target as HTMLInputElement).files
      if (!fileList || fileList.length === 0) return
      await editor.putExternalContent({
        type: 'files',
        files: Array.from(fileList),
        point: editor.getViewportPageBounds().center,
        ignoreParent: false,
      })
      input.value = ''
    }
    input.addEventListener('change', onchange)
    return () => {
      inputRef.current = undefined
      input.removeEventListener('change', onchange)
    }
  }, [editor])

  return useCallback(() => {
    inputRef.current?.click()
  }, [inputRef])
}

type ToolItem =
  | {
      id: string
      type: 'separator'
    }
  | {
      id: string
      selected?: boolean
      type?: never
      name: ReactNode
      icon: (props: SVGProps<SVGSVGElement>) => React.ReactElement
      onClick?: () => void
    }

type Tool =
  | {
      id: string
      type: 'tool-group'
      name: ReactNode
      tools: ToolItem[]
      icon: (props: SVGProps<SVGSVGElement>) => React.ReactElement
    }
  | ToolItem

const ToolLabel: FC<{
  name: string
  kbd?: string
}> = ({ name, kbd }) => {
  return (
    <div className="flex items-center gap-1">
      <span>{name}</span>
      {kbd && <Kbd commands={kbd} />}
    </div>
  )
}

export const Toolbar = track(() => {
  const { t } = useTranslation()

  const editor = useEditor()
  const currentTool = editor.getCurrentTool()
  const activeToolbox = useMemo(() => {
    if (currentTool.id === 'marker') return <ToolboxInpaint key="marker" />
    // if (currentTool.id === "dialogue")
    //   return <ToolboxDialogue key="dialogue" />;
    return null
  }, [currentTool.id])

  const insertMedia = useInsertMedia()

  const TOOLS: Tool[] = useValue(
    'toolbar tools',
    () => [
      {
        id: 'hand',
        name: <ToolLabel name={t('tool.hand')} kbd="H" />,
        icon: RadixIconsHand,
      },
      {
        id: 'separator-hand-genbox',
        type: 'separator' as const,
      },
      {
        id: 'select',
        name: <ToolLabel name={t('tool.select')} kbd="V" />,
        icon: RadixIconsCursorArrow,
      },
      {
        id: 'tool-group-frame',
        type: 'tool-group' as const,
        name: <ToolLabel name={t('tool.frame')} kbd="F" />,
        icon: RadixIconsFrame,
        tools: [
          {
            id: 'frame',
            name: <ToolLabel name={t('tool.frame')} kbd="F" />,
            icon: RadixIconsFrame,
          },
          {
            id: 'geoframe',
            name: <ToolLabel name={t('tool.geoframe')} kbd="G" />,
            icon: ({ className, ...props }) => (
              <RadixIconsFrame className={clsx('-skew-x-12', className)} {...props} />
            ),
          },
        ],
      },
      {
        id: 'separator-frame-divider-dialogue',
        type: 'separator' as const,
      },
      {
        id: 'tool-group-dialogue',
        type: 'tool-group' as const,
        name: <ToolLabel name={t('tool.dialogue')} />,
        icon: RadixIconsText,
        tools: [
          {
            id: 'dialogue',
            name: <ToolLabel name={t('tool.dialogue')} kbd="T" />,
            icon: RadixIconsChatBubble,
          },
          {
            id: 'text',
            name: <ToolLabel name={t('tool.text')} kbd="shift+T" />,
            icon: RadixIconsText,
            onClick: () => {
              editor.setCurrentTool('text')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
            },
          },
          {
            id: 'rich-dialogue',
            name: <ToolLabel name={t('tool.rich-dialogue')} kbd="shift+R" />,
            icon: ({ className, ...props }) => (
              <RadixIconsChatBubble
                className={clsx(
                  'rounded-full bg-gradient-to-r from-primary to-red-300 p-0.5',
                  className
                )}
                {...props}
              />
            ),
          },
          {
            id: 'rich-text',
            name: <ToolLabel name={t('tool.rich-text')} kbd="ctrl+T" />,
            icon: ({ className, ...props }) => (
              <RadixIconsText
                className={clsx(
                  'rounded-full bg-gradient-to-r from-primary to-red-300 p-0.5',
                  className
                )}
                {...props}
              />
            ),
          },
        ],
      },
      {
        id: 'tool-group-shapes',
        type: 'tool-group' as const,
        name: <ToolLabel name={t('tool.shapes')} />,
        icon: (props) => <Shapes {...props} />,
        tools: [
          {
            id: 'geo-rectangle',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'rectangle',
            name: <ToolLabel name={t('tool.rectangle')} kbd="R" />,
            icon: RadixIconBox,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'rectangle')
            },
          },
          {
            id: 'geo-ellipse',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'ellipse',
            name: <ToolLabel name={t('tool.ellipse')} kbd="O" />,
            icon: RadixIconsCircle,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'ellipse')
            },
          },
          {
            id: 'geo-triangle',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'triangle',
            name: <ToolLabel name={t('tool.triangle')} />,
            icon: (props) => <Triangle {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'triangle')
            },
          },
          {
            id: 'geo-diamond',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'diamond',
            name: <ToolLabel name={t('tool.diamond')} />,
            icon: (props) => <Diamond {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'diamond')
            },
          },
          {
            id: 'geo-pentagon',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'pentagon',
            name: <ToolLabel name={t('tool.pentagon')} />,
            icon: (props) => <Pentagon {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'pentagon')
            },
          },
          {
            id: 'geo-hexagon',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'hexagon',
            name: <ToolLabel name={t('tool.hexagon')} />,
            icon: (props) => <Hexagon {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'hexagon')
            },
          },
          {
            id: 'geo-octagon',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'octagon',
            name: <ToolLabel name={t('tool.octagon')} />,
            icon: (props) => <Octagon {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'octagon')
            },
          },
          {
            id: 'geo-oval',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'oval',
            name: <ToolLabel name={t('tool.oval')} />,
            icon: (props) => <FluentOvalIcon {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'oval')
            },
          },
          {
            id: 'geo-star',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'star',
            name: <ToolLabel name={t('tool.star')} />,
            icon: (props) => <Star {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'star')
            },
          },
          {
            id: 'geo-rhombus',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'rhombus',
            name: <ToolLabel name={t('tool.rhombus')} />,
            icon: (props) => <FluentRhombusIcon {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'rhombus')
            },
          },
          {
            id: 'geo-trapezoid',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'trapezoid',
            name: <ToolLabel name={t('tool.trapezoid')} />,
            icon: (props) => <IconParkTrapezoid {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'trapezoid')
            },
          },
          {
            id: 'geo-arrow-right',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'arrow-right',
            name: <ToolLabel name={t('tool.arrow-right')} />,
            icon: (props) => <ArrowRight {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'arrow-right')
            },
          },
          {
            id: 'geo-arrow-left',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'arrow-left',
            name: <ToolLabel name={t('tool.arrow-left')} />,
            icon: (props) => <ArrowLeft {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'arrow-left')
            },
          },
          {
            id: 'geo-arrow-up',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'arrow-up',
            name: <ToolLabel name={t('tool.arrow-up')} />,
            icon: (props) => <ArrowUp {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'arrow-up')
            },
          },
          {
            id: 'geo-arrow-down',
            selected:
              editor.getCurrentTool().id === 'geo' &&
              editor.getStyleForNextShape(GeoShapeGeoStyle) === 'arrow-down',
            name: <ToolLabel name={t('tool.arrow-down')} />,
            icon: (props) => <ArrowDown {...props} />,
            onClick: () => {
              editor.setCurrentTool('geo')
              editor.setStyleForNextShapes(DefaultFontStyle, 'serif')
              editor.setStyleForNextShapes(DefaultFillStyle, 'solid')
              editor.setStyleForNextShapes(GeoShapeGeoStyle, 'arrow-down')
            },
          },
        ],
      },
      {
        id: 'assets',
        name: <ToolLabel name={t('tool.add-image')} kbd="meta+U" />,
        icon: RadixIconsImage,
        onClick: () => {
          insertMedia()
        },
      },
      {
        id: 'tool-group_motion-lines',
        type: 'tool-group' as const,
        name: <ToolLabel name={t('tool.motion-lines')} />,
        icon: TeenyIconsDividerLine,
        tools: [
          {
            id: 'speed-lines',
            name: <ToolLabel name={t('tool.speed-lines')} kbd="shift+S" />,
            icon: TeenyIconsDividerLine,
          },
          {
            id: 'focus-lines',
            name: <ToolLabel name={t('tool.focus-lines')} kbd="shift+F" />,
            icon: TeenyIconsDividerLine,
          },
        ],
      },
      {
        id: 'separator-text-marker',
        type: 'separator' as const,
      },
      {
        id: 'tool-group_marker',
        type: 'tool-group' as const,
        name: <ToolLabel name={t('tool.marker')} />,
        icon: RadixIconsPencil2,
        tools: [
          {
            id: 'marker',
            name: <ToolLabel name={t('tool.marker.label')} kbd="I" />,
            icon: RadixIconsPencil2,
          },
          {
            id: 'marker-geo',
            name: <ToolLabel name={t('tool.marker-box.label')} kbd="shift+B" />,
            onClick: () => {
              editor.setCurrentTool('marker-geo')
            },
            icon: RadixIconsPencil2,
          },
          {
            id: 'marker-lasso',
            name: <ToolLabel name={t('tool.marker-lasso.label')} kbd="shift+I" />,
            icon: RadixIconsPencil2,
          },
          {
            id: 'marker-eraser',
            name: <ToolLabel name={t('tool.marker-eraser')} kbd="meta+I" />,
            icon: RadixIconsEraser,
          },
        ],
      },
      {
        id: 'pose',
        name: <ToolLabel name={t('tool.pose')} />,
        icon: IonAccessibility,
      },
    ],
    [insertMedia, t, editor]
  )

  return (
    <AnimatePresence mode="popLayout">
      <div
        className="absolute left-outer-padding top-outer-padding z-canvas size-10 overflow-hidden rounded bg-zinc-800 shadow-lg"
        key="workspace"
      >
        <ToolItemWorkspace />
      </div>

      <div
        key="toolbar"
        id={ELEMENT_IDS.CANVAS_TOOLBAR}
        className="absolute left-outer-padding z-canvas w-10"
        style={{
          top: 'calc(var(--outer-padding) * 2 + 2.5rem)',
        }}
      >
        <div className="flex flex-col items-center justify-center overflow-hidden rounded bg-zinc-800 shadow-lg">
          {TOOLS.map((tool) => (
            <ToolItem key={tool.id} tool={tool} />
          ))}

          <ToolItemGenBox />
        </div>
      </div>

      {activeToolbox && (
        <motion.div
          key="toolbox"
          layout
          className="absolute left-outer-padding z-canvas flex w-80 origin-bottom-left items-center rounded bg-zinc-800/80 font-bold text-white shadow-lg backdrop-blur-lg"
          initial={{
            scale: 0.8,
            opacity: 0,
          }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          exit={{
            scale: 0.8,
            opacity: 0,
          }}
          style={{
            bottom: 'calc(var(--outer-padding) * 2 + 2.5rem)',
          }}
          transition={{
            type: 'spring',
            damping: 28,
            stiffness: 250,
          }}
        >
          {activeToolbox}
        </motion.div>
      )}
    </AnimatePresence>
  )
})

const ToolItem = track(({ tool }: { tool: Tool }) => {
  const editor = useEditor()
  const current = editor.getCurrentTool()
  const [open, setOpen] = useState(false)

  if (tool.type === 'separator') {
    return <Separator orientation="horizontal" key={tool.id} className="my-px bg-zinc-600" />
  }

  const isToolSelected = (tool: Tool): boolean =>
    (tool.type === 'tool-group' && tool.tools.some((tool) => isToolSelected(tool))) ||
    (!tool.type && tool.selected === true) ||
    current.id === tool.id

  const inner = (
    <Tooltip key={tool.id}>
      <TooltipTrigger asChild>
        <button
          onClick={() => {
            if (tool.type === 'tool-group') return
            if (tool.onClick) {
              tool.onClick()
            } else {
              editor.setCurrentTool(tool.id)
            }
          }}
          className={clsx(
            'relative flex size-10 cursor-default items-center justify-center gap-2 text-white transition duration-75',
            isToolSelected(tool)
              ? 'bg-blue-500 focus-within:bg-blue-600 hover:bg-blue-600 active:bg-blue-700'
              : 'focus-within:bg-zinc-700 hover:bg-zinc-700 active:bg-zinc-900'
          )}
        >
          <span>
            <tool.icon className={clsx('size-4', tool.type === 'tool-group' && 'mr-2')} />
          </span>

          {tool.type === 'tool-group' && (
            <RadixIconsChevronRight className="absolute right-0.5 size-4 opacity-50" />
          )}
        </button>
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={2}>
        <TooltipArrow />
        <div>{tool.name}</div>
      </TooltipContent>
    </Tooltip>
  )

  if (tool.type === 'tool-group') {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger tabIndex={-1}>{inner}</PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            side="right"
            sideOffset={2}
            className="flex w-auto items-center justify-center overflow-hidden bg-zinc-800 p-0"
          >
            <div className="flex max-h-[20rem] flex-col flex-wrap">
              {tool.tools.map((tool) => (
                <ToolItem key={tool.id} tool={tool} />
              ))}
            </div>
            <PopoverArrow />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    )
  }

  return inner
})

const ToolItemGenBox = track(() => {
  const { t } = useTranslation()

  const editor = useEditor()
  const genbox = useGenBoxOnPage()

  const isEnabled = !!genbox.genbox
  const isSelected = editor.getSelectedShapes().some((shape) => shape.id === genbox.genbox?.id)

  const handleClick = () => {
    match([isEnabled, isSelected])
      .with([true, true], () => {
        // disable
        genbox.remove()
      })
      .with([true, false], () => {
        genbox.focus()
      })
      .with([false, false], () => {
        genbox.focus()
      })
      .otherwise(() => {
        throw new Error('Invalid state')
      })
  }

  const handleContextMenu = (e: React.MouseEvent) => {
    if (isEnabled) {
      e.preventDefault()
      genbox.remove()
    }
  }

  return (
    <Tooltip key="genbox">
      <TooltipTrigger asChild>
        <button
          onClick={handleClick}
          onContextMenu={handleContextMenu}
          className={clsx(
            'relative flex w-full cursor-default flex-col items-center justify-center gap-2 py-4 text-white transition duration-75',
            match([isEnabled, isSelected])
              .with(
                [true, true],
                () => 'bg-blue-500 focus-within:bg-blue-600 hover:bg-blue-600 active:bg-blue-700'
              )
              .with(
                [true, false],
                () =>
                  'bg-blue-500 opacity-50 focus-within:bg-blue-600 hover:bg-blue-600 active:bg-blue-700'
              )
              .with(
                [false, false],
                () => 'focus-within:bg-zinc-700 hover:bg-zinc-700 active:bg-zinc-900'
              )
              .otherwise(() => 'cursor-not-allowed bg-zinc-700')
          )}
          aria-label={t('tool.genbox.label')}
        >
          <span>
            <RadixIconsTransparencyGrid className="size-4" />
          </span>
          <div
            className={clsx(
              'text-xs transition [writing-mode:vertical-rl]',
              isEnabled ? 'text-zinc-100' : 'text-zinc-400'
            )}
          >
            {t('tool.genbox')}
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={2}>
        <div className="flex flex-col items-start">
          <span>{t('tool.genbox.label')}</span>
          <span>
            {match([isEnabled, isSelected])
              .with([true, true], () => t('tool.genbox.label-fragment.selected'))
              .with([true, false], () => t('tool.genbox.label-fragment.unselected'))
              .with([false, false], () => t('tool.genbox.label-fragment.disabled'))
              .otherwise(() => '/')}
          </span>
        </div>
      </TooltipContent>
    </Tooltip>
  )
})

const ToolItemWorkspace = track(() => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  return (
    <>
      <Drawer open={open} onOpenChange={setOpen} direction="left">
        <DrawerContent>
          <WorkspaceListContainer
            onSelected={() => {
              setOpen(false)
            }}
          />
        </DrawerContent>
      </Drawer>

      <Tooltip key="workspace">
        <TooltipTrigger asChild>
          <button
            onClick={handleClick}
            className={
              'relative flex h-10 w-full cursor-default flex-col items-center justify-center gap-2 text-white transition duration-75 focus-within:bg-zinc-700 hover:bg-zinc-700 active:bg-zinc-900'
            }
            aria-label="Workspace"
          >
            <RadixIconsTokens className="size-5" />
          </button>
        </TooltipTrigger>
        <TooltipContent side="right" sideOffset={2}>
          {t('page-menu.title')}
        </TooltipContent>
      </Tooltip>
    </>
  )
})
