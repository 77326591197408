import { useEffect, useState } from 'react'

// Define the type for the input argument of the hook
interface LoadableInput<T> {
  data: T | null | undefined
  isLoading?: boolean
}

type LoadableDataGetter<T, D> = (data: T | null | undefined) => D | null | undefined

// Define the types for the output of the hook
type LoadableState<T> = { state: 'loading' } | { state: 'ready'; data: T }

// The useLoadable hook implementation
export function useLoadable<T, D = T>(
  { data, isLoading }: LoadableInput<T>,
  getterFn: LoadableDataGetter<T, D> = (data) => data as unknown as D
): LoadableState<D> {
  const [state, setState] = useState<LoadableState<D>>({ state: 'loading' })

  const _data = getterFn(data)
  useEffect(() => {
    if (isLoading) {
      setState({ state: 'loading' })
    } else if (_data != null) {
      setState({ state: 'ready', data: _data })
    }
  }, [_data, isLoading])

  return state
}
