import { randomId } from '@/lib/strings'
import { createShapeId, TLHandle, VecLike } from '@troph-team/tldraw'

export const createEmptyFramePreset = () => {
  const id = createShapeId(`geoframe-${randomId(16)}`)
  return {
    id,
    type: 'frame' as const,
    props: {
      // A4 paper size in pixels
      w: 595 * 2,
      h: 842 * 2,
      name: 'Frame',
    },
  }
}

function pointsToHandles(points: [number, number][]): Record<string, TLHandle> {
  return points.reduce((acc, point, i) => {
    acc[i] = {
      id: `handle-${i}`,
      type: 'vertex',
      canBind: true,
      canSnap: true,
      index: i.toString(),
      x: point[0],
      y: point[1],
    }
    return acc
  }, {} as Record<string, TLHandle>)
}

export const createMangaFramePreset = (originPoint: VecLike) => {
  const containerFrame = {
    ...createEmptyFramePreset(),
    x: originPoint.x,
    y: originPoint.y,
  }

  const margin = 40
  const center = {
    x: containerFrame.props.w / 2,
    y: containerFrame.props.h / 2,
  }
  const separator = {
    from: [0, center.y - margin * 0],
    to: [containerFrame.props.w, center.y - margin * 8],
  }
  const getSeparatorCoords = (x: number) => {
    const m = (separator.to[1] - separator.from[1]) / (separator.to[0] - separator.from[0])
    const b = separator.from[1] - m * separator.from[0]
    return {
      x,
      y: m * x + b,
    }
  }

  const w = containerFrame.props.w / 2 - margin * 1.5

  const topLeftOrigins = [
    [margin, margin],
    [margin * 2 + w, margin],
    [margin, getSeparatorCoords(margin).y + margin * 2],
    [margin * 2 + w, getSeparatorCoords(margin * 2 + w).y + margin * 2],
  ]

  const frames = [
    {
      x: topLeftOrigins[0][0],
      y: topLeftOrigins[0][1],
      w,
      h: getSeparatorCoords(margin).y - margin,
      handles: pointsToHandles([
        [0, 0],
        [0 + w, 0],
        [0 + w, getSeparatorCoords(topLeftOrigins[0][0] + w).y],
        [0, getSeparatorCoords(topLeftOrigins[0][0]).y],
      ]),
    },
    {
      x: topLeftOrigins[1][0],
      y: topLeftOrigins[1][1],
      w,
      h: getSeparatorCoords(topLeftOrigins[1][0]).y,
      handles: pointsToHandles([
        [0, 0],
        [0 + w, 0],
        [0 + w, getSeparatorCoords(topLeftOrigins[1][0] + w).y],
        [0, getSeparatorCoords(topLeftOrigins[1][0] + 0).y],
      ]),
    },
    {
      x: topLeftOrigins[2][0],
      y: topLeftOrigins[2][1],
      w,
      h: containerFrame.props.h - getSeparatorCoords(topLeftOrigins[2][0] + 0 + w).y - margin,
      handles: pointsToHandles([
        [0, 0],
        [
          0 + w,
          getSeparatorCoords(topLeftOrigins[2][0] + 0 + w).y - topLeftOrigins[2][1] + margin * 2,
        ],
        [0 + w, containerFrame.props.h - topLeftOrigins[2][1] - margin],
        [0, containerFrame.props.h - topLeftOrigins[2][1] - margin],
      ]),
    },
    {
      x: topLeftOrigins[3][0],
      y: topLeftOrigins[3][1],
      w,
      h: containerFrame.props.h - getSeparatorCoords(topLeftOrigins[3][0] + 0 + w).y - margin,
      handles: pointsToHandles([
        [0, 0],
        [
          0 + w,
          getSeparatorCoords(topLeftOrigins[3][0] + 0 + w).y - topLeftOrigins[3][1] + margin * 2,
        ],
        [0 + w, containerFrame.props.h - topLeftOrigins[3][1] - margin],
        [0, containerFrame.props.h - topLeftOrigins[3][1] - margin],
      ]),
    },
  ]

  return [
    containerFrame,
    ...frames.map((frame) => {
      const shapeId = createShapeId(`geoframe-${randomId(16)}`)
      return {
        id: shapeId,
        type: 'geoframe' as const,
        x: frame.x,
        y: frame.y,
        parentId: containerFrame.id,
        isLocked: false,
        props: {
          w: frame.w,
          h: frame.h,
          isComplete: true,
          handles: frame.handles,
        },
      }
    }),
  ]
}

interface TLContentFramePreset {
  dimensions: VecLike
  content: unknown
  preview: React.ReactNode
}

/**
 * How to add more:
 *
 * 1. Get JSON and import it into the canvas by using Workspace -> Import JSON
 * 2. Right click, then copy the shape as SVG
 * 3. Paste the SVG into the `preview` prop of the new preset
 * 3.1. Modify SVG, remove style
 * 3.2. Modify SVG, change all stroke-width from 1 to 5
 * 4. Modify `dimensions` to fit the root shape of the JSON
 * 5. Paste JSON into the `content` prop of the new preset
 * 6. Done!
 */

export const getTLContentFramePresets = (): TLContentFramePreset[] => {
  return [
    {
      dimensions: { x: 1190, y: 1684 },
      content: {
        shapes: [
          {
            x: 246,
            y: -340.255859375,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 1190, h: 1684, name: 'Frame' },
            parentId: 'page:hXpDJUJ5b-PLEwjha6bgA',
            index: 'a1',
            id: 'shape:Mo2x_QC48dRKnpA9YEUI8',
            typeName: 'shape',
          },
          {
            x: 38.31696398331951,
            y: 47.32963871921811,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 539.3660720333614, h: 784.084368752367, name: '' },
            parentId: 'shape:Mo2x_QC48dRKnpA9YEUI8',
            index: 'a1',
            id: 'shape:cN2TgWR9Mn_2sD5iugAPV',
            typeName: 'shape',
          },
          {
            x: 617.6830360166805,
            y: 48.67036128078189,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 539.3660720333614, h: 784.084368752367, name: '' },
            parentId: 'shape:Mo2x_QC48dRKnpA9YEUI8',
            index: 'a2',
            id: 'shape:CQvEs4PzPI6F6Hcvat7c5',
            typeName: 'shape',
          },
          {
            x: 40,
            y: 856,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 1120, h: 784.084368752367, name: '' },
            parentId: 'shape:Mo2x_QC48dRKnpA9YEUI8',
            index: 'a3',
            id: 'shape:HXvVNhWt0-iFCcoduykR0',
            typeName: 'shape',
          },
        ],
        rootShapeIds: ['shape:Mo2x_QC48dRKnpA9YEUI8'],
        schema: {
          schemaVersion: 1,
          storeVersion: 4,
          recordVersions: {
            asset: {
              version: 1,
              subTypeKey: 'type',
              subTypeVersions: { image: 2, video: 2, bookmark: 0 },
            },
            camera: { version: 1 },
            document: { version: 2 },
            instance: { version: 22 },
            instance_page_state: { version: 5 },
            page: { version: 2 },
            shape: {
              version: 3,
              subTypeKey: 'type',
              subTypeVersions: {
                group: 0,
                text: 2,
                bookmark: 1,
                draw: 1,
                geo: 8,
                note: 4,
                line: 1,
                frame: 0,
                arrow: 2,
                highlight: 0,
                embed: 4,
                image: 3,
                video: 1,
                dialogue: 7,
                genbox: 0,
                genmedia: 7,
                geoframe: 2,
                marker: 1,
                'frame-divider': 0,
                pose: 0,
                'marker-lasso': 0,
                'marker-geo': 0,
              },
            },
            instance_presence: { version: 5 },
            pointer: { version: 1 },
          },
        },
        assets: [],
      },
      preview: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          direction="ltr"
          width="1190"
          height="1684"
          viewBox="246 -340.255859375 1190 1684"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <defs>
            <clipPath id="B-0a5tawOKBb3Fs2oXPL8">
              <path d="M246,-340.255859375L1436,-340.255859375L1436,1343.744140625L246,1343.744140625Z" />
            </clipPath>
            <clipPath id="L31pijRJLt9mEqmG6Txok">
              <path d="M246,-340.255859375L1436,-340.255859375L1436,1343.744140625L246,1343.744140625Z" />
            </clipPath>
            <clipPath id="dwlAea5nwuF6cVsY2-rlo">
              <path d="M246,-340.255859375L1436,-340.255859375L1436,1343.744140625L246,1343.744140625Z" />
            </clipPath>
          </defs>
          <g clip-path="url(#B-0a5tawOKBb3Fs2oXPL8)">
            <g transform="matrix(1, 0, 0, 1, 284.31, -292.9262)" opacity="1">
              <g>
                <rect
                  width="539.36"
                  height="784.08"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#L31pijRJLt9mEqmG6Txok)">
            <g transform="matrix(1, 0, 0, 1, 863.68, -291.5855)" opacity="1">
              <g>
                <rect
                  width="539.36"
                  height="784.08"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#dwlAea5nwuF6cVsY2-rlo)">
            <g transform="matrix(1, 0, 0, 1, 286, 515.74)" opacity="1">
              <g>
                <rect
                  width="1120"
                  height="784.08"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      dimensions: { x: 1190, y: 1684 },
      content: {
        shapes: [
          {
            x: 2102.6253413626673,
            y: -390.5643106163809,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 1190, h: 1684, name: 'Frame' },
            parentId: 'page:hXpDJUJ5b-PLEwjha6bgA',
            index: 'a2',
            id: 'shape:mstgSHvKeM7S0yNI6iPcm',
            typeName: 'shape',
          },
          {
            x: 352,
            y: 248,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'geoframe',
            props: {
              w: 1,
              h: 1,
              isComplete: true,
              handles: {
                a0: {
                  id: 'handle-0',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a0',
                  x: -323.99792885563056,
                  y: -183.9632145674491,
                },
                a1: {
                  id: 'handle-1',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a1',
                  x: 804.236362620778,
                  y: -185.61792897079283,
                },
                a2: {
                  id: 'handle-2',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a2',
                  x: 804.8734933758096,
                  y: 137.62106265784286,
                },
                a3: {
                  id: 'handle-3',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a3',
                  x: -325.53395470288615,
                  y: 358.8485091409606,
                },
              },
            },
            parentId: 'shape:mstgSHvKeM7S0yNI6iPcm',
            index: 'a1',
            id: 'shape:bm9z28OCmAykt7RzWxjFN',
            typeName: 'shape',
          },
          {
            x: 352,
            y: 872,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'geoframe',
            props: {
              w: 1,
              h: 1,
              isComplete: true,
              handles: {
                a0: {
                  id: 'handle-0',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a0',
                  x: -324.2719750845241,
                  y: 122.85230892255535,
                },
                a1: {
                  id: 'handle-1',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a1',
                  x: 804.8631404150757,
                  y: 122.85230892255498,
                },
                a2: {
                  id: 'handle-2',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a2',
                  x: 804.8631404150756,
                  y: -454.7856911999573,
                },
                a3: {
                  id: 'handle-3',
                  type: 'vertex',
                  canBind: true,
                  canSnap: true,
                  index: 'a3',
                  x: -324.51856330019564,
                  y: -234.30626649321687,
                },
              },
            },
            parentId: 'shape:mstgSHvKeM7S0yNI6iPcm',
            index: 'a2',
            id: 'shape:p0ByXpCl1W0oT9DTrh-CH',
            typeName: 'shape',
          },
          {
            x: 28,
            y: 1040,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 547.3660720333614, h: 600, name: '' },
            parentId: 'shape:mstgSHvKeM7S0yNI6iPcm',
            index: 'a3',
            id: 'shape:lJ_XdrC9RFZm3nwcNXcKV',
            typeName: 'shape',
          },
          {
            x: 612,
            y: 1040,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 544.0000000000005, h: 600, name: '' },
            parentId: 'shape:mstgSHvKeM7S0yNI6iPcm',
            index: 'a4',
            id: 'shape:3oBNgqcjBi1xVmk-OLNDB',
            typeName: 'shape',
          },
        ],
        rootShapeIds: ['shape:mstgSHvKeM7S0yNI6iPcm'],
        schema: {
          schemaVersion: 1,
          storeVersion: 4,
          recordVersions: {
            asset: {
              version: 1,
              subTypeKey: 'type',
              subTypeVersions: { image: 2, video: 2, bookmark: 0 },
            },
            camera: { version: 1 },
            document: { version: 2 },
            instance: { version: 22 },
            instance_page_state: { version: 5 },
            page: { version: 2 },
            shape: {
              version: 3,
              subTypeKey: 'type',
              subTypeVersions: {
                group: 0,
                text: 2,
                bookmark: 1,
                draw: 1,
                geo: 8,
                note: 4,
                line: 1,
                frame: 0,
                arrow: 2,
                highlight: 0,
                embed: 4,
                image: 3,
                video: 1,
                dialogue: 7,
                genbox: 0,
                genmedia: 7,
                geoframe: 2,
                marker: 1,
                'frame-divider': 0,
                pose: 0,
                'marker-lasso': 0,
                'marker-geo': 0,
              },
            },
            instance_presence: { version: 5 },
            pointer: { version: 1 },
          },
        },
        assets: [],
      },
      preview: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          direction="ltr"
          width="1190"
          height="1684"
          viewBox="2102.62 -390.5643106163809 1190 1684"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <defs>
            <clipPath id="SMumHznfmMZBDL-rAJb7m">
              <path d="M2102.6253413626673,-390.5643106163809L3292.6253413626673,-390.5643106163809L3292.6253413626673,1293.435689383619L2102.6253413626673,1293.435689383619Z" />
            </clipPath>
            <clipPath id="crx5xtAszqUtJYXgvLHTl">
              <path d="M2102.6253413626673,-390.5643106163809L3292.6253413626673,-390.5643106163809L3292.6253413626673,1293.435689383619L2102.6253413626673,1293.435689383619Z" />
            </clipPath>
            <clipPath id="3pfZnh7U3ZWMAZkFQ2bxC">
              <path d="M2102.6253413626673,-390.5643106163809L3292.6253413626673,-390.5643106163809L3292.6253413626673,1293.435689383619L2102.6253413626673,1293.435689383619Z" />
            </clipPath>
            <clipPath id="JqDtY0PgniuIPrTqSczHZ">
              <path d="M2102.6253413626673,-390.5643106163809L3292.6253413626673,-390.5643106163809L3292.6253413626673,1293.435689383619L2102.6253413626673,1293.435689383619Z" />
            </clipPath>
          </defs>
          <g clip-path="url(#SMumHznfmMZBDL-rAJb7m)">
            <g transform="matrix(1, 0, 0, 1, 2454.62, -142.5643)" opacity="1">
              <g>
                <path
                  width="1"
                  height="1"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  d="M -323.99792885563056,-183.9632145674491 L 804.23,-185.61792897079283 L 804.87,137.62106265784286 L -325.53395470288615,358.8485091409606 Z"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#crx5xtAszqUtJYXgvLHTl)">
            <g transform="matrix(1, 0, 0, 1, 2454.62, 481.43)" opacity="1">
              <g>
                <path
                  width="1"
                  height="1"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  d="M -324.2719750845241,122.85230892255535 L 804.86,122.85230892255498 L 804.86,-454.7856911999573 L -324.51856330019564,-234.30626649321687 Z"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#3pfZnh7U3ZWMAZkFQ2bxC)">
            <g transform="matrix(1, 0, 0, 1, 2130.62, 649.43)" opacity="1">
              <g>
                <rect
                  width="547.36"
                  height="600"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#JqDtY0PgniuIPrTqSczHZ)">
            <g transform="matrix(1, 0, 0, 1, 2714.62, 649.43)" opacity="1">
              <g>
                <rect
                  width="544.00"
                  height="600"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      dimensions: { x: 1190, y: 1684 },
      content: {
        shapes: [
          {
            x: 3704,
            y: 0,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 1190, h: 1684, name: 'Frame' },
            parentId: 'page:hXpDJUJ5b-PLEwjha6bgA',
            index: 'a4',
            id: 'shape:UDUzYl9R5Qf8zYsqSmEXD',
            typeName: 'shape',
          },
          {
            x: 30.31696398331951,
            y: 51.32963871921811,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 539.3660720333614, h: 504.6703612807819, name: '' },
            parentId: 'shape:UDUzYl9R5Qf8zYsqSmEXD',
            index: 'a1',
            id: 'shape:Ydppok0Wwe4yofnANY-iH',
            typeName: 'shape',
          },
          {
            x: 609.6830360166805,
            y: 52.67036128078189,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 539.3660720333614, h: 1039.329638719218, name: '' },
            parentId: 'shape:UDUzYl9R5Qf8zYsqSmEXD',
            index: 'a2',
            id: 'shape:8Rl7p3wjUCzcjI4hOxtZm',
            typeName: 'shape',
          },
          {
            x: 32,
            y: 588,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 539.3660720333614, h: 504.6703612807819, name: '' },
            parentId: 'shape:UDUzYl9R5Qf8zYsqSmEXD',
            index: 'a3',
            id: 'shape:RdOMcAFoIudvmHjk5CrdT',
            typeName: 'shape',
          },
          {
            x: 32,
            y: 1132,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 1120.000000000001, h: 504.6703612807819, name: '' },
            parentId: 'shape:UDUzYl9R5Qf8zYsqSmEXD',
            index: 'a4',
            id: 'shape:YUjPHWrOSkKpLYbtAnieq',
            typeName: 'shape',
          },
        ],
        rootShapeIds: ['shape:UDUzYl9R5Qf8zYsqSmEXD'],
        schema: {
          schemaVersion: 1,
          storeVersion: 4,
          recordVersions: {
            asset: {
              version: 1,
              subTypeKey: 'type',
              subTypeVersions: { image: 2, video: 2, bookmark: 0 },
            },
            camera: { version: 1 },
            document: { version: 2 },
            instance: { version: 22 },
            instance_page_state: { version: 5 },
            page: { version: 2 },
            shape: {
              version: 3,
              subTypeKey: 'type',
              subTypeVersions: {
                group: 0,
                text: 2,
                bookmark: 1,
                draw: 1,
                geo: 8,
                note: 4,
                line: 1,
                frame: 0,
                arrow: 2,
                highlight: 0,
                embed: 4,
                image: 3,
                video: 1,
                dialogue: 7,
                genbox: 0,
                genmedia: 7,
                geoframe: 2,
                marker: 1,
                'frame-divider': 0,
                pose: 0,
                'marker-lasso': 0,
                'marker-geo': 0,
              },
            },
            instance_presence: { version: 5 },
            pointer: { version: 1 },
          },
        },
        assets: [],
      },
      preview: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          direction="ltr"
          width="1190"
          height="1684"
          viewBox="3704 0 1190 1684"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <defs>
            <clipPath id="dRVA8ZtUg12dl-71ByhVQ">
              <path d="M3704,0L4894,0L4894,1684L3704,1684Z" />
            </clipPath>
            <clipPath id="2g_iTuZuMXlurrk9fg-dr">
              <path d="M3704,0L4894,0L4894,1684L3704,1684Z" />
            </clipPath>
            <clipPath id="Ii3cR_todZKeRc0sLZdZS">
              <path d="M3704,0L4894,0L4894,1684L3704,1684Z" />
            </clipPath>
            <clipPath id="NEL_dojq0H0I6zN9FwMhO">
              <path d="M3704,0L4894,0L4894,1684L3704,1684Z" />
            </clipPath>
          </defs>
          <g clip-path="url(#dRVA8ZtUg12dl-71ByhVQ)">
            <g transform="matrix(1, 0, 0, 1, 3734.31, 51.32)" opacity="1">
              <g>
                <rect
                  width="539.36"
                  height="504.67"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#2g_iTuZuMXlurrk9fg-dr)">
            <g transform="matrix(1, 0, 0, 1, 4313.68, 52.67)" opacity="1">
              <g>
                <rect
                  width="539.36"
                  height="1039.32"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#Ii3cR_todZKeRc0sLZdZS)">
            <g transform="matrix(1, 0, 0, 1, 3736, 588)" opacity="1">
              <g>
                <rect
                  width="539.36"
                  height="504.67"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#NEL_dojq0H0I6zN9FwMhO)">
            <g transform="matrix(1, 0, 0, 1, 3736, 1132)" opacity="1">
              <g>
                <rect
                  width="1120.00"
                  height="504.67"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      dimensions: { x: 1190, y: 1684 },
      content: {
        shapes: [
          {
            x: 4952,
            y: 0,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 1190, h: 1684, name: 'Frame' },
            parentId: 'page:hXpDJUJ5b-PLEwjha6bgA',
            index: 'a3',
            id: 'shape:ejibkAB8S-l1fqi_P_itF',
            typeName: 'shape',
          },
          {
            x: 414.66666666666697,
            y: 60,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 360.0000000000002, h: 384, name: '' },
            parentId: 'shape:ejibkAB8S-l1fqi_P_itF',
            index: 'a1',
            id: 'shape:KYHCAJuFTlbOAsmfpliQe',
            typeName: 'shape',
          },
          {
            x: 798.666666666667,
            y: 60,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 360.0000000000002, h: 384, name: '' },
            parentId: 'shape:ejibkAB8S-l1fqi_P_itF',
            index: 'a2',
            id: 'shape:ixQqs6e_2RlQZUmpLgKVw',
            typeName: 'shape',
          },
          {
            x: 614.666666666667,
            y: 1036,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 544.0000000000005, h: 600, name: '' },
            parentId: 'shape:ejibkAB8S-l1fqi_P_itF',
            index: 'a3',
            id: 'shape:KjVxayFBOjqOmT8gQy_JS',
            typeName: 'shape',
          },
          {
            x: 30.66666666666697,
            y: 60,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 360.0000000000002, h: 384, name: '' },
            parentId: 'shape:ejibkAB8S-l1fqi_P_itF',
            index: 'a4',
            id: 'shape:OxRSjm1Ys_DwGBjA528Wv',
            typeName: 'shape',
          },
          {
            x: 30.66666666666697,
            y: 484,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 1128.0000000000007, h: 512, name: '' },
            parentId: 'shape:ejibkAB8S-l1fqi_P_itF',
            index: 'a5',
            id: 'shape:D1PF-dkL071rpHdZnkrw0',
            typeName: 'shape',
          },
          {
            x: 30.66666666666697,
            y: 1036,
            rotation: 0,
            isLocked: false,
            opacity: 1,
            meta: {},
            type: 'frame',
            props: { w: 547.3660720333614, h: 600, name: '' },
            parentId: 'shape:ejibkAB8S-l1fqi_P_itF',
            index: 'a6',
            id: 'shape:_DO7vFoaIv-1PY5qmRsXe',
            typeName: 'shape',
          },
        ],
        rootShapeIds: ['shape:ejibkAB8S-l1fqi_P_itF'],
        schema: {
          schemaVersion: 1,
          storeVersion: 4,
          recordVersions: {
            asset: {
              version: 1,
              subTypeKey: 'type',
              subTypeVersions: { image: 2, video: 2, bookmark: 0 },
            },
            camera: { version: 1 },
            document: { version: 2 },
            instance: { version: 22 },
            instance_page_state: { version: 5 },
            page: { version: 2 },
            shape: {
              version: 3,
              subTypeKey: 'type',
              subTypeVersions: {
                group: 0,
                text: 2,
                bookmark: 1,
                draw: 1,
                geo: 8,
                note: 4,
                line: 1,
                frame: 0,
                arrow: 2,
                highlight: 0,
                embed: 4,
                image: 3,
                video: 1,
                dialogue: 7,
                genbox: 0,
                genmedia: 7,
                geoframe: 2,
                marker: 1,
                'frame-divider': 0,
                pose: 0,
                'marker-lasso': 0,
                'marker-geo': 0,
              },
            },
            instance_presence: { version: 5 },
            pointer: { version: 1 },
          },
        },
        assets: [],
      },
      preview: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          direction="ltr"
          width="1190"
          height="1684"
          viewBox="4952 0 1190 1684"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <defs>
            <clipPath id="2TwGNAMgFQb_Y5zlWGIbi">
              <path d="M4952,0L6142,0L6142,1684L4952,1684Z" />
            </clipPath>
            <clipPath id="6zszCvaSiM1cy_psb8132">
              <path d="M4952,0L6142,0L6142,1684L4952,1684Z" />
            </clipPath>
            <clipPath id="_1x873h3XfVjqPXbet8xB">
              <path d="M4952,0L6142,0L6142,1684L4952,1684Z" />
            </clipPath>
            <clipPath id="lHaHQVYqAGRUV1j8gnaJ_">
              <path d="M4952,0L6142,0L6142,1684L4952,1684Z" />
            </clipPath>
            <clipPath id="vywNcjFANNl06SbpTMCjc">
              <path d="M4952,0L6142,0L6142,1684L4952,1684Z" />
            </clipPath>
            <clipPath id="FDUl3EYy5ko8P3m60dL8d">
              <path d="M4952,0L6142,0L6142,1684L4952,1684Z" />
            </clipPath>
          </defs>
          <g clip-path="url(#2TwGNAMgFQb_Y5zlWGIbi)">
            <g transform="matrix(1, 0, 0, 1, 5366.66, 60)" opacity="1">
              <g>
                <rect
                  width="360.00"
                  height="384"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#6zszCvaSiM1cy_psb8132)">
            <g transform="matrix(1, 0, 0, 1, 5750.66, 60)" opacity="1">
              <g>
                <rect
                  width="360.00"
                  height="384"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#_1x873h3XfVjqPXbet8xB)">
            <g transform="matrix(1, 0, 0, 1, 5566.66, 1036)" opacity="1">
              <g>
                <rect
                  width="544.00"
                  height="600"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#lHaHQVYqAGRUV1j8gnaJ_)">
            <g transform="matrix(1, 0, 0, 1, 4982.66, 60)" opacity="1">
              <g>
                <rect
                  width="360.00"
                  height="384"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#vywNcjFANNl06SbpTMCjc)">
            <g transform="matrix(1, 0, 0, 1, 4982.66, 484)" opacity="1">
              <g>
                <rect
                  width="1128.00"
                  height="512"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
          <g clip-path="url(#FDUl3EYy5ko8P3m60dL8d)">
            <g transform="matrix(1, 0, 0, 1, 4982.66, 1036)" opacity="1">
              <g>
                <rect
                  width="547.36"
                  height="600"
                  fill="#fcfffe"
                  stroke="#1d1d1d"
                  stroke-width="5"
                  rx="1"
                  ry="1"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
    },
  ]
}
