import { useState, useTransition } from 'react'
import { useMount } from 'react-use'

export const useDelayedMount = (delay: number = 16 * 10) => {
  const [delayedMount, setDelayedMount] = useState(false)
  const [, startTransition] = useTransition()

  useMount(() => {
    setTimeout(() => {
      startTransition(() => {
        setDelayedMount(true)
      })
    }, delay)
  })

  return delayedMount
}
