import { gql } from '@/services/__generated__'

export const COMMON_FRAGMENTS = gql(`
  fragment MediaBase on Media {
    __typename
    id
    type
    width
    height
    urls {
      variant
      url
    }
    imageType
    fileUrl
    duration
    thumbnailUrl
    hlsUrl
    size

    flag {
      ...ModerationFlagBase
    }
  }

  fragment ArtworkBase on Artwork {
    __typename
    id
    title
    authorId
    authorName
    mediaId
    prompts
    createdAt
    updatedAt
    media {
      ...MediaBase
    }
    videoMediaId
    videoMedia {
      ...MediaBase
    }
    hidePrompts
    isPrivate

    isNsfw
    isSensitive

    extra

    deriveThemeId
    rootThemeId

    type

    flag {
      ...ModerationFlagBase
    }
  }

  fragment ArtworkDetail on Artwork {
    ...ArtworkBase
    author {
      followedByMe
      ...UserBase
    }
    tags {
      ...TagBase
    }
    likedCount
    liked
    views
    commentCount
    inspiredCount
    flag {
      ...ModerationFlagDetail
    }
  }

  fragment MangaBase on Manga {
    __typename
    id
    authorId
    title
    description
    coverMediaId
    coverMedia {
      ...MediaBase
    }

    isNsfw
    isPrivate
    isArchived
    type
    extra
    createdAt
    updatedAt

    artworkId
  }

  fragment Manga on Manga {
    ...MangaBase

    author {
      ...UserBase
    }

    likedCount
    liked
  }

  fragment MangaWithFirstChapter on Manga {
    ...Manga

    chapter(index: 0) {
      ...MangaChapterBase
    }
  }

  fragment UserLikedManga on Manga {
    ...MangaWithFirstChapter

    artwork {
      ...ArtworkBase
    }
  }

  fragment MangaWithArtwork on Manga {
    ...Manga

    artwork {
      ...ArtworkBase
    }
  }

  fragment MangaChapterBase on MangaChapter {
    id
    mangaId
    order
    title
    coverMediaId
    coverMedia {
      ...MediaBase
    }
    originalData
    content
    createdAt
    updatedAt
  }

  fragment TagBase on Tag {
    id
    name
    mediaId
    media {
      ...MediaBase
    }
    category
    weight
    rootTagId
    createdAt
    updatedAt
    extra
  }

  fragment TagWithRoot on Tag {
    ...TagBase
    rootTag {
      ...TagBase
    }
  }

  fragment UserBase on User {
    id
    email
    emailVerified
    username
    displayName
    createdAt
    updatedAt
    avatarMedia {
      ...MediaBase
    }

    membership {
      membershipId
      tier
    }

    isAdmin
  }

  fragment UserDetail on User {
    ...UserBase
    coverMedia {
      ...MediaBase
    }

    followedByMe
    followingMe
    followerCount
    followingCount
    inspiredCount
  }

  fragment RoleBase on Role {
    id
    name
  }

  fragment ExternalProfileBase on ExternalProfile {
    provider
    externalId
    name
    createdAt
    updatedAt
  }

  fragment MessageBase on Message {
    id
    topicId
    authorId
    author {
      ...UserBase
    }
    createdAt
    updatedAt
    type
    content
    mediaId
    media {
      ...MediaBase
    }
    extra
    nonce
    replyToMessageId
  }

  fragment ReviewSummaryBase on ReviewSummary {
    itemId
    score
    ratingDetail {
      rating
      count
    }
  }

  fragment ReviewBase on Review {
    id
    userId
    itemId
    rating
    createdAt
    updatedAt
  }

  fragment TaskBase on Task {
    id
    userId
    parameters
    outputs
    status
    priority
    runnerId
    startedAt
    endAt
    createdAt
    updatedAt
    retryCount
    paidCredit
  }

  fragment TaskWithMedia on Task {
    ...TaskBase

    favoritedAt

    media {
      ...MediaBase
    }
  }

  fragment TaskDetail on Task {
    ...TaskBase
    favoritedAt
    artworkId
    artworkIds
    artworks {
      createdAt
      hidePrompts
      id

      isNsfw
      isSensitive

      mediaId
      title
      updatedAt

      flag {
        ...ModerationFlagBase
      }
    }
    media {
      ...MediaBase
    }
    type {
      type
      model
    }
  }

  fragment TemplateBase on Template {
    id
    userId
    recipes {
      title
      defaultValue
    }
    parameters
    previewMediaId
    previewMedia {
      ...MediaBase
    }
    name
    published
    extra
    createdAt
    updatedAt
  }

  fragment NotificationBase on Notification {
    id
    type
    userId
    extra
    relatedUserIds
    relatedUsers {
      ...UserBase
    }

    refId
    refType
    refTitle
    refMedia {
      ...MediaBase
    }

    artwork {
      ...ArtworkBase
    }
    artworkId

    createdAt
    updatedAt

    title
    content
    locales

    iconMedia {
      ...MediaBase
    }

    unread

    data {
      rank {
        mediaType
        rankNumber
      }
    }
  }

  fragment ArtworkThemeBase on ArtworkTheme {
    id
    authorId
    author {
      ...UserBase
    }
    mediaId
    media {
      ...MediaBase
    }
    initTaskId
    title
    description
    fixedPrompts
    extra
    createdAt
    updatedAt

    artworkCount
    participantCount
    creatingCount
  }

  fragment GenerationModelBase on GenerationModel {
    id
    authorId
    title
    mediaId
    media {
      ...MediaBase
    }
    type
    extra
    createdAt
    updatedAt

    isNsfw

    isDownloadable
    isPrivate

    flag {
      ...ModerationFlagBase
    }
  }

  fragment GenerationModelDetail on GenerationModel {
    ...GenerationModelBase
    author {
      ...UserBase
    }

    tags {
      ...TagBase
    }

    likedCount
    liked
    reviewSummary {
      ...ReviewSummaryBase
    }
    bookmarked
    commentCount
    refCount
    refUserCount

    latestAvailableVersion {
      ...GenerationModelVersionWithFile
    }

    trainingTask {
      ...TrainingTaskBase
    }

    rebateAmount

    flag {
      ...ModerationFlagDetail
    }
  }

  fragment GenerationModelVersionBase on GenerationModelVersion {
    id
    modelId
    mediaId
    name
    fileUploadId
    createdAt
    updatedAt
    extra
  }

  fragment GenerationModelVersionDetail on GenerationModelVersion {
    ...GenerationModelVersionBase
    media {
      ...MediaBase
    }
    modelType
    downloadUrl
    status
  }

  fragment GenerationModelVersionWithFile on GenerationModelVersion {
    ...GenerationModelVersionDetail
    fileUploadRecord {
      ...FileUploadRecordBase
    }
  }

  fragment FileUploadRecordBase on FileUploadRecord {
    id
    fileSize
    fileType
    filename
    asset
    createdAt
    updatedAt
    userId
    externalId
    isFinished
    status
    extra
  }

  # fetch loras on demand
  fragment ArtworkWithLorasBase on Artwork {
    ...ArtworkDetail

    loras {
      id
      isNsfw
      isPrivate
      title
      likedCount
      media {
        ...MediaBase
      }
      mediaId
      type

      authorId
      createdAt
      updatedAt
      isDownloadable

      flag {
        ...ModerationFlagBase
      }
    }
  }

  fragment BookmarkBase on Bookmark {
    id
    isPublic
    name
    order
    parentId
    rootId
    updatedAt
    userId
    createdAt
    coverMediaId
    coverMedia {
      ...MediaBase
    }
  }

  fragment SessionBase on Session {
    id
    userId
    ip
    ua
    client
    extra
    createdAt
    expiredAt

    location
    userAgentData
    isValid
    isCurrentSession
  }

  fragment AccessTokenBase on AccessToken {
    id
    userId
    name
    secret
    expireTime
    lastUsedAt
    createdAt
  }

  fragment WebhookBase on UserWebhook {
    id
    userId
    name
    url
    scopes
    createdAt
    updatedAt
  }

  fragment TrainingTaskBase on TrainingTask {
    id
    userId
    status
    token
    priority
    startedAt
    endAt
    createdAt
    updatedAt
    archived
    retryCount
    type
    refId
    parameters {
      title
      mediaIds
    }
    extra {
      progress
      estimatedTotalTime
    }
    outputs {
      message
    }
  }

  fragment MFABase on MFA {
    id
    userId
    type
    status
    key
    createdAt
    updatedAt
  }

  fragment PaymentResultBase on PaymentResult {
    url
    paddleCheckoutParams
  }

  fragment MembershipBase on Membership {
    membershipId
    tier
    privilege
  }

  fragment UserSubscriptionBase on UserSubscription {
    provider
    planId
    interval
    status
    startAt
    endAt

    cancelAtPeriodEnd
    invoice
    lastPaymentError
    nextAction
  }

  fragment ContestBase on Contest {
    id
    name
    media {
      ...MediaBase
    }
    mediaId
    startAt
    endAt
    cid

    description
    prize
    rule

    published

    createdAt
    updatedAt

    locales
    extra
  }

  fragment ModerationFlagBase on ModerationFlag {
    status
    isSensitive
    isMinors
    isRealistic

    shouldBlur
    isWarned
  }

  fragment ModerationFlagDetail on ModerationFlag {
    ...ModerationFlagBase
    isAppealable
  }

  fragment QuestBase on Quest {
    id
    name
    type
    published
    interval
    startAt
    endAt
    rewardType
    rewardAmount
    periodTimes
    canStack
    info {
      questId
      rewardedTimes
      rewardableTimes
      completedTimes
    }
    surveyLink
  }

  fragment CouponBase on Coupon {
    id
    code
    type
    amount
    status
    paymentItemIds
    createdAt
    updatedAt
  }

  fragment WorkflowBase on Workflow {
    id
    userId
    name
    createdAt
    updatedAt
  }

  fragment WorkflowVersionBase on WorkflowVersion {
    id
    workflowId
    name
    status
    createdAt
    updatedAt
    data
  }

  fragment ArtworkInFeed on Artwork {
    ...ArtworkBase

    likedCount
    liked
    commentCount

    author {
      ...UserBase
    }

    flag {
      ...ModerationFlagBase
    }
  }

  fragment ArtworkWithMangaInFeed on Artwork {
    ...ArtworkInFeed

    manga {
      ...MangaBase
      likedCount
      liked
      chapter(index: 0) {
        ...MangaChapterBase
      }
    }
  }

  fragment GenerationModelInFeed on GenerationModel {
    ...GenerationModelBase

    likedCount
    liked
    refCount
    commentCount

    latestAvailableVersion {
      ...GenerationModelVersionBase
      status
      downloadUrl
    }

    # trainingTask {
    #   ...TrainingTaskBase
    # }

    flag {
      ...ModerationFlagDetail
    }
  }

  fragment GenerationModelVersionInFeed on GenerationModelVersion {
    ...GenerationModelVersionBase
    media {
      ...MediaBase
    }
    modelType
    status
  }

  fragment UserInFeed on User {
    ...UserBase

    followedByMe
  }

`)
