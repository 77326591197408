import { withCustomizedErrorBoundary } from '@/components/global/ErrorBoundary'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { client } from '@/services/client'
import { QUERY_ME } from '@/services/user'
import { useAuthToken } from '@/states/auth'
import { useAuthDialog } from '@/states/AuthDialogProvider'
import { formatError } from '@/utils/errors'
import { NetworkStatus, useQuery } from '@apollo/client'
import { track } from '@troph-team/tldraw'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import LineMdLoadingTwotoneLoop from '~icons/line-md/loading-twotone-loop'
import RadixIconsArrowTopRight from '~icons/radix-icons/arrow-top-right'
import RadixIconsExclamationTriangle from '~icons/radix-icons/exclamation-triangle'
import RadixIconsPerson from '~icons/radix-icons/person'

export const AccountAvatar = withCustomizedErrorBoundary(
  track(() => {
    const { t } = useTranslation(['auth'])
    const { data, error, loading, networkStatus } = useQuery(QUERY_ME)
    const url = data?.me?.avatarMedia?.urls?.[0]?.url

    const { ensureAuthenticated } = useAuthDialog()
    const [token, setToken] = useAuthToken()

    const inner = (() => {
      const loginButton = (
        <Button
          onClick={() => ensureAuthenticated()}
          color="primary"
          className="h-8 gap-2 rounded-full pl-3 pr-4"
        >
          <RadixIconsPerson className="size-4" />

          <span>{t('auth:login.cta')}</span>
        </Button>
      )

      if (!token) return loginButton

      if (
        loading ||
        // having a token and is currently (re)validating
        ((networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.loading) &&
          !!token)
      )
        return <LineMdLoadingTwotoneLoop className="m-1 size-6 text-white" />

      // if error is a 401, show login button
      if (
        error?.networkError &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error.networkError as any)?.statusCode === 401
      ) {
        return loginButton
      }

      if (error) {
        return (
          <Tooltip>
            <TooltipTrigger>
              <div className="flex size-8 items-center justify-center rounded-full bg-orange-300/70">
                <RadixIconsExclamationTriangle className="size-4 text-white/70" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Unable to fetch user data: {formatError(error)} ({formatError(error.graphQLErrors)},{' '}
              {formatError(error.protocolErrors)})
            </TooltipContent>
          </Tooltip>
        )
      }

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="aspect-square h-8 cursor-pointer">
              {url ? (
                <img
                  src={url}
                  alt="Avatar"
                  className="size-full shrink-0 rounded-full border border-zinc-700"
                />
              ) : (
                <div className="flex size-full items-center justify-center rounded-full border border-zinc-700 bg-zinc-600 uppercase leading-none text-white">
                  {data?.me?.displayName?.[0] ?? '?'}
                </div>
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel className="flex flex-col items-start">
              <div>{data?.me?.displayName}</div>
              <div className="text-xs font-normal text-gray-400">{data?.me?.email}</div>
            </DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                window.open('https://pixai.art/@' + (data?.me?.username ?? '') + '/tasks')
              }}
              className="flex items-center gap-1"
            >
              <span>{t('auth:user-tasks.action')}</span>

              <RadixIconsArrowTopRight className="size-4" />
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={async () => {
                setToken(null)
                toast.success(t('auth:logout.toast.success'))
                await client.resetStore()

                // FIXME: This is a hack to refetch the user data
                setTimeout(() => {
                  client.refetchQueries({ include: [QUERY_ME] })
                }, 0)
              }}
            >
              {t('auth:logout.action')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    })()

    return <div className="shrink-0 items-center justify-end text-white">{inner}</div>
  })
)
