import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { useState } from 'react'
import LineMdLoadingTwotoneLoop from '~icons/line-md/loading-twotone-loop'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonBaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean
  tooltip?: React.ReactNode
  isLoading?: boolean
  disabledReason?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown | Promise<unknown>
}

export interface ButtonProps extends ButtonBaseProps, VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <ButtonBase
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  (
    {
      className,
      asChild = false,
      tooltip,
      isLoading,
      disabled,
      disabledReason,
      children,
      onClick,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    const [implicitIsLoading, setImplicitIsLoading] = useState(false)
    const augmentedIsLoading = isLoading || implicitIsLoading
    const augmentedIsDisabled = augmentedIsLoading || disabled

    const augmentedOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick == null) return
      const result = onClick(event)
      if (result instanceof Promise) {
        setImplicitIsLoading(true)
        result.finally(() => setImplicitIsLoading(false))
      }
    }

    const inner = (
      <Comp
        className={cn(
          'relative transition-colors focus-visible:outline-none focus-visible:border-ring focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 select-none active:brightness-90',
          className
        )}
        ref={ref}
        onClick={augmentedOnClick}
        disabled={augmentedIsDisabled}
        {...props}
      >
        {augmentedIsLoading && (
          <div className="absolute inset-0 flex items-center justify-center rounded-md bg-black/50 transition-colors">
            <LineMdLoadingTwotoneLoop className="size-4" />
          </div>
        )}
        {children}
      </Comp>
    )

    const tooltipContent = (!disabled && tooltip) || (disabled && disabledReason)

    if (tooltipContent) {
      return (
        <Tooltip>
          <TooltipTrigger asChild={!disabledReason}>{inner}</TooltipTrigger>
          <TooltipContent side="bottom" sideOffset={2} arrowPadding={1}>
            <TooltipArrow />
            {tooltipContent}
          </TooltipContent>
        </Tooltip>
      )
    }

    return inner
  }
)
ButtonBase.displayName = 'ButtonBase'

export { Button, ButtonBase, buttonVariants }
