import { gql } from '@/services/__generated__'

export const QUERY_ME = gql(`
  query QueryMe {
    me {
      id
      email
      displayName
      username
      avatarMedia {
        ...MediaBase
      }
      isAdmin
    }
  }
`)

export const MUTATION_LOGIN = gql(`
  mutation login($input: RegisterOrLoginInput!) {
    login(input: $input) {
      ...UserBase
    }
  }
`)

export const QUERY_MY_ROLE = gql(`
  query getMyRoles($before: String, $after: String, $first: Int, $last: Int, $names: [String!]) {
    me {
      roles(before: $before, after: $after, first: $first, last: $last, names: $names) {
        edges {
          node {
            ...RoleBase
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
      }
    }
  }
`)
