import { useEditor } from '@/states/EditorProvider'
import { MarkerShapeWidthStyle, track } from '@troph-team/tldraw'

import { Label } from '@/components/ui/label'
import { Slider } from '@/components/ui/slider'
import { useTranslation } from 'react-i18next'

export const ToolboxInpaint = track(() => {
  const { t } = useTranslation()

  const editor = useEditor()
  const nextWidth = editor.getStyleForNextShape(MarkerShapeWidthStyle) as number

  const projectedWidthOnCanvas = (() => {
    const topLeft = editor.pageToScreen({ x: 0, y: 0 })
    const bottomRight = editor.pageToScreen({
      x: nextWidth,
      y: nextWidth,
    })

    return Math.max(bottomRight.x - topLeft.x, bottomRight.y - topLeft.y)
  })()

  return (
    <div className="relative flex size-full flex-col gap-2 overflow-hidden p-3">
      <div className="text-lg font-bold text-white">{t('tool.marker')}</div>

      <div className="flex w-full items-center gap-2">
        <div className="flex grow flex-col gap-3">
          <Label>{t('style-panel.brush-size')}</Label>
          <Slider
            min={5}
            max={100}
            step={1}
            value={[nextWidth]}
            onValueChange={(v) => {
              const value = v[0]
              editor.setStyleForNextShapes(MarkerShapeWidthStyle, value, {
                squashing: true,
              })
            }}
          />
        </div>

        <div className="pointer-events-none relative flex aspect-square h-16 shrink-0 items-center justify-center">
          <div
            className="flex shrink-0 items-center justify-center rounded-full bg-white/5 text-center shadow-lg"
            style={{
              width: projectedWidthOnCanvas,
              height: projectedWidthOnCanvas,
              boxShadow: `inset 0 0 ${Math.log2(projectedWidthOnCanvas)}px ${Math.log(
                projectedWidthOnCanvas
              )}px rgba(255, 255, 255, 0.35)`,
            }}
          >
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-bold tabular-nums leading-none text-white mix-blend-difference">
              {nextWidth.toFixed(0)}px
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
