import { Button } from '@/components/ui/button'
import { InputRangeSlider } from '@/components/ui/input-range-slide'
import { InputSlider } from '@/components/ui/input-slider'
import { Label } from '@/components/ui/label'
import { PSFocusLinesShape, PSSpeedLinesShape, useEditor } from '@troph-team/tldraw'
import { Dices } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorPickerWithEyedrop } from './ColorPickerWithEyedrop'
import { SpeedLinesStylesControls } from './lines/SpeedLinesStylesControls'
import { FocusLinesStylesControls } from './lines/FocusLinesStylesControls'

import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion'

export const LinesStylesPanel: FC<{
  shape: PSSpeedLinesShape | PSFocusLinesShape
}> = ({ shape }) => {
  const { t } = useTranslation()

  const editor = useEditor()

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full border-b-2 px-4"
      defaultValue="motion-line"
    >
      <AccordionItem value="motion-line">
        <AccordionTrigger className="font-bold">{t('style-panel.motion-line')}</AccordionTrigger>
        <AccordionContent className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            {shape.type === 'speed-lines' && <SpeedLinesStylesControls shape={shape} />}
            <Label>{t('style-panel.speedlines.space')}</Label>

            <InputRangeSlider
              onValueChange={(value) => {
                editor.updateShape({
                  id: shape.id,
                  type: shape.type,
                  props: {
                    minSpaceWidth: value[0],
                    maxSpaceWidth: value[1],
                  },
                })
              }}
              value={[shape.props.minSpaceWidth, shape.props.maxSpaceWidth]}
              min={0.1}
              max={10}
              step={0.1}
            />
            <Label>{t('style-panel.speedlines.width')}</Label>

            <InputRangeSlider
              onValueChange={(value) => {
                editor.updateShape({
                  id: shape.id,
                  type: shape.type,
                  props: {
                    minStrokeWidth: value[0],
                    maxStrokeWidth: value[1],
                  },
                })
              }}
              value={[shape.props.minStrokeWidth, shape.props.maxStrokeWidth]}
              min={0.1}
              max={10}
              step={0.1}
            />

            <Label>{t('style-panel.speedlines.fadeFrom')}</Label>

            <InputSlider
              onValueChange={(value) => {
                editor.updateShape({
                  id: shape.id,
                  type: shape.type,
                  props: {
                    fadeFrom: value,
                  },
                })
              }}
              value={shape.props.fadeFrom}
              min={0}
              max={1}
              step={0.1}
            />

            {shape.type === 'focus-lines' && (
              <>
                <Label>{t('style-panel.speedlines.fade-from-opacity')}</Label>

                <InputSlider
                  onValueChange={(value) => {
                    editor.updateShape({
                      id: shape.id,
                      type: shape.type,
                      props: {
                        fadeFromOpacity: value,
                      },
                    })
                  }}
                  value={shape.props.fadeFromOpacity}
                  min={0}
                  max={1}
                  step={0.1}
                />
              </>
            )}

            <div className="my-2 flex items-center justify-between gap-4">
              <Label>{t('style-panel.speedlines.color')}</Label>
              <ColorPickerWithEyedrop
                value={shape.props.color}
                onChange={(color: string) =>
                  editor.updateShape({
                    id: shape.id,
                    type: shape.type,
                    props: {
                      color: color,
                    },
                  })
                }
              />
            </div>
            <div className="my-2 flex items-center justify-between gap-4">
              <Label>{t('style-panel.speedlines.seed')}</Label>

              <Button
                onClick={() => {
                  editor.updateShape({
                    id: shape.id,
                    type: shape.type,
                    props: {
                      seed: Math.floor(Math.random() * 100000),
                    },
                  })
                }}
                variant="outline"
                className="w-32"
              >
                <Dices />
              </Button>
            </div>

            {shape.type === 'focus-lines' && <FocusLinesStylesControls shape={shape} />}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
