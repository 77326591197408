import poses from '@/assets/poses_filtered.json'
import { bodyMeta, PartMeta } from './partMeta'
import { PointWithConfidence } from '@troph-team/tldraw'

const poseArrayToObject = (pose: number[]) => {
  // [x1, y1, c1, x2, y2, c2, ...]
  return pose.reduce(
    (acc, _cur, idx) => {
      if (idx % 3 === 0 && idx < pose.length - 2) {
        acc.push({
          x: pose[idx],
          y: pose[idx + 1],
          c: pose[idx + 2],
        })
      }
      return acc
    },
    [] as {
      x: number
      y: number
      c: number
    }[]
  )
}

export const getTemplatePoses = () => {
  return poses.map((pose) => {
    return {
      exampleMediaUrl: pose.exampleMediaUrl,
      tags: pose.tags,
      width: pose.pose.width,
      height: pose.pose.height,
      people: pose.pose.people.map((person) => {
        return {
          body: poseArrayToObject(person.pose),
          // handLeft: poseArrayToObject(person.hand_left || []),
          // handRight: poseArrayToObject(person.hand_right || []),
        }
      }),
    }
  })
}

const calculateEllipseFromLine = ({
  k1,
  k2,
  keypoints,
  scale,
}: {
  k1: number
  k2: number
  keypoints: PointWithConfidence[]
  scale: number
}) => {
  const x1 = keypoints[k1].x
  const y1 = keypoints[k1].y
  const x2 = keypoints[k2].x
  const y2 = keypoints[k2].y

  const cx = (x1 + x2) / 2
  const cy = (y1 + y2) / 2

  const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
  const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI) // Convert to degrees

  const strokeWidth = 2 * scale

  return {
    cx,
    cy,
    rx: length / 2,
    ry: strokeWidth,
    transform: `rotate(${angle}, ${cx}, ${cy})`,
  }
}

function svgKeypoints(part: PartMeta, keypoints: PointWithConfidence[], scale: number) {
  const g = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  for (let i = 0; i < part.keypoints.length; i++) {
    if (part.displayIgnoreKeypoints?.includes(i)) continue
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    circle.setAttribute('cx', keypoints[i].x + '')
    circle.setAttribute('cy', keypoints[i].y + '')
    circle.setAttribute('r', part.keypointRadius * scale + '')
    circle.setAttribute('fill', part.keypointColors[i])
    circle.setAttribute('opacity', keypoints[i].c + '')
    g.appendChild(circle)
  }
  return g
}

function svgConnections(part: PartMeta, keypoints: PointWithConfidence[], scale: number) {
  if (!part.connections || !part.connectionColors) return
  const g = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  for (const [index, [k1, k2]] of Object.entries(part.connections)) {
    const i = Number(index)

    const ellipseConfig = calculateEllipseFromLine({
      k1,
      k2,
      keypoints,
      scale,
    })
    const ellipse = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse')
    for (const [key, value] of Object.entries(ellipseConfig)) {
      ellipse.setAttribute(key, value + '')
    }
    ellipse.setAttribute('fill', part.keypointColors[i])
    ellipse.setAttribute('opacity', '0.6')
    g.appendChild(ellipse)
  }
  return g
}

export const poseToSvg = ({
  body,
  width,
  height,
}: {
  body: { x: number; y: number; c: number }[]
  width: number
  height: number
}) => {
  const g = document.createElementNS('http://www.w3.org/2000/svg', 'g')

  const scale = 1
  const children = [
    svgConnections(bodyMeta, body, scale),
    svgKeypoints(bodyMeta, body, scale),
  ].filter(Boolean) as SVGElement[]
  for (const child of children) g.appendChild(child)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMin slice"
      dangerouslySetInnerHTML={{ __html: g.outerHTML }}
    />
  )
}
