import { Editor, useEditor as useEditorTldraw } from '@troph-team/tldraw'
import { FC, PropsWithChildren, createContext, useContext, useState } from 'react'

export const EditorContext = createContext<{
  editor: Editor
  setEditor: (editor: Editor) => void
}>({
  editor: null as unknown as Editor,
  setEditor: () => {
    throw new Error('EditorProvider is not found')
  },
})

export const EditorProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [editor, setEditor] = useState<Editor>(null as unknown as Editor)

  return <EditorContext.Provider value={{ editor, setEditor }}>{children}</EditorContext.Provider>
}

export const useEditor = () => {
  const self = useContext(EditorContext).editor
  const tldraw = useEditorTldraw()
  return self ?? tldraw
}
