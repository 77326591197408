import { useEditor } from '@/states/EditorProvider'
import { useSortable } from '@dnd-kit/sortable'
import {
  EASINGS,
  PSGenBoxShape,
  PSGenMediaShape,
  TLGeoShape,
  TLShape,
  track,
} from '@troph-team/tldraw'
import { FC, useMemo } from 'react'
import { ListItem } from '../../ui/ListItem'

import { toTitleCase } from '@/lib/strings'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import invariant from '@/utils/invariant'
import { TooltipPortal } from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import IonAccessibility from '~icons/ion/accessibility'
import LineMdLoadingTwotoneLoop from '~icons/line-md/loading-twotone-loop'
import RadixIconsBox from '~icons/radix-icons/box'
import RadixIconsChatBubble from '~icons/radix-icons/chat-bubble'
import RadixIconsCornerBottomLeft from '~icons/radix-icons/corner-bottom-left'
import RadixIconsDot from '~icons/radix-icons/dot'
import RadixIconsFileText from '~icons/radix-icons/file-text'
import RadixIconsFrame from '~icons/radix-icons/frame'
import RadixIconsImage from '~icons/radix-icons/image'
import RadixIconsPencil1 from '~icons/radix-icons/pencil-1'
import RadixIconsPencil2 from '~icons/radix-icons/pencil-2'
import RadixIconsText from '~icons/radix-icons/text'

const SHAPE_ICON_MAP = {
  frame: RadixIconsFrame,
  geoframe: RadixIconsFrame,
  text: RadixIconsText,
  geo: RadixIconsBox,
  image: RadixIconsImage,
  genmedia: RadixIconsImage,
  draw: RadixIconsPencil1,
  marker: RadixIconsPencil2,
  dialogue: RadixIconsChatBubble,
  pose: IonAccessibility,
}

export const ShapeName: FC<{
  shape: TLShape
  showIcon?: boolean
  contentClassName?: string
  dark?: boolean
}> = ({ shape, showIcon, contentClassName }) => {
  const { t } = useTranslation()

  const editor = useEditor()
  const icon = useMemo(() => {
    if (shape.meta.isPageFrame) return <RadixIconsFileText className="m-1 mr-3 size-6 shrink-0" />

    try {
      if (shape.type === 'genmedia') {
        const s = shape as PSGenMediaShape
        if (s.props.pending)
          return (
            <div className="mr-2 flex size-8 shrink-0 items-center justify-center">
              <LineMdLoadingTwotoneLoop className="size-5 shrink-0" />
            </div>
          )

        invariant(s.props.currentAssetId, 'GenMedia shape must have a currentAssetId')
        const asset = editor.getAsset(s.props.currentAssetId)
        invariant(asset, 'Asset not found')
        invariant(asset.props.src, 'Asset must have a src')
        return (
          <Tooltip disableHoverableContent>
            <TooltipTrigger asChild>
              <img src={asset.props.src} className="mr-2 size-8 shrink-0 object-cover" alt="" />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent side="right" sideOffset={2} className="p-1">
                <img src={asset.props.src} className="h-full w-40 rounded-sm" alt="" />
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )
      }
    } catch (e) {
      console.warn('Failed to render genmedia icon. Falling back to default.', e)
    }

    const Icon = SHAPE_ICON_MAP[shape.type as keyof typeof SHAPE_ICON_MAP]
    if (Icon) return <Icon className="m-2 mr-4 size-4 shrink-0" />

    return <RadixIconsDot className="mr-2 size-8 shrink-0" />
  }, [shape])

  const content = useMemo(() => {
    if (shape.type === 'genmedia') {
      const s = shape as PSGenMediaShape
      if (s.props.isInpaint) {
        return `[${t('shape.genmedia.inpaint')}] ${s.props.prompts}`
      }

      return s.props.prompts ? `[${t('shape.genmedia')}] ${s.props.prompts}` : 'Generated'
    }
    if (shape.type === 'marker') return t('tool.marker')
    if (shape.type === 'marker-lasso') return t('tool.marker-lasso')
    if (shape.type === 'marker-geo') return t('tool.marker-box')
    if (shape.type === 'genbox') {
      const s = shape as PSGenBoxShape
      return `${t('tool.genbox.label')} (${s.props.w}x${s.props.h})`
    }
    if (shape.type === 'geo') {
      const s = shape as TLGeoShape
      return t('tool.' + s.props.geo)
    }

    const props = shape.props as { text?: string; name?: string }
    if (props.text) return props.text
    if (props.name) return props.name

    const toolMessage = t('tool.' + shape.type)
    if (toolMessage) return toolMessage
    return toTitleCase(shape.type)
  }, [shape, t])

  return (
    <div className="flex w-full items-center">
      {showIcon && icon}
      <div
        className={cn(
          'shrink truncate overflow-ellipsis whitespace-normal text-xs',
          contentClassName
        )}
      >
        {content}
      </div>
    </div>
  )
}

export const LayerListItem: FC<{
  shape: TLShape
  className?: string
  root?: boolean
  dark?: boolean
}> = track(({ shape, className, root = true, dark = false }) => {
  const editor = useEditor()
  const selected = editor.getSelectedShapes()
  const shapeSelected = selected.some((s) => s.id === shape.id)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: shape.id,
  })

  return (
    <ListItem
      style={{
        transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
        transition,
      }}
      ref={setNodeRef}
      key={shape.id}
      aria-label={`Select ${shape.type}`}
      selected={shapeSelected}
      className={clsx('touch-none', className, dark && 'hover:!bg-black active:!bg-gray-900')}
      {...attributes}
      {...listeners}
    >
      <div
        className={clsx(
          'flex items-center justify-start gap-2 px-4 py-2 text-sm',
          dark && 'text-white/90'
        )}
        onClick={() => {
          editor.select(shape.id)
          editor.zoomToSelection({
            duration: 200,
            easing: EASINGS.easeInOutQuint,
          })
        }}
      >
        {!root && (
          <RadixIconsCornerBottomLeft
            className={clsx(
              dark
                ? shapeSelected
                  ? 'text-primary-foreground/80'
                  : 'text-white/60'
                : shapeSelected
                  ? 'text-primary-foreground/80'
                  : 'text-muted-foreground/60',
              'size-6'
            )}
          />
        )}
        <ShapeName shape={shape} showIcon contentClassName="line-clamp-3" />
      </div>
    </ListItem>
  )
})
