import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { GenerationStateForm } from '@/states/Generation'
import { useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
export const GenBoxPanelHyperSD = () => {
  const { t } = useTranslation()

  const { watch, setValue } = useFormContext<GenerationStateForm>()
  const useHyperSD = watch('useHyperSD')

  return (
    <>
      <div className="mt-1 flex w-full items-center gap-2">
        <Label htmlFor="use-hyper-sd" className="flex items-center gap-2">
          <span>{t('gen.use-hyper-sd')}</span>
        </Label>
        <div className="flex-1" />
        <Switch
          id="use-hyper-sd"
          name="useHyperSD"
          checked={!!useHyperSD}
          onCheckedChange={(v) => {
            setValue('useHyperSD', v)
          }}
        />
      </div>
    </>
  )
}
