import { CONFIG } from '@/utils/config'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from 'virtual:i18next-loader'
import './index.css'

i18next
  .use(
    new LanguageDetector(null, {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
      lookupQuerystring: 'locale',
      lookupLocalStorage: 'pixstudio-locale',
      lookupSessionStorage: 'pixstudio-locale',
      lookupCookie: 'pixstudio-locale',
      caches: ['localStorage'],
      convertDetectedLanguage(lng) {
        const convertedMatch = CONFIG.SUPPORTED_LOCALES.find((locale) =>
          locale.locale.startsWith(lng.replace(/_/g, '-'))
        )
        if (convertedMatch) {
          // Use the exact language code if it's supported
          return convertedMatch.locale
        }

        // match the language only
        const match = /^[a-z]+/i.exec(lng)
        // if the match is supported in one of the locales, return it
        const matchLocale =
          match && CONFIG.SUPPORTED_LOCALES.find((locale) => locale.locale.startsWith(match[0]))
        if (matchLocale) {
          return matchLocale.locale
        }

        // we return the `lng` as-is so i18next
        // defaults the `fallbackLng` if the detected language is not supported
        return lng
      },
    })
  )
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // keySeparator: false, // we do not use keys in form messages.welcome
    // the translations
    resources,
    fallbackLng: 'en-US',
    defaultNS: 'default',
    fallbackNS: 'default',
    supportedLngs: CONFIG.SUPPORTED_LOCALES.map((locale) => locale.locale),

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
