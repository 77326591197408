import { MediaProvider, MediaType } from '@/services/__generated__/graphql'
import { MUTATION_UPLOAD_MEDIA } from '@/services/media'
import axios, { AxiosRequestConfig } from 'axios'
import { client } from '../services/client'

export const uploadMedia = async (
  file: File,
  {
    provider = MediaProvider.S3,
    preserveFileType,
    isPrivate,
    isEphemeral,
    onProgress,
    signal,
  }: {
    provider?: MediaProvider
    isPrivate?: boolean
    isEphemeral?: boolean
    /**
     * if false, file type will be determined on the server
     * GIF images need this to be true
     */
    preserveFileType?: boolean
    onProgress?: (progress: number) => void
    signal?: AbortSignal
  } = {}
) => {
  const type = file.type.startsWith('image')
    ? MediaType.Image
    : file.type.startsWith('video')
    ? MediaType.Video
    : undefined
  if (!type) throw new Error(`Unsupported media type ${type}`)

  onProgress?.(0)

  const { data } = await client.mutate({
    mutation: MUTATION_UPLOAD_MEDIA,
    variables: {
      input: {
        type,
        provider,
        isPrivate,
        isEphemeral,
      },
    },
    context: {
      fetchOptions: {
        signal,
      },
    },
  })
  const { uploadUrl, externalId } = data?.uploadMedia ?? {}
  if (!uploadUrl) throw new Error('Upload url is not specified')

  onProgress?.(0.15)

  const formData = new FormData()
  formData.append('file', file)

  const opt: AxiosRequestConfig =
    type === MediaType.Image && provider !== MediaProvider.S3 // S3 uses PUT method
      ? {
          method: 'POST',
          data: formData,
        }
      : {
          method: 'PUT',
          data: file,
        }

  const res = await axios.request({
    url: uploadUrl,
    ...opt,
    onUploadProgress: (e) => {
      if (e.progress) onProgress?.(0.15 + e.progress * 0.7)
    },
    signal,
  })

  const parsedExternalId =
    provider === MediaProvider.S3
      ? getIdFromS3Url(uploadUrl) // External ID of S3 is that UUID string inside the URL
      : type === MediaType.Image
      ? res.data?.result?.id
      : externalId

  const re = await client.mutate({
    mutation: MUTATION_UPLOAD_MEDIA,
    variables: {
      input: {
        type,
        provider,
        externalId: parsedExternalId,
        imageType: preserveFileType ? file.type : undefined,
      },
    },
  })

  onProgress?.(1)

  return {
    ...re.data?.uploadMedia,
    type: type,
  }
}

/**
 * Extract UUID from S3 URL
 *
 * @example `https://s3.us-east-1.amazonaws.com/images.pixai.art/images/orig/f8fe906c-ae2c-4307-b69f-8f4a412f5dc4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA2SZRG3PWX3DN4AHK%2F20230824%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230824T065250Z&X-Amz-Expires=3600&X-Amz-Signature=07b9b4020d04a1dda45fb9a0179253fef1135590e95d556506a54a16e88cce32&X-Amz-SignedHeaders=host&x-id=PutObject
`
 * @params url
 * @returns UUID string
 */
function getIdFromS3Url(url: string) {
  const urlObj = new URL(url)
  const { pathname } = urlObj
  const lastPart = pathname.split('/').pop()
  return lastPart
}
