import { roundMultipleOf } from '@/lib/numbers'
import { randomId } from '@/lib/strings'
import { useEditor } from '@/states/EditorProvider'
import { EASINGS, PSGenBoxShape, createShapeId, useValue } from '@troph-team/tldraw'

export const useGenBoxOnPage = () => {
  const editor = useEditor()

  const genbox = useValue(
    'genbox on page',
    () => {
      return editor.getCurrentPageShapes().find((shape) => shape.type === 'genbox') as
        | PSGenBoxShape
        | undefined
    },
    [editor]
  )

  const createGenBox = () => {
    const page = editor.getCurrentPage()
    const shapeId = createShapeId(`genbox-${page.id}-${randomId(16)}`)
    const pageCenter = editor.getViewportPageCenter()
    editor.createShape({
      type: 'genbox',
      id: shapeId,
      x: roundMultipleOf(pageCenter.x - 768 / 2, 8),
      y: roundMultipleOf(pageCenter.y - 1280 / 2, 8),
      parentId: page.id,
      // arbitrarily large z: https://github.com/troph-team/tldraw/blob/466c26c1a0fd85a80ad08027e29426012d3cb53d/packages/tldraw-editor/src/lib/utils/reordering/dgreensp/dgreensp.ts#L158
      index: 'z99999999999999999999999999',
      props: {
        w: 768,
        h: 1280,
      },
    })

    return editor.getShape<PSGenBoxShape>(shapeId)!
  }

  return {
    genbox: genbox,
    add: () => {
      if (genbox) return genbox

      return createGenBox()
    },
    remove: () => {
      if (genbox) editor.deleteShape(genbox.id)
    },
    focus: () => {
      if (genbox) {
        editor.select(genbox.id)
        editor.zoomToSelection({
          duration: 200,
          easing: EASINGS.easeInOutQuint,
        })
        editor.bringToFront([genbox.id])
      } else {
        const shape = createGenBox()
        editor.select(shape.id)
      }
    },
  }
}
