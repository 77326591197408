export const isOffscreenCanvas = (o: HTMLCanvasElement | OffscreenCanvas): o is OffscreenCanvas =>
  typeof window.OffscreenCanvas === 'function' && o instanceof window.OffscreenCanvas

export const canvasToFile = async (canvas: HTMLCanvasElement | OffscreenCanvas) => {
  return isOffscreenCanvas(canvas)
    ? await canvas.convertToBlob()
    : await new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve)
      })
}

export async function convertBlobToPng(blob: Blob) {
  const elem = new Image()
  const loadImage = () =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      elem.onload = () => resolve(elem)
      elem.onerror = reject
      elem.src = URL.createObjectURL(blob)
    })

  try {
    const rawImage = await loadImage()
    let canvas: HTMLCanvasElement | OffscreenCanvas

    if (typeof window.OffscreenCanvas === 'function') {
      canvas = new OffscreenCanvas(rawImage.width, rawImage.height)
      const ctx = canvas.getContext('2d')
      if (!ctx) throw new Error('ctx not initialized')
      ctx.drawImage(rawImage, 0, 0, rawImage.width, rawImage.height)
    } else {
      canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = rawImage.width
      canvas.height = rawImage.height
      if (!ctx) throw new Error('ctx not initialized')
      ctx.drawImage(rawImage, 0, 0, rawImage.width, rawImage.height)
    }

    return canvasToFile(canvas)
  } catch (e) {
    console.log('cannot download image:', e)
  }
}
