import { COMMON_FRAGMENTS } from '@/services/fragment'
import { authTokenSubject, getAuthToken } from '@/states/auth'
import { ApolloClient, from, InMemoryCache, split } from '@apollo/client'
import { createFragmentRegistry } from '@apollo/client/cache'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { toast } from 'sonner'

export const clientCache = new InMemoryCache({
  fragments: createFragmentRegistry(COMMON_FRAGMENTS),
})

const GRAPHQL_ENDPOINT = 'https://api.pixai.art/graphql'

const refreshToken = async () => {
  // we wrap everything in a trycatch so the promise would eventually
  // finish, even if the refresh token fails. this way, the `await`
  // within the `httpLink` below will eventually finish (resolve/reject) and could
  // continue
  let timeoutTimer: number | null = null
  try {
    const abortController = new AbortController()
    const signal = abortController.signal
    // we use the signal to abort the request if the user logs out
    authTokenSubject.subscribe((token) => {
      if (token === null) {
        abortController.abort()
      }
    })
    // and also abort the request after 15 seconds
    timeoutTimer = setTimeout(() => {
      abortController.abort()
    }, 15000)
    const refreshResponse = await fetch(GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify({
        operationName: 'RefreshToken',
        query: `
            mutation RefreshToken {
              refreshToken
            }
          `,
        variables: {},
      }),
      signal,
    })
    // if the refresh token fails, we just return the initial response
    if (!refreshResponse.ok || refreshResponse.status !== 200) {
      return null
    }
    const token = refreshResponse.headers.get('token')?.trim()
    if (token) {
      authTokenSubject.next(token)
    }

    return token
  } catch (e) {
    console.warn('Failed to refresh token', e)
    return null
  } finally {
    if (timeoutTimer) clearTimeout(timeoutTimer)
  }
}

// we initiate the refresh token request as soon as possible
// so we can wait for it before making any requests
const initialRefreshTokenPromise = refreshToken()

const batchHttpLink = new BatchHttpLink({
  uri: GRAPHQL_ENDPOINT,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 10, // Wait no more than 10ms after first batched operation

  async fetch(input, init) {
    await initialRefreshTokenPromise // wait for the initial refresh token to be done

    const headers = new Headers(init?.headers)
    if (getAuthToken()) {
      headers.set('Authorization', `Bearer ${getAuthToken()}`)
    }
    const initialResponse = await fetch(input, {
      ...init,
      headers,
    })
    if (initialResponse.status === 401 && getAuthToken()) {
      // token might have just expired so we first try to refresh it
      // due to the fact we are not constructed a client yet, we can't really use
      // the apollo client to refresh the token, so we just do a simple fetch
      // and update the token in the subject
      const refreshedToken = await refreshToken()
      if (!refreshedToken) {
        // if the refresh token fails, we just return the initial response,
        // fire a toast and logout the user
        toast.error('Session expired, please log in again.')
        authTokenSubject.next(null)
        return initialResponse
      }

      // then, we retry the initial request with the new token
      const headers = new Headers(init?.headers)
      if (getAuthToken()) {
        headers.set('Authorization', `Bearer ${getAuthToken()}`)
      }
      return fetch(input, {
        ...init,
        headers,
      })
    }

    const token = initialResponse.headers.get('token')?.trim()
    if (token) {
      authTokenSubject.next(token)
    }

    return initialResponse
  },
})

let activeSocket: WebSocket, timeoutTimer: number
const wsLink = new GraphQLWsLink(
  createClient({
    url: 'wss://gw.pixai.art/graphql',
    keepAlive: 30_000,
    // considering we are a webapp, user may be online for a long time so setting a fixed # of
    // retryAttempts is not a good idea.
    retryAttempts: Infinity,
    connectionParams: () => {
      if (getAuthToken()) {
        return {
          token: getAuthToken(),
        }
      }
    },
    on: {
      connected: (socket) => (activeSocket = socket as WebSocket),
      ping: (received) => {
        if (!received)
          // sent
          timeoutTimer = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN)
              activeSocket.close(4408, 'Request Timeout')
          }, 5_000) // wait 5 seconds for the pong and then close the connection
      },
      pong: (received) => {
        if (received) clearTimeout(timeoutTimer) // pong is received, clear connection close timeout
      },
    },
  })
)

const retryLink = new RetryLink({
  attempts: {
    retryIf(error, operation) {
      // we don't want to retry if the error is 401
      // or if the operation is a mutation or a refresh token operation
      if (
        error.statusCode === 401 ||
        operation.query.definitions.some(
          (def) => def.kind === 'OperationDefinition' && def.operation === 'mutation'
        )
      ) {
        return false
      }

      return true
    },
  },
})

const composedHttpLink = from([retryLink, batchHttpLink])

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  composedHttpLink
)

export const client = new ApolloClient({
  link: splitLink,
  cache: clientCache,
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'cache-and-network',
    },
  },
})
