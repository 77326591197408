import { MAX_SD_SEED, randomInt } from '@/lib/numbers'
import { randomId } from '@/lib/strings'
import { GenerationModelType } from '@/services/__generated__/graphql'
import { client } from '@/services/client'
import { MUTATION_CREATE_GENERATION_TASK } from '@/services/tasks'
import { getAuthToken } from '@/states/auth'
import { useEditor } from '@/states/EditorProvider'
import { useGenBoxPoses } from '@/states/useGenBoxPoses'
import { ensureAssetMediaId } from '@/utils/assets/ensureAssetMediaId'
import { DetailedError, fireErrorToast, formatError } from '@/utils/errors'
import { downloadBlob } from '@/utils/file'
import {
  GenerationTaskCreativeUpscaleParameters,
  GenerationTaskDiffusionMergeParameters,
  GenerationTaskEnlargeParameters,
  GenerationTaskPixaiGeneratorParameters,
  generationTaskCreativeUpscale,
  generationTaskDiffusionMerge,
  generationTaskEnlarge,
  generationTaskFaceAdetailer,
  generationTaskHyperSD,
  generationTaskInpaint,
  generationTaskPixaiGenerator,
  generationTaskRemoveBackground,
} from '@/utils/generation'
import { makeMask } from '@/utils/image/mask'
import invariant from '@/utils/invariant'
import { renderAreaUnderShape } from '@/utils/shapes/renderShape'
import { uploadMedia } from '@/utils/uploadMedia'
import { getPublicUrl } from '@/utils/url'
import { cloneDeep } from '@apollo/client/utilities'
import {
  AssetRecordType,
  Box2d,
  PSGenBoxShape,
  PSGenMediaShape,
  PSMarkerGeoShape,
  PSMarkerLassoShape,
  PSMarkerShape,
  PSPoseShape,
  TLAssetId,
  TLImageAsset,
  TLImageShape,
  TLShapeId,
  compact,
  createShapeId,
  getIndexAbove,
  sortByIndex,
} from '@troph-team/tldraw'
import React, { ReactNode, createContext, useContext, useReducer } from 'react'
import { toast } from 'sonner'
import * as zod from 'zod'
import { GenerationTaskFaceAdetailerParameters } from '../utils/generation'

const CONTROLNET_ENDPOINT_URL = 'https://controlnet.pixai.art/annotate'

export const generationStateSchema = zod
  .object({
    prompts: zod.string().min(1, 'Prompt cannot be empty'),
    negativePrompts: zod.string().optional(),
    width: zod.number().int().positive('Width must be positive'),
    height: zod.number().int().positive('Height must be positive'),
    modelId: zod.string().min(1, 'Model ID cannot be empty'),
    modelType: zod.enum([GenerationModelType.SdxlModel, GenerationModelType.SdV1Model]).optional(),

    // a null seed indicates that the seed is random
    seed: zod.number().int().positive('Seed must be positive').optional(),

    batchSize: zod
      .number()
      .int()
      .positive('Batch size must be positive')
      .lte(8, 'Batch size must be less than or equal to 8'),

    steps: zod
      .number()
      .int()
      .positive('Steps must be positive')
      .lte(100, 'Steps must be less than or equal to 100'),

    samplingMethod: zod.string().default('Euler a'),

    cfgScale: zod
      .number()
      .min(1, 'CFG Scale must be greater than or equal to 1')
      .max(50, 'CFG Scale must be less than or equal to 50')
      .default(7),

    loras: zod.array(
      zod.object({
        modelVersionId: zod.string().min(1, 'Model version ID cannot be empty'),
        weight: zod
          .number()
          .gte(-0.5, 'Weight must be greater than or equal to -0.5')
          .lte(1.5, 'Weight must be less than or equal to 1.5'),
      })
    ),

    ipAdapter: zod.object({
      referenceImageMedias: zod
        .array(
          zod.object({
            id: zod.string(),
            previewUrl: zod.string(),
            mediaId: zod.string().optional(),
          })
        )
        .max(5),
    }),

    useHyperSD: zod.boolean().optional(),

    styleMix: zod.object({
      enabled: zod.boolean(),
      seed: zod.number().int().positive('Seed must be positive').optional(),
      weight: zod
        .number()
        .min(0, 'Weight must be greater than or equal to 0')
        .max(1, 'Weight must be less than or equal to 1'),
    }),
  })
  .refine(
    (data) => {
      if (data.ipAdapter.referenceImageMedias.length) {
        return data.ipAdapter.referenceImageMedias.every((m) => m.mediaId)
      }
      return true
    },
    {
      message: 'Some IP Adapter reference image is still uploading',
      path: ['ipAdapter', 'referenceImageMedias'],
    }
  )

export type GenerationStateForm = zod.infer<typeof generationStateSchema>

export type GenerationPendingTask = {
  localShapeId: TLShapeId
  remoteId: string
  parameters: GenerationStateForm
}

type State = {
  pendingTasks: GenerationPendingTask[]
}

type Action =
  | { type: 'ADD_PENDING_TASK'; task: GenerationPendingTask }
  | { type: 'REMOVE_PENDING_TASK'; localShapeId: TLShapeId }

const initialState: State = {
  pendingTasks: [],
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ADD_PENDING_TASK':
      return {
        ...state,
        pendingTasks: [...state.pendingTasks, action.task],
      }
    case 'REMOVE_PENDING_TASK':
      return {
        ...state,
        pendingTasks: state.pendingTasks.filter((t) => t.localShapeId !== action.localShapeId),
      }
    default:
      return state
  }
}

const GenerationStateContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})

export const GenerationStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GenerationStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GenerationStateContext.Provider>
  )
}

export const useGenerationState = () => {
  const context = useContext(GenerationStateContext)
  if (!context) {
    throw new Error('useGenerationState must be used within a GenerationStateProvider')
  }
  return context
}

function convertPoseCoordinates(tuples: number[]): {
  x: number
  y: number
  c: number
}[] {
  const result = []
  for (let i = 0; i < tuples.length; i += 3) {
    result.push({
      x: tuples[i],
      y: tuples[i + 1],
      c: tuples[i + 2],
    })
  }
  return result
}

function inheritGenMediaShapeFromAnyMediaShape(shape: TLImageShape | PSGenMediaShape) {
  const currentAssetId =
    shape.type === 'genmedia' ? shape.props.currentAssetId : shape.props.assetId
  const assetIds =
    shape.type === 'genmedia'
      ? shape.props.assetIds
      : ([currentAssetId].filter(Boolean) as TLAssetId[])
  const prompts = shape.type === 'genmedia' ? shape.props.prompts : ''
  return {
    type: 'genmedia' as const,
    x: shape.x,
    y: shape.y,
    props: {
      w: shape.props.w,
      h: shape.props.h,
      prompts,
      currentAssetId,
      derivationOfShape: shape.id,
      assetIds,
    },
    parentId: shape.parentId,
  }
}

export const useGenerationService = () => {
  const editor = useEditor()
  const { poseShapes } = useGenBoxPoses()
  const { state, dispatch } = useGenerationState()

  const submitTask = async (localShapeId: TLShapeId, task: object) => {
    const variables = {
      parameters: {
        ...task,
      },
    }
    const response = await client.mutate({
      mutation: MUTATION_CREATE_GENERATION_TASK,
      variables,
    })

    if (response.errors && response.errors.length > 0) {
      throw new DetailedError(response.errors.map((e) => `{${e.path}}: ${e.message};`).join('\n'), {
        task,
        response,
      })
    }

    const remoteId = response.data?.createGenerationTask?.id
    if (!remoteId) {
      throw new DetailedError('Failed to create generation task: ID is null', {
        task,
        response,
      })
    }

    dispatch({
      type: 'ADD_PENDING_TASK',
      task: {
        localShapeId,
        remoteId,
        parameters: task as GenerationStateForm,
      },
    })

    return response.data?.createGenerationTask
  }

  const submitRemoveBackgroundTask = async (shape: PSGenMediaShape | TLImageShape) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    const inheritedShape = inheritGenMediaShapeFromAnyMediaShape(shape)
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      ...inheritedShape,
      props: {
        ...inheritedShape.props,
        pending: 'Submitting task...',
        prompts: shape.type === 'image' ? '' : shape.props.prompts,
      },
    })

    editor.select(shapeId)

    try {
      const currentAssetId =
        shape.type === 'image' ? shape.props.assetId : shape.props.currentAssetId
      invariant(currentAssetId, 'Asset ID is required')

      const prompts = shape.type === 'image' ? '' : shape.props.prompts

      const mediaId = await ensureAssetMediaId(editor, currentAssetId)
      invariant(mediaId, 'No mediaId found')

      const id = toast.loading('Submitting task...')

      const remoteTask = await submitTask(
        shapeId,
        generationTaskRemoveBackground({
          mediaId,
        })
      )
      editor.updateShapeById(
        shapeId,
        {
          props: {
            prompts,
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
      toast.dismiss(id)
    } catch (e) {
      fireErrorToast('Failed to submit remove background task', e)
    }
  }

  const submitGenerationTask = async (
    shape: PSGenMediaShape | PSGenBoxShape,
    data: GenerationStateForm
  ) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      type: 'genmedia',
      x: shape.x,
      y: shape.y,
      props: {
        w: data.width,
        h: data.height,
        pending: 'Preparing...',
        prompts: data.prompts,
      },
      parentId: shape.parentId,
    })

    editor.select(shapeId)

    const box = new Box2d(shape.x, shape.y, shape.props.w, shape.props.h)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const task: any = await (async () => {
      const resultTask: GenerationTaskPixaiGeneratorParameters = {
        prompts: data.prompts,
        width: data.width,
        height: data.height,
        modelId: data.modelId,
        seed: data.seed,
        batchSize: data.batchSize,
        samplingSteps: data.steps,
        lora: data.loras?.reduce((acc, lora) => {
          acc[lora.modelVersionId] = lora.weight
          return acc
        }, {} as Record<string, number>),
        cfgScale: data.cfgScale,
        negativePrompts: data.negativePrompts || undefined,
        samplingMethod: data.samplingMethod,
        styleMix: data.styleMix,
      }

      if (poseShapes.length > 0) {
        editor.updateShapeById(
          shapeId,
          {
            props: {
              pending: 'Preparing poses...',
            },
          },
          {
            ephemeral: true,
          }
        )
        const blob = await renderAreaUnderShape(
          editor,
          shape,
          { level: 'pose', format: 'jpeg' },
          {
            bounds: box,
            background: '#000000',
            padding: 0,
            scale: 1,
          }
        )
        invariant(blob, 'Failed to render pose shapes')
        // convert blob to a base64 url
        const poseMedia = await uploadMedia(new File([blob], 'pose.jpg', { type: 'image/jpeg' }), {
          isEphemeral: true,
        })
        invariant(poseMedia.mediaId, 'No pose mediaId found')
        resultTask.controlNets = [
          {
            type: 'dwpose',
            mediaId: poseMedia.mediaId,
            weight: 0.8,
          },
        ]
      }

      if (data.ipAdapter.referenceImageMedias.length > 0) {
        resultTask.ipAdapter = {
          enabled: true,
          referenceImages: data.ipAdapter.referenceImageMedias.map((ref) => ref.mediaId!),
        }
      }

      if (data.useHyperSD) return await generationTaskHyperSD(resultTask)
      else return generationTaskPixaiGenerator(resultTask)
    })()

    try {
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Submitting task...',
          },
        },
        {
          ephemeral: true,
        }
      )
      const remoteTask = await submitTask(shapeId, task)
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const submitRegenerationTask = async (shape: PSGenMediaShape) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      type: 'genmedia',
      x: shape.x,
      y: shape.y,
      props: {
        ...shape.props,
        pending: 'Submitting task...',
      },
      parentId: shape.parentId,
    })

    editor.select(shapeId)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const task = cloneDeep((shape.meta?.generationTask as any)?.parameters)
    task.seed = randomInt(0, MAX_SD_SEED)

    try {
      const remoteTask = await submitTask(shapeId, task)
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const submitVariationTask = async (shape: PSGenMediaShape) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    const inheritedShape = inheritGenMediaShapeFromAnyMediaShape(shape)
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      ...inheritedShape,
      props: {
        ...inheritedShape.props,
        pending: 'Submitting task...',
      },
    })

    editor.select(shapeId)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const task = cloneDeep((shape.meta?.generationTask as any)?.parameters)

    try {
      const assetId = shape.props.currentAssetId
      invariant(assetId, 'Asset ID is required')

      task.seed = (task?.seed ?? randomInt(0, MAX_SD_SEED)) + 4

      const remoteTask = await submitTask(shapeId, task)
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const submitInpaintTask = async (
    baseShape: PSGenMediaShape | TLImageShape,
    maskShapes: (PSMarkerShape | PSMarkerLassoShape | PSMarkerGeoShape)[],
    parameters: {
      prompts: string
    },
    feathering: number = 8
  ) => {
    const assetId =
      baseShape.type === 'genmedia' ? baseShape.props.currentAssetId : baseShape.props.assetId

    invariant(assetId, 'Asset ID is required')
    const baseMediaId = await ensureAssetMediaId(editor, assetId)
    const baseAsset = editor.getAsset(assetId) as TLImageAsset
    invariant(baseAsset, 'Base asset not found')
    const maskShapesSorted = [...maskShapes].sort((a, b) => -sortByIndex(a, b))

    const maskShapesPageBound = Box2d.Common(
      compact(maskShapes.map((id) => editor.getShapePageBounds(id)))
    )
    const maskShapesShapeBound = Box2d.FromPoints(
      editor.getShapePageTransform(baseShape).invert().applyToPoints(maskShapesPageBound.corners)
    )

    const shapeId = createShapeId('genmedia-' + randomId(16))

    const x = maskShapesShapeBound.x - feathering
    const y = maskShapesShapeBound.y - feathering
    const w = maskShapesShapeBound.w + feathering * 2
    const h = maskShapesShapeBound.h + feathering * 2
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      type: 'genmedia',
      x: x, // because we are using the original base shape as the parent, inpaint image overlay will be positioned relative to the base shape
      y: y,
      props: {
        w: w,
        h: h,
        pending: 'Preparing...',
        prompts: parameters.prompts,
        shouldReceiveMarker: false,
        isInpaint: true,
        derivationOfShape: baseShape.id,

        crop: {
          topLeft: {
            x: x / baseShape.props.w,
            y: y / baseShape.props.h,
          },
          bottomRight: {
            x: (maskShapesShapeBound.x + maskShapesShapeBound.w + feathering) / baseShape.props.w,
            y: (maskShapesShapeBound.y + maskShapesShapeBound.h + feathering) / baseShape.props.h,
          },
        },
      },
      meta: {
        inpaintOfShape: baseShape.id,
      },
      parentId: baseShape.id, // we put the inpaint result as the same layer with base shape
      index: getIndexAbove(maskShapesSorted[0].index), // we put the inpaint result on top of the highest mask shape
    })

    editor.select(shapeId)

    try {
      // make mask shapes image
      const bounds = new Box2d(baseShape.x, baseShape.y, baseShape.props.w, baseShape.props.h)
      const rendered = await editor.getSvg(
        maskShapes.map((shape) => shape.id),
        {
          bounds,
          background: false,
          padding: 0,
        }
      )
      invariant(rendered, 'Failed to render mask shapes')

      editor.deleteShapes(maskShapes.map((shape) => shape.id))
      const readyMask = await makeMask(rendered, feathering, baseAsset.props.w, baseAsset.props.h)
      const maskMedia = await uploadMedia(
        new File([readyMask], 'mask.png', { type: 'image/png' }),
        { isEphemeral: true }
      )
      invariant(maskMedia.mediaId, 'No mask mediaId found')

      const DEBUG = false
      if (DEBUG) {
        downloadBlob(readyMask, 'mask.png')
        // debug
        const assetId = AssetRecordType.createId(maskMedia.mediaId!)
        editor.createAssets([
          {
            id: assetId,
            type: 'image',
            typeName: 'asset',
            props: {
              name: 'Mask Image ' + maskMedia.mediaId,
              src: getPublicUrl(maskMedia.media)!,
              w: maskMedia.media?.width ?? 0,
              h: maskMedia.media?.height ?? 0,
              mimeType: 'image/png',
              isAnimated: false,
            },
            meta: {
              mediaId: maskMedia.mediaId,
              media: maskMedia,
            },
          },
        ])
        editor.updateShapeById(
          shapeId,
          {
            props: {
              pending: null,
              currentAssetId: assetId,
            },
          },
          {
            ephemeral: true,
          }
        )
        return
      } else {
        const task = generationTaskInpaint({
          baseMediaId: baseMediaId,
          maskMediaId: maskMedia.mediaId,
          prompts: parameters.prompts,
        })

        const remoteTask = await submitTask(shapeId, task)
        editor.updateShapeById(
          shapeId,
          {
            props: {
              pending: 'Waiting...',
            },
            meta: {
              generationTask: remoteTask,
            },
          },
          {
            ephemeral: true,
          }
        )
      }
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const submitSynchronousEstimatePoseTask = async (shape: PSGenMediaShape | TLImageShape) => {
    const currentAssetId =
      shape.type === 'genmedia' ? shape.props.currentAssetId : shape.props.assetId
    invariant(currentAssetId, 'Asset ID is required')

    const asset = editor.getAsset(currentAssetId)
    invariant(asset, 'Media ID is required')

    const mediaId = asset.meta.mediaId
      ? asset.meta.mediaId
      : await ensureAssetMediaId(editor, currentAssetId)

    const requestBody = JSON.stringify({
      format: 'openpose_json',
      method: 'dwpose',
      mediaId,
      resolution: 512,
    })

    const id = toast.loading('Estimating pose...')
    try {
      const response = await fetch(CONTROLNET_ENDPOINT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getAuthToken(),
        },
        body: requestBody,
      })
      if (!response.ok) {
        throw new Error(`Failed to estimate pose: ${response.statusText}`)
      }
      const data = (await response.json()) as {
        data: {
          canvas_width: number
          canvas_height: number
          people: {
            pose_keypoints_2d: number[]
            face_keypoints_2d: number[]
            hand_left_keypoints_2d: number[]
            hand_right_keypoints_2d: number[]
          }[]
        }
      }

      let lastPoseShapeId: TLShapeId | null = null

      editor.batch(() => {
        for (const person of data.data.people) {
          const poseShapeId = createShapeId('pose-' + randomId(16))
          const body = convertPoseCoordinates(person.pose_keypoints_2d)
          const neck = body[1]

          editor.createShape<PSPoseShape>({
            id: poseShapeId,
            type: 'pose',
            x: shape.x + neck.x - 163, // neck offset?
            y: shape.y + neck.y - 59,
            index: getIndexAbove(shape.index),
            props: {
              body,
            },
          })

          // editor.resizeShape(
          //   poseShapeId,
          //   {
          //     x: shape.props.w / data.data.canvas_width,
          //     y: shape.props.h / data.data.canvas_height,
          //   },
          //   {
          //     scaleOrigin: {
          //       x: shape.x,
          //       y: shape.y,
          //     },
          //   }
          // );

          lastPoseShapeId = poseShapeId
        }
      })

      toast.success('Pose estimated', { id })

      if (lastPoseShapeId) editor.select(lastPoseShapeId)
    } catch (e) {
      toast.dismiss(id)
      fireErrorToast(
        'Failed to estimate pose',
        new DetailedError(formatError(e), { shape, requestBody })
      )
    }
  }

  const submitFaceEnhanceTask = async (
    shape: PSGenMediaShape | TLImageShape,
    data: GenerationTaskFaceAdetailerParameters
  ) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    const inheritedShape = inheritGenMediaShapeFromAnyMediaShape(shape)
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      ...inheritedShape,
      props: {
        ...inheritedShape.props,
        w: shape.props.w,
        h: shape.props.h,
        pending: 'Submitting task...',
      },
    })

    editor.select(shapeId)

    try {
      const assetId = shape.type === 'genmedia' ? shape.props.currentAssetId : shape.props.assetId
      invariant(assetId, 'Asset ID is required')

      const task = generationTaskFaceAdetailer(data)

      const remoteTask = await submitTask(shapeId, task)
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const submitCreativeUpscaleTask = async (
    shape: PSGenMediaShape | TLImageShape,
    data: GenerationTaskCreativeUpscaleParameters
  ) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    const inheritedShape = inheritGenMediaShapeFromAnyMediaShape(shape)
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      ...inheritedShape,
      props: {
        ...inheritedShape.props,
        w: shape.props.w,
        h: shape.props.h,
        pending: 'Submitting task...',
      },
    })

    editor.select(shapeId)

    try {
      const assetId = shape.type === 'genmedia' ? shape.props.currentAssetId : shape.props.assetId
      invariant(assetId, 'Asset ID is required')

      const task = await generationTaskCreativeUpscale(data)

      const remoteTask = await submitTask(shapeId, task)
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const submitEnlargeTask = async (
    shape: PSGenMediaShape | TLImageShape,
    data: GenerationTaskEnlargeParameters
  ) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    const inheritedShape = inheritGenMediaShapeFromAnyMediaShape(shape)
    editor.createShape<PSGenMediaShape>({
      id: shapeId,
      ...inheritedShape,
      props: {
        ...inheritedShape.props,
        w: shape.props.w,
        h: shape.props.h,
        pending: 'Submitting task...',
      },
    })

    editor.select(shapeId)

    try {
      const assetId = shape.type === 'genmedia' ? shape.props.currentAssetId : shape.props.assetId
      invariant(assetId, 'Asset ID is required')

      const task = generationTaskEnlarge(data)

      const remoteTask = await submitTask(shapeId, task)
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const submitDiffusionMergeTask = async (
    _foregroundShape: PSGenMediaShape | TLImageShape,
    backgroundShape: PSGenMediaShape | TLImageShape,
    data: GenerationTaskDiffusionMergeParameters
  ) => {
    const shapeId = createShapeId('genmedia-' + randomId(16))
    const inheritedShape = inheritGenMediaShapeFromAnyMediaShape(backgroundShape)
    editor.batch(() => {
      editor.createShape<PSGenMediaShape>({
        id: shapeId,
        ...inheritedShape,
        props: {
          ...inheritedShape.props,
          pending: 'Submitting task...',
        },
      })
      // .deleteShapes([foregroundShape.id, backgroundShape.id]);
    })

    editor.select(shapeId)

    try {
      const task = generationTaskDiffusionMerge(data)

      const remoteTask = await submitTask(shapeId, task)
      editor.updateShapeById(
        shapeId,
        {
          props: {
            pending: 'Waiting...',
          },
          meta: {
            generationTask: remoteTask,
          },
        },
        {
          ephemeral: true,
        }
      )
    } catch (e) {
      fireErrorToast('Failed to submit generation task', e)
    }
  }

  const removePendingTask = (localShapeId: TLShapeId) => {
    dispatch({ type: 'REMOVE_PENDING_TASK', localShapeId })
  }

  return {
    pendingTasks: state.pendingTasks,
    submitRemoveBackgroundTask,
    submitGenerationTask,
    submitRegenerationTask,
    submitVariationTask,
    submitInpaintTask,
    submitSynchronousEstimatePoseTask,
    submitFaceEnhanceTask,
    submitEnlargeTask,
    submitDiffusionMergeTask,
    removePendingTask,
    submitCreativeUpscaleTask,
  }
}
