import { cn } from '@/lib/utils'
import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react'

type ListItemProps = PropsWithChildren<{ selected?: boolean }> & HTMLAttributes<HTMLLIElement>

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ selected, children, ...props }, ref) => {
    return (
      <li
        {...props}
        ref={ref}
        className={cn(
          'border-b border-border touch-none overflow-x-hidden list-none cursor-default transition-colors duration-75',
          props.className,
          selected
            ? 'bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800'
            : 'text-foreground hover:bg-zinc-100 active:bg-zinc-200'
        )}
        aria-current={selected}
      >
        {children}
      </li>
    )
  }
)
