import * as SliderPrimitive from '@radix-ui/react-slider'
import * as React from 'react'

import { BufferedInput } from '@/components/ui/buffered-input'
import { Slider } from '@/components/ui/slider'
import { clamp, coerceToNumber } from '@/lib/numbers'

const InputSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  Omit<React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>, 'value' | 'onValueChange'> & {
    value: number
    onValueChange: (value: number) => void
  }
>(({ value, onValueChange, ...props }, ref) => (
  <div className="flex items-center gap-2">
    <Slider
      {...props}
      value={[value]}
      onValueChange={([v]) => onValueChange?.(v)}
      ref={ref}
      className="w-full"
    />

    <BufferedInput<number>
      className="w-20"
      value={value}
      transformFromT={(v) => v.toLocaleString()}
      transformToT={(v) => clamp(coerceToNumber(v), props.min ?? 0, props.max ?? 100)}
      onValueChange={(v) => onValueChange?.(v)}
    />
  </div>
))
InputSlider.displayName = SliderPrimitive.Root.displayName

export { InputSlider }
