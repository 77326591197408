import { MAX_SD_SEED, randomInt } from '@/lib/numbers'
import { LOCALSTORAGE_KEYS } from '@/utils/consts'
import * as zod from 'zod'

import { QUERY_GENERATION_MODEL_VERSION } from '@/services/model'
import { client } from '@/services/client'
import { GenerationModelType } from '@/services/__generated__/graphql'

const DEFAULT_PRIORITY = 1000

const getPriority = () => {
  const v = localStorage.getItem(LOCALSTORAGE_KEYS.GENERATION_PRIORITY)
  if (v === 'NONE' || v === null) return DEFAULT_PRIORITY
  return parseInt(v) || DEFAULT_PRIORITY
}

export const generationTaskPixaiGeneratorSchema = zod.object({
  prompts: zod.string().max(4096),
  enableTile: zod.boolean().default(false).optional(),
  mediaId: zod.string().optional(),
  mediaUrl: zod.string().optional(),
  negativePrompts: zod.string().max(4096).optional(),
  samplingSteps: zod.number().min(1).max(50).optional(),
  samplingMethod: zod.string().optional(),
  cfgScale: zod.number().min(0).max(100).default(6).optional(),
  seed: zod.union([zod.number(), zod.literal('')]).optional(),
  skipMQ: zod.boolean().optional(),
  modelId: zod.string().optional(),
  upscale: zod.number().optional(),
  upscaleSampler: zod.string().max(100).optional(),
  upscaler: zod.string().max(100).optional(),
  upscaleDenoisingStrength: zod.number().optional(),
  upscaleDenoisingSteps: zod.number().optional(),
  enlarge: zod.number().min(1).max(100).optional(),
  enlargeModel: zod.string().max(64).optional(),
  width: zod.number().min(1).max(10240).optional(),
  height: zod.number().min(1).max(10240).optional(),
  strength: zod.number().optional(),
  controlNets: zod
    .array(
      zod.object({
        weight: zod.number().optional(),
        mediaId: zod.string().max(20).optional(),
        mediaUrl: zod.string().optional(),
        type: zod.string().optional(),
      })
    )
    .max(10)
    .optional(),
  lora: zod.record(zod.number()).optional(),
  latentCouple: zod
    .object({
      type: zod.string().optional(),
      divisions: zod.array(zod.string()).optional(),
      positions: zod.array(zod.string()).optional(),
      weights: zod.array(zod.number()).optional(),
    })
    .optional(),
  maskMediaId: zod.string().optional(),
  maskMediaUrl: zod.string().optional(),
  batchSize: zod.number().min(1).max(4).optional(),
  dynthres: zod
    .object({
      mimicScale: zod.number().optional(),
      thresholdPercentile: zod.number().optional(),
      mimicMode: zod.string().optional(),
      mimicScaleMin: zod.number().optional(),
      cfgMode: zod.string().optional(),
      cfgScaleMin: zod.number().optional(),
      powerscalePower: zod.number().optional(),
    })
    .optional(),
  animateDiff: zod
    .object({
      enabled: zod.boolean().optional(),
      smooth: zod.boolean().optional(),
      long: zod.boolean().optional(),
      v2: zod
        .object({
          motionScale: zod.number().min(0).max(2).optional(),
          denoise: zod.number().min(0).max(1).optional(),
        })
        .optional(),
    })
    .optional(),
  enableADetailer: zod.boolean().optional(),
  clipSkip: zod.number().optional(),
  vaeModelId: zod.string().optional(),
  inputs: zod.record(zod.any()).optional(),
  picsme: zod
    .object({
      enhance_facerestore_enabled: zod.boolean().optional(),
    })
    .optional(),
  ipAdapter: zod
    .object({
      enabled: zod.boolean().optional(),
      referenceImages: zod.array(zod.string()).max(10).optional(),
    })
    .optional(),

  styleMix: zod
    .object({
      enabled: zod.boolean().optional(),
      seed: zod.number().optional(),
      weight: zod.number().optional(),
    })
    .optional(),
})

export type GenerationTaskPixaiGeneratorParameters = zod.infer<
  typeof generationTaskPixaiGeneratorSchema
>

export const generationTaskPixaiGenerator = (
  parameters: GenerationTaskPixaiGeneratorParameters
) => {
  const parsed = generationTaskPixaiGeneratorSchema.parse(parameters)

  return {
    ...parsed,
    priority: getPriority(),
  }
}

export const generationTaskRemoveBackground = ({ mediaId }: { mediaId: string }) => {
  return {
    priority: getPriority(),
    model: 'pixai',
    modelId: '',
    workflowName: 'mymusise/birefnet:v2',
    inputs: {
      '2': {
        image: {
          type: 'media',
          media_id: mediaId,
        },
      },
    },
  } as const
}

export const generationTaskFaceAdetailerSchema = zod.object({
  mediaId: zod.string(),
  prompts: zod.string(),
  steps: zod.number(),
  seed: zod.number().optional(),
  strength: zod.number().min(0).max(1),
})

export type GenerationTaskFaceAdetailerParameters = zod.infer<
  typeof generationTaskFaceAdetailerSchema
>

export const generationTaskFaceAdetailer = (parameters: GenerationTaskFaceAdetailerParameters) => {
  const {
    mediaId,
    prompts,
    steps,
    seed = randomInt(0, MAX_SD_SEED),
    strength,
  } = generationTaskFaceAdetailerSchema.parse(parameters)

  return {
    priority: getPriority(),
    model: 'pixai',
    modelId: '',
    workflow: {
      '1': {
        inputs: {
          guide_size: 384,
          guide_size_for: true,
          max_size: 1024,
          seed: seed,
          steps: steps,
          cfg: 8,
          sampler_name: 'dpmpp_sde',
          scheduler: 'karras',
          denoise: strength, // 修改的强度, 约大和原来的约不像，建议区间[0.5, 0.7]
          feather: 5,
          noise_mask: true,
          force_inpaint: true,
          bbox_threshold: 0.5,
          bbox_dilation: 10,
          bbox_crop_factor: 3,
          sam_detection_hint: 'center-1',
          sam_dilation: 0,
          sam_threshold: 0.93,
          sam_bbox_expansion: 0,
          sam_mask_hint_threshold: 0.7,
          sam_mask_hint_use_negative: 'False',
          drop_size: 10,
          wildcard: '',
          cycle: 1,
          inpaint_model: false,
          noise_mask_feather: 20,
          image: ['4', 0],
          model: ['3', 0],
          clip: ['3', 1],
          vae: ['3', 2],
          positive: ['5', 0],
          negative: ['7', 0],
          bbox_detector: ['2', 0],
        },
        class_type: 'FaceDetailer',
      },
      '2': {
        inputs: {
          model_name: 'bbox/face_yolov8m.pt',
        },
        class_type: 'UltralyticsDetectorProvider',
      },
      '3': {
        // sd模型, 1.5基本就够用
        inputs: {
          ckpt_name: 'Moonbeam',
          model_id: '1648918127446573124',
        },
        class_type: 'CheckpointLoaderSimple',
      },
      '4': {
        // 输入的图片，传media_id就可以
        inputs: {
          image: {
            type: 'media',
            media_id: mediaId,
          },
        },
        class_type: 'LoadImage',
      },
      '5': {
        inputs: {
          text: prompts, // 默认输入prompt，用户可以修改, 比如"smiling, blush"
          clip: ['3', 1],
        },
        class_type: 'CLIPTextEncode',
      },
      '7': {
        inputs: {
          text: 'unclear, ugly',
          clip: ['3', 1],
        },
        class_type: 'CLIPTextEncode',
      },
      '9': {
        inputs: {
          filename_prefix: 'ComfyUI',
          images: ['1', 0],
        },
        class_type: 'SaveImage',
      },
      '12': {
        inputs: {
          text: ['1', 6],
        },
        class_type: 'ShowText|pysssss',
        _meta: {
          title: 'Show Text 🐍',
        },
      },
    },
  }
}

export const generationTaskInpaintSchema = zod.object({
  baseMediaId: zod.string(),
  maskMediaId: zod.string(),
  prompts: zod.string(),
})

export type GenerationTaskInpaintParameters = zod.infer<typeof generationTaskInpaintSchema>

export const generationTaskInpaint = (parameters: GenerationTaskInpaintParameters) => {
  const { baseMediaId, maskMediaId, prompts } = generationTaskInpaintSchema.parse(parameters)

  return {
    priority: getPriority(),
    model: 'pixai',
    modelId: '',
    workflowVersionId: '1768046331465693873',
    inputs: {
      '1': {
        image: {
          type: 'media',
          media_id: baseMediaId, // inpaint base image
        },
      },
      '53': {
        image: {
          type: 'media',
          media_id: maskMediaId, // mask image
        },
      },
      '49': {
        text: prompts,
      },
    },
  }
}

export const generationTaskEnlargeSchema = zod.object({
  mediaId: zod.string(),
  enlarge: zod.number(),
  enlargeModel: zod.string(),
})

export type GenerationTaskEnlargeParameters = zod.infer<typeof generationTaskEnlargeSchema>

export const generationTaskEnlarge = (parameters: GenerationTaskEnlargeParameters) => {
  const { mediaId, enlarge, enlargeModel } = generationTaskEnlargeSchema.parse(parameters)

  return {
    priority: getPriority(),
    mediaId,
    enlarge,
    enlargeModel,
  }
}

export const generationTaskDiffusionMergeSchema = zod.object({
  foregroundMediaId: zod.string(),
  background: zod.object({
    mediaId: zod.string().min(1),
    height: zod.number().min(1).max(10240),
    width: zod.number().min(1).max(10240),
  }),
})

export type GenerationTaskDiffusionMergeParameters = zod.infer<
  typeof generationTaskDiffusionMergeSchema
>

export const generationTaskDiffusionMerge = (
  parameters: GenerationTaskDiffusionMergeParameters
) => {
  const { foregroundMediaId, background } = generationTaskDiffusionMergeSchema.parse(parameters)

  return {
    priority: getPriority(),
    model: 'pixai',
    modelId: '',
    workflow: {
      '2': {
        inputs: {
          ckpt_name: 'Moonbeam',
          model_id: '1648918127446573124',
        },
        class_type: 'CheckpointLoaderSimple',
      },
      '4': {
        inputs: {
          text: '1girl standing on the street, many tree on the background',
          clip: ['2', 1],
        },
        class_type: 'CLIPTextEncode',
      },
      '5': {
        inputs: {
          text: 'worst quality, low quality, manga, portrait, low contrast, painting, surrealism, camera, anime, illustration, low poly, plain, simple, monochrome, industrial, mechanical, geometric patterns, drab, boring, moody, serious,',
          clip: ['2', 1],
        },
        class_type: 'CLIPTextEncode',
      },
      '7': {
        inputs: {
          samples: ['19', 0],
          vae: ['2', 2],
        },
        class_type: 'VAEDecode',
      },
      '9': {
        inputs: {
          image: {
            type: 'media',
            media_id: foregroundMediaId,
          },
        },
        class_type: 'LoadImage',
      },
      '19': {
        inputs: {
          seed: randomInt(0, MAX_SD_SEED),
          steps: 25,
          cfg: 2.98,
          sampler_name: 'dpmpp_2m',
          scheduler: 'karras',
          denoise: 1,
          model: ['37', 0],
          positive: ['77', 0],
          negative: ['77', 1],
          latent_image: ['50', 0],
        },
        class_type: 'KSampler',
      },
      '22': {
        inputs: {
          shape: 'circle',
          frames: 1,
          location_x: 512,
          location_y: 256,
          grow: 81,
          frame_width: ['54', 1],
          frame_height: ['54', 2],
          shape_width: 512,
          shape_height: 512,
        },
        class_type: 'CreateShapeMask',
      },
      '35': {
        inputs: {
          mask: ['75', 0],
        },
        class_type: 'MaskToImage',
      },
      '36': {
        inputs: {
          images: ['7', 0],
        },
        class_type: 'PreviewImage',
      },
      '37': {
        inputs: {
          model_path: {
            __name: 'model_path',
            type: 'custom_model',
            dir: 'models/unet',
            file_id: '1777151287773525602',
          },
          model: ['2', 0],
        },
        class_type: 'LoadAndApplyICLightUnet',
      },
      '50': {
        inputs: {
          pixels: ['35', 0],
          vae: ['2', 2],
        },
        class_type: 'VAEEncode',
      },
      '51': {
        inputs: {
          image: {
            type: 'media',
            media_id: background.mediaId,
          },
        },
        class_type: 'LoadImage',
      },
      '52': {
        inputs: {
          width: 512,
          height: 768,
          batch_size: 1,
        },
        class_type: 'EmptyLatentImage',
      },
      '53': {
        inputs: {
          width: background.width,
          height: background.height,
          interpolation: 'lanczos',
          method: 'keep proportion',
          condition: 'always',
          multiple_of: 0,
          image: ['74', 0],
        },
        class_type: 'ImageResize+',
      },
      '54': {
        inputs: {
          width: ['53', 1],
          height: ['53', 2],
          interpolation: 'nearest',
          method: 'keep proportion',
          condition: 'always',
          multiple_of: 0,
          image: ['51', 0],
        },
        class_type: 'ImageResize+',
      },
      '74': {
        inputs: {
          version: 'v1.4',
          fp16: true,
          bg_color: '#7F7F7F',
          batch_size: 4,
          video_frames: ['9', 0],
        },
        class_type: 'BRIAAI Matting',
      },
      '75': {
        inputs: {
          expand: 0,
          incremental_expandrate: 0,
          tapered_corners: true,
          flip_input: false,
          blur_radius: 28.1,
          lerp_alpha: 1,
          decay_factor: 1,
          fill_holes: false,
          mask: ['22', 0],
        },
        class_type: 'GrowMaskWithBlur',
      },
      '77': {
        inputs: {
          multiplier: 0.25,
          positive: ['4', 0],
          negative: ['5', 0],
          vae: ['2', 2],
          foreground: ['78', 0],
          opt_background: ['80', 0],
        },
        class_type: 'ICLightConditioning',
      },
      '78': {
        inputs: {
          pixels: ['53', 0],
          vae: ['2', 2],
        },
        class_type: 'VAEEncode',
      },
      '80': {
        inputs: {
          pixels: ['54', 0],
          vae: ['2', 2],
        },
        class_type: 'VAEEncode',
      },
    },
  }
}
export const generationTaskHyperSD = async ({
  modelId,
  prompts,
  negativePrompts,
  width,
  height,
  batchSize,
}: GenerationTaskPixaiGeneratorParameters) => {
  const typeToWorkflowName = {
    sdxl: 'mymusise/hyper-sd-text-2-image:t2i-sdxl-v1',
    sd15: 'mymusise/hyper-sd-text-2-image:t2i-sd15-v1',
    waterfront: 'mymusise/hyper-sd-text-2-image-waterfront:v2',
  }
  if (!modelId) throw new Error('modelId is required: generationTaskHyperSD')
  const type = await modelIdToType(modelId)

  return {
    priority: getPriority(),
    model: 'pixai',
    modelId: '',
    workflowName: typeToWorkflowName[type],
    inputs: {
      model_id: modelId,
      positive_prompt: prompts,
      negative_prompt:
        negativePrompts ||
        'worst quality, low quality, manga, portrait, low contrast, painting, surrealism, camera, anime, illustration, low poly, plain, simple, monochrome, industrial, mechanical, geometric patterns, drab, boring, moody, serious,',
      width,
      height,
      batch_size: batchSize,
      seed: randomInt(0, MAX_SD_SEED),
      cfg: 2.5,
      sampler_name: 'euler_cfg_pp',
      scheduler: 'sgm_uniform',
      rescale_cfg: 0.7,
    },
  } as const
}

export const generationTaskCreativeUpscaleSchema = zod.object({
  mediaId: zod.string(),
  modelId: zod.string(),
  creative: zod.number().min(0).max(1).default(0.2),
  prompt: zod.string().optional(),
})

export type GenerationTaskCreativeUpscaleParameters = zod.infer<
  typeof generationTaskCreativeUpscaleSchema
>

export const generationTaskCreativeUpscale = async (
  parameters: GenerationTaskCreativeUpscaleParameters
) => {
  const typeToWorkflowName = {
    sdxl: {
      tagger: 'mymusise/creative-upscale:sdxl-2x-v2',
      prompt: 'mymusise/creative-upscale:sdxl-2x-prompt-v2',
    },
    sd15: {
      tagger: 'mymusise/creative-upscale:sd15-2x-v2',
      prompt: 'mymusise/creative-upscale:sd15-2x-prompt-v2',
    },
    waterfront: {
      tagger: 'mymusise/creative-upscale:waterfront-2x-v2',
      prompt: 'mymusise/creative-upscale:waterfront-2x-prompt-v2',
    },
  }
  const { mediaId, modelId, creative, prompt } =
    generationTaskCreativeUpscaleSchema.parse(parameters)

  const type = await modelIdToType(modelId)

  const workflow = prompt ? typeToWorkflowName[type].prompt : typeToWorkflowName[type].tagger

  return {
    priority: getPriority(),
    model: 'pixai',
    modelId: '',
    workflowName: workflow,
    inputs: {
      model_id: modelId,
      image: {
        type: 'media',
        media_id: mediaId,
      },
      creative,
      prompt,
    },
  } as const
}

const modelIdToType = async (modelId: string) => {
  const model = await client.query({
    query: QUERY_GENERATION_MODEL_VERSION,
    variables: {
      id: modelId,
    },
  })
  if (!model.data.generationModelVersion?.modelType) {
    throw new Error('Model not found')
  }
  if (model.data.generationModelVersion?.modelType === GenerationModelType.SdxlModel) {
    if (
      model.data.generationModelVersion?.extra?.category &&
      model.data.generationModelVersion?.extra?.category === 'beta-sdxl'
    ) {
      return 'waterfront'
    } else {
      return 'sdxl'
    }
  } else {
    return 'sd15'
  }
}
