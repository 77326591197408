import { Canvas } from '@/components/Canvas'
import { useQuery } from '@apollo/client'
import AlphaTestLogin from '../components/AlphaTestLogin'
import { QUERY_MY_ROLE } from '../services/user'
import { useAuthToken } from '../states/auth'

export function AppRoot() {
  const [authToken] = useAuthToken()
  const myRole = useQuery(QUERY_MY_ROLE, {
    variables: {
      first: 100,
    },
  })

  return (
    <>
      {authToken &&
        myRole?.data?.me?.roles?.edges?.find((role) => {
          return role?.node?.name === 'pixstudio:tester:alpha'
        }) ? (
        <Canvas />
      ) : myRole.loading ? null : (
        <AlphaTestLogin />
      )}
    </>
  )
}
