import { stopEventPropagation, TLFrameShape, useEditor } from '@troph-team/tldraw'
import { motion } from 'framer-motion'
import { FC } from 'react'
import RadixIconsDoubleArrowDown from '~icons/radix-icons/double-arrow-down'

export const InFrontOfTheCanvasWebtoonAddPageSection: FC<{
  shape: TLFrameShape
}> = ({ shape }) => {
  const editor = useEditor()

  const shapeScreenBox = editor.pageToScreenBox(editor.getShapePageBounds(shape)!)
  const shapeBottomLeft = {
    x: shapeScreenBox.x,
    y: shapeScreenBox.y + shapeScreenBox.height,
  }

  return (
    <motion.button
      key={`webtoon-add-${shape.id}`}
      style={{
        x: shapeBottomLeft.x,
        y: shapeBottomLeft.y + 6,
        width: shapeScreenBox.width,
      }}
      className="group pointer-events-auto absolute left-0 top-0 z-canvas text-foreground"
      transition={{ type: 'spring', damping: 20, stiffness: 300 }}
      onPointerDown={stopEventPropagation}
      onClick={() => {
        editor.updateShapes<TLFrameShape>([
          {
            id: shape.id,
            type: 'frame',
            props: {
              h: shape.props.h + 1200,
            },
          },
        ])
      }}
    >
      <div
        className="relative flex h-6 items-center justify-center opacity-30 transition group-hover:opacity-100 group-active:brightness-75"
        style={{ width: shapeScreenBox.width }}
      >
        <div className="h-2 flex-1 rounded-full bg-primary" />
        <div className="absolute top-0 flex size-6 items-center justify-center rounded-full bg-primary">
          <RadixIconsDoubleArrowDown className="size-4 text-white" />
        </div>
      </div>
    </motion.button>
  )
}
