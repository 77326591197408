import { useMemo, useState } from 'react'
import { LayerListContainer } from './canvas/layer/LayerListContainer'
import { ChevronDown, Layers } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

export default function LayersPanel() {
  const { t } = useTranslation()
  const layerListContainer = useMemo(() => <LayerListContainer key="layers" dark />, [])

  const [isLayerListOpen, setIsLayerListOpen] = useState(false)
  return (
    <div className="relative mb-2">
      <AnimatePresence>
        {isLayerListOpen && (
          <motion.div
            className="flex h-[50dvh] max-w-[30rem] flex-col overflow-hidden rounded-lg bg-zinc-900/80 pb-8 backdrop-blur"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
          >
            <div className="flex items-end justify-start">
              <div className="m-2 select-none px-4 py-1 text-base font-bold text-white">
                {t('layers.title')}
              </div>
            </div>
            <div className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-zinc-700/40 flex-1 overflow-y-auto px-2 pt-2">
              {layerListContainer}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <button
        className="absolute bottom-0 left-0 flex size-8 items-center justify-center rounded bg-zinc-800 p-0 text-white hover:bg-zinc-700 active:bg-zinc-900"
        onClick={() => setIsLayerListOpen(!isLayerListOpen)}
      >
        {isLayerListOpen ? <ChevronDown className="size-6" /> : <Layers className="size-4" />}
      </button>
    </div>
  )
}
