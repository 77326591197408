import { FontStylesPanel } from '@/components/canvas/styles/FontStylesPanel'
import { TLDefaultShape, track, useEditor } from '@troph-team/tldraw'
import { FC } from 'react'
import { RichTextStylesPanel } from './styles/RichTextStylesPanel'
import { ImageStylesPanel } from './styles/ImageStylesPanel'
import { LinesStylesPanel } from './styles/LineStylesPanel'
import { BackgroundAndStrokePanel } from './styles/BackgroundAndStrokePanel'

export const ShapeStylesPanel: FC = track(() => {
  const selectedShapes = useEditor().getSelectedShapes()

  if (selectedShapes.length === 0) {
    return (
      <div className="flex h-full items-center justify-center py-4 text-gray-500">
        No shape selected
      </div>
    )
  } else if (selectedShapes.length > 1) {
    return (
      <div className="flex h-full items-center justify-center p-4 text-center text-gray-500">
        Multiple shapes selected. Editing multiple shapes is currently not yet supported.
      </div>
    )
  }

  const shape = selectedShapes[0] as TLDefaultShape

  const styles = [
    (shape.type === 'geo' || shape.type === 'dialogue' || shape.type === 'rich-dialogue') && (
      <BackgroundAndStrokePanel shape={shape} key="fontStylesPanel" />
    ),
    (shape.type === 'geo' || shape.type === 'text' || shape.type === 'dialogue') && (shape.props.text) && (
      <FontStylesPanel shape={shape} key="fontStylesPanel" />
    ),
    (shape.type === 'rich-dialogue' || shape.type === 'rich-text') && (
      <RichTextStylesPanel shape={shape} key="dialogueStylesPanel" />
    ),
    (shape.type === 'genmedia' || shape.type === 'image') && <ImageStylesPanel shape={shape} />,
    (shape.type === 'focus-lines' || shape.type === 'speed-lines') && (
      <LinesStylesPanel shape={shape} />
    ),
  ].filter(Boolean)

  if (styles.length === 0) {
    return (
      <div className="flex h-full items-center justify-center py-4 text-gray-500">
        No editable properties
      </div>
    )
  }

  return <div className="flex flex-col">{styles}</div>
})
