import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { TooltipPortal } from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { FC, ReactNode, useState } from 'react'
import RadixIconsQuestionMarkCircled from '~icons/radix-icons/question-mark-circled'

export const HelpIcon: FC<{
  content?: ReactNode
}> = ({ content }) => {
  const [open, setOpen] = useState(false)

  return (
    <Tooltip open={open} onOpenChange={setOpen}>
      <TooltipTrigger>
        <RadixIconsQuestionMarkCircled
          className={clsx('size-4 transition', open ? 'opacity-100' : 'opacity-50')}
        />
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent arrowPadding={1} sideOffset={2} side="bottom">
          <div className="max-w-xs py-1 text-xs">{content}</div>
          <TooltipArrow className="text-white" />
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}
