import { roundMultipleOf } from '@/lib/numbers'
import { randomId } from '@/lib/strings'
import invariant from '@/utils/invariant'
import { createMangaFramePreset } from '@/utils/shapes/frame-family/presets'
import {
  AssetRecordType,
  Editor,
  PSGenBoxShape,
  PSGenMediaShape,
  TLPage,
  TLTextShape,
  createShapeId,
} from '@troph-team/tldraw'

export function createPage(editor: Editor, namePrefix: string, meta: TLPage['meta']) {
  const samePrefixPages = editor
    .getPages()
    .map((page) => page.name)
    .filter((name) => name.startsWith(namePrefix))
  let newPageName: string | undefined = undefined
  for (let i = 0; i < 200; i++) {
    newPageName = `${namePrefix} ${i + 1}`
    if (!samePrefixPages.includes(newPageName)) {
      break
    }
  }
  if (newPageName === undefined) {
    newPageName = `${namePrefix} ${randomId(8)}`
  }

  editor.createPage({ name: newPageName, meta })

  const pages = editor.getPages()
  const newPageId = pages.find((page) => page.name === newPageName)?.id
  invariant(newPageId, 'New page id not found')

  editor.setCurrentPage(newPageId)

  const shapeId = createShapeId(`genbox-${newPageId}-${randomId(16)}`)
  const pageCenter = editor.getViewportPageCenter()
  editor.createShape({
    type: 'genbox',
    id: shapeId,
    x: roundMultipleOf(pageCenter.x - 768 / 2, 8),
    y: roundMultipleOf(pageCenter.y - 1280 / 2, 8),
    parentId: newPageId,
    // arbitrarily large z: https://github.com/troph-team/tldraw/blob/466c26c1a0fd85a80ad08027e29426012d3cb53d/packages/tldraw-editor/src/lib/utils/reordering/dgreensp/dgreensp.ts#L158
    index: 'z99999999999999999999999999',
    props: {
      w: 768,
      h: 1280,
    },
  })

  const genBox = editor.getShape<PSGenBoxShape>(shapeId)!
  editor.select(genBox.id)
  editor.zoomToSelection({
    duration: 200,
  })

  return editor.getCurrentPage()
}

const createDemoAssets = (editor: Editor) => {
  const assets = [
    {
      type: 'image' as const,
      props: {
        name: 'Generated Image',
        src: 'https://images-ng.pixai.art/images/orig/093ec1ae-5f8c-4aad-95fb-0b6a46d7f0c7',
        w: 768,
        h: 1024,
        mimeType: 'image/webp',
        isAnimated: false,
      },
      meta: {
        mediaId: '472821060510948526',
        media: {
          __typename: 'Media',
          id: '472821060510948526',
          type: 'IMAGE',
          width: 768,
          height: 1024,
          urls: [
            {
              __typename: 'ImageUrl',
              variant: 'PUBLIC',
              url: 'https://images-ng.pixai.art/images/orig/093ec1ae-5f8c-4aad-95fb-0b6a46d7f0c7',
            },
            {
              __typename: 'ImageUrl',
              variant: 'THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/thumb/093ec1ae-5f8c-4aad-95fb-0b6a46d7f0c7',
            },
            {
              __typename: 'ImageUrl',
              variant: 'STILL_THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/stillThumb/093ec1ae-5f8c-4aad-95fb-0b6a46d7f0c7',
            },
          ],
          imageType: 'webp',
          fileUrl: null,
          duration: null,
          thumbnailUrl: null,
          hlsUrl: null,
          size: null,
          flag: null,
        },
      },
      typeName: 'asset' as const,
    },
    {
      type: 'image' as const,
      props: {
        name: 'Generated Image',
        src: 'https://images-ng.pixai.art/images/orig/70ec32ae-3705-4dcf-90c8-13c916a4502f',
        w: 768,
        h: 1024,
        mimeType: 'image/webp',
        isAnimated: false,
      },
      meta: {
        mediaId: '472821060639189227',
        media: {
          __typename: 'Media',
          id: '472821060639189227',
          type: 'IMAGE',
          width: 768,
          height: 1024,
          urls: [
            {
              __typename: 'ImageUrl',
              variant: 'PUBLIC',
              url: 'https://images-ng.pixai.art/images/orig/70ec32ae-3705-4dcf-90c8-13c916a4502f',
            },
            {
              __typename: 'ImageUrl',
              variant: 'THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/thumb/70ec32ae-3705-4dcf-90c8-13c916a4502f',
            },
            {
              __typename: 'ImageUrl',
              variant: 'STILL_THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/stillThumb/70ec32ae-3705-4dcf-90c8-13c916a4502f',
            },
          ],
          imageType: 'webp',
          fileUrl: null,
          duration: null,
          thumbnailUrl: null,
          hlsUrl: null,
          size: null,
          flag: null,
        },
      },
      typeName: 'asset' as const,
    },
    {
      type: 'image' as const,
      props: {
        name: 'Generated Image',
        src: 'https://images-ng.pixai.art/images/orig/d8bac17c-5c1a-4be1-9f3e-5a524721e358',
        w: 768,
        h: 1024,
        mimeType: 'image/webp',
        isAnimated: false,
      },
      meta: {
        mediaId: '472822198390728269',
        media: {
          __typename: 'Media',
          id: '472822198390728269',
          type: 'IMAGE',
          width: 768,
          height: 1024,
          urls: [
            {
              __typename: 'ImageUrl',
              variant: 'PUBLIC',
              url: 'https://images-ng.pixai.art/images/orig/d8bac17c-5c1a-4be1-9f3e-5a524721e358',
            },
            {
              __typename: 'ImageUrl',
              variant: 'THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/thumb/d8bac17c-5c1a-4be1-9f3e-5a524721e358',
            },
            {
              __typename: 'ImageUrl',
              variant: 'STILL_THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/stillThumb/d8bac17c-5c1a-4be1-9f3e-5a524721e358',
            },
          ],
          imageType: 'webp',
          fileUrl: null,
          duration: null,
          thumbnailUrl: null,
          hlsUrl: null,
          size: null,
          flag: null,
        },
      },
      typeName: 'asset' as const,
    },
    {
      type: 'image' as const,
      props: {
        name: 'Generated Image',
        src: 'https://images-ng.pixai.art/images/orig/1d8e274c-6569-4e44-ab16-3f20fed5482a',
        w: 768,
        h: 1024,
        mimeType: 'image/webp',
        isAnimated: false,
      },
      meta: {
        mediaId: '472822198398116465',
        media: {
          __typename: 'Media',
          id: '472822198398116465',
          type: 'IMAGE',
          width: 768,
          height: 1024,
          urls: [
            {
              __typename: 'ImageUrl',
              variant: 'PUBLIC',
              url: 'https://images-ng.pixai.art/images/orig/1d8e274c-6569-4e44-ab16-3f20fed5482a',
            },
            {
              __typename: 'ImageUrl',
              variant: 'THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/thumb/1d8e274c-6569-4e44-ab16-3f20fed5482a',
            },
            {
              __typename: 'ImageUrl',
              variant: 'STILL_THUMBNAIL',
              url: 'https://images-ng.pixai.art/images/stillThumb/1d8e274c-6569-4e44-ab16-3f20fed5482a',
            },
          ],
          imageType: 'webp',
          fileUrl: null,
          duration: null,
          thumbnailUrl: null,
          hlsUrl: null,
          size: null,
          flag: null,
        },
      },
      typeName: 'asset' as const,
    },
  ]

  return assets.map((asset) => {
    const id = AssetRecordType.createId(asset.meta.mediaId)
    editor.createAssets([
      {
        id,
        ...asset,
      },
    ])
    return id
  })
}

export function initializeCurrentPageAsEmptyPage(editor: Editor) {
  const pageCenter = editor.getViewportPageCenter()
  const page = editor.getCurrentPage()
  const textShapeId = createShapeId(`text-${page.id}`)
  const demoImageShapeId = createShapeId(`genmedia-${page.id}`)

  editor.createShapes<TLTextShape>([
    {
      id: textShapeId,
      type: 'text',
      x: pageCenter.x / 2,
      y: pageCenter.y / 2,
      props: {
        w: 400,
        autoSize: false,
        text: 'Welcome to PixStudio! :D',
      },
      parentId: page.id,
    },
  ])

  const assetIds = createDemoAssets(editor)

  editor.createShapes<PSGenMediaShape>([
    {
      x: pageCenter.x / 2 - 350,
      y: pageCenter.y / 2 + 200,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {
        generationTask: {
          id: '1763120412606466637',
          parameters: {
            width: 768,
            height: 1024,
            modelId: '1684657781067182082',
            prompts:
              '(((Best quality))), (((Ultra detailed))), ((((vivid)))), landscape, (((fireflies, particles))), (((flower garden, colorful flowers, beautiful flowers))), ((blue sky, cumulonimbus)), <lora:more_details:0.5>, 1girl',
            priority: 1500,
            batchSize: 4,
            ipAdapter: {
              referenceImageMedias: [],
            },
          },
          outputs: {
            duration: 20.47,
            detailParameters: {
              height: 1024,
              width: 768,
              cfg_scale: 6.5,
              steps: 20,
              sampler: 'Restart',
              prompt:
                '(((Best quality))), (((Ultra detailed))), ((((vivid)))), landscape, (((fireflies, particles))), (((flower garden, colorful flowers, beautiful flowers))), ((blue sky, cumulonimbus)), <lora:more_details:0.5>, 1girl',
              negative_prompt:
                '(2000s:2), (2010s:1.3), text, error, signature, watermark, username, realistic,3d, lowres, cropped, low quality, jpeg artifacts, blurry, bad anatomy, bad hands, bad arms, bad feet,  manga-like, lowres, (2000s:1.3), (2010s:1.3), text, error, signature, watermark, username, realistic,3d, lowres, cropped, low quality, jpeg artifacts, blurry, bad anatomy, bad hands, bad arms, bad feet, lowres',
              seed: 8151364296299055,
              batchSize: 4,
              modelId: '1684657781067182082',
              clipSkip: 2,
              madeBy: '6wtlb',
            },
            mediaId: '472821058236977625',
            batch: [
              {
                seed: 8151364296299055,
                mediaId: '472821060510948526',
                extra: {
                  node_id: '4',
                },
              },
              {
                seed: 8151364296299055,
                mediaId: '472821060639189227',
                extra: {
                  node_id: '4',
                },
              },
              {
                seed: 8151364296299055,
                mediaId: '472821059727097875',
                extra: {
                  node_id: '4',
                },
              },
              {
                seed: 8151364296299055,
                mediaId: '472821059939181003',
                extra: {
                  node_id: '4',
                },
              },
            ],
          },
          status: 'completed',
          priority: 1500,
          runnerId: '1564694851891740680',
          startedAt: '2024-06-27T17:41:42.257Z',
          endAt: '2024-06-27T17:42:05.667Z',
          createdAt: '2024-06-27T17:41:20.558Z',
          updatedAt: '2024-06-27T17:41:20.558Z',
          retryCount: 0,
          media: {
            __typename: 'Media',
            id: '472821058236977625',
            type: 'IMAGE',
            width: 1536,
            height: 2048,
            urls: [
              {
                variant: 'PUBLIC',
                url: 'https://images-ng.pixai.art/images/orig/d5c0ec50-bc8f-4407-ab6e-ecfcf2efaa80',
                __typename: 'ImageUrl',
              },
              {
                variant: 'THUMBNAIL',
                url: 'https://images-ng.pixai.art/images/thumb/d5c0ec50-bc8f-4407-ab6e-ecfcf2efaa80',
                __typename: 'ImageUrl',
              },
              {
                variant: 'STILL_THUMBNAIL',
                url: 'https://images-ng.pixai.art/images/stillThumb/d5c0ec50-bc8f-4407-ab6e-ecfcf2efaa80',
                __typename: 'ImageUrl',
              },
            ],
            imageType: 'webp',
            fileUrl: null,
            duration: null,
            thumbnailUrl: null,
            hlsUrl: null,
            size: null,
            flag: null,
          },
          __typename: 'Task',
        },
      },
      type: 'genmedia',
      props: {
        w: 768,
        h: 1024,
        currentAssetId: assetIds[2],
        pending: null,
        playing: true,
        alert: null,
        crop: null,
        prompts:
          '(((Best quality))), (((Ultra detailed))), ((((vivid)))), landscape, (((fireflies, particles))), (((flower garden, colorful flowers, beautiful flowers))), ((blue sky, cumulonimbus)), <lora:more_details:0.5>, 1girl',
        assetIds,
      },
      parentId: page.id,
      id: demoImageShapeId,
      typeName: 'shape',
    },
  ])

  editor.zoomToFit()
}

export function initializeCurrentPageAsWebtoonPage(editor: Editor) {
  createWebtoonGeoFramesOnCurrentPage(editor)
}

export function initializeCurrentPageAsMangaPage(editor: Editor) {
  createMangaGeoFramesOnCurrentPage(editor)
  editor.zoomToFit()
}

// function pointsToHandles(points: [number, number][]): Record<string, TLHandle> {
//   return points.reduce((acc, point, i) => {
//     acc[i] = {
//       id: `handle-${i}`,
//       type: "vertex",
//       canBind: true,
//       canSnap: true,
//       index: i.toString(),
//       x: point[0],
//       y: point[1],
//     };
//     return acc;
//   }, {} as Record<string, TLHandle>);
// }

function createMangaGeoFramesOnCurrentPage(editor: Editor) {
  console.info('Creating geo frames on current page')

  editor.createShapes(createMangaFramePreset({ x: 0, y: 0 }))
}

function createWebtoonGeoFramesOnCurrentPage(editor: Editor) {
  const id = createShapeId(`geoframe-${randomId(16)}`)
  const containerFrame = {
    id,
    x: 0,
    y: 0,
    type: 'frame' as const,
    props: {
      w: 595 * 2,
      h: 700 * 2,
      name: 'Webtoon Frame',
    },
  }

  editor.createShapes([containerFrame])

  editor.zoomToFit()

  // separating to two steps is to make the zoom level not too large
  editor.updateShapes(
    [
      {
        id,
        type: 'frame',
        props: {
          h: 1700 * 2,
        },
      },
    ],
    {
      squashing: true,
    }
  )
}
