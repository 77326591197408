import { Label } from '@/components/ui/label'
import { RadioCard } from '@/components/ui/radio-card'
import { PSRichDialogueShape, PSRichTextShape, useEditor } from '@troph-team/tldraw'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tiptap } from './richText/TipTap'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion'

export const RichTextStylesPanel: FC<{
  shape: PSRichDialogueShape | PSRichTextShape
}> = ({ shape }) => {
  const { t } = useTranslation()

  const editor = useEditor()

  return (
    <>
      <Accordion type="single" collapsible className="w-full border-b-2 px-4" defaultValue="fonts">
        <AccordionItem value="fonts">
          <AccordionTrigger className="font-bold">{t('style-panel.rich-text')}</AccordionTrigger>
          <AccordionContent className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <Tiptap
                key={shape.id + shape.props.writingMode}
                shape={shape}
                defaultValue={shape.props.data}
                onUpdate={(props: { w?: number; h?: number; data: string }) =>
                  editor.updateShape({
                    id: shape.id,
                    type: shape.type,
                    props,
                  })
                }
              />
              <div className="mt-2 flex flex-col gap-1">
                <Label>{t('style-panel.layout')}</Label>

                <div className="mt-1 grid grid-cols-6 gap-1">
                  <RadioCard
                    className="col-span-2"
                    icon={<span className="text-xl font-bold leading-none opacity-90">Aa</span>}
                    title={t('font-layout.horizontal')}
                    selected={shape.props.writingMode === 'horizontal-tb'}
                    onClick={() =>
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { writingMode: 'horizontal-tb' },
                      })
                    }
                  />
                  <RadioCard
                    className="col-span-2"
                    icon={
                      <span
                        className="text-xl font-bold leading-none opacity-90"
                        style={{ writingMode: 'vertical-lr' }}
                      >
                        Aa
                        <br />
                        Bb
                      </span>
                    }
                    title={t('font-layout.vertical-lr')}
                    selected={shape.props.writingMode === 'vertical-lr'}
                    onClick={() =>
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { writingMode: 'vertical-lr' },
                      })
                    }
                  />
                  <RadioCard
                    className="col-span-2"
                    icon={
                      <span
                        className="text-xl font-bold leading-none opacity-90"
                        style={{ writingMode: 'vertical-rl' }}
                      >
                        Aa
                        <br />
                        Bb
                      </span>
                    }
                    title={t('font-layout.vertical-rl')}
                    selected={shape.props.writingMode === 'vertical-rl'}
                    onClick={() =>
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { writingMode: 'vertical-rl' },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  )
}
