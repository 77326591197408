import { client } from '@/services/client'
import { QUERY_GET_MEDIA } from '@/services/media'
import { QUERY_GET_TASK_BY_ID, SUBSCRIPTION_PERSONAL_EVENTS } from '@/services/tasks'
import { useAuthToken } from '@/states/auth'
import { useEditor } from '@/states/EditorProvider'
import { useGenerationService } from '@/states/Generation'
import { DetailedError, fireErrorToast } from '@/utils/errors'
import invariant from '@/utils/invariant'
import { getPublicUrl } from '@/utils/url'
import { useSubscription } from '@apollo/client'
import { AssetRecordType, Editor, PSGenMediaShape, TLShapeId } from '@troph-team/tldraw'
import { useEffect, useMemo } from 'react'
import { toast } from 'sonner'

function formatPendingStatus(status?: string | null) {
  switch (status) {
    case 'pending':
      return 'Waiting...'
    case 'completed':
      return 'Finalizing...'
    case 'failed':
      return null
    case 'running':
      return 'Generating...'
    case 'waiting':
      return 'Waiting...'
    default:
      return '[Unknown]'
  }
}

export const GenerationOnCanvasController = () => {
  const editor = useEditor()
  const [jwt] = useAuthToken()

  const Component = useMemo(() => {
    if (!jwt) return null
    return <GenerationOnCanvasControllerTaskUpdater />
  }, [jwt])

  useEffect(() => {
    invariant(editor, 'Editor is not available')

    // loop through all pending "genmedia"s and check if their tasks are completed
    // TODO: check shapes on other pages as well
    const shapes = editor
      .getCurrentPageShapes()
      .filter((shape) => shape.type === 'genmedia') as PSGenMediaShape[]

    shapes.forEach(async (shape) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const task = shape.meta?.generationTask as any
      if (!task) return
      if (task.status !== 'completed' && task.status !== 'failed') {
        console.log('Checking task', task)
        const id = toast.loading('Checking previously unfinished generation tasks...')
        try {
          const { data } = await client.query({
            query: QUERY_GET_TASK_BY_ID,
            variables: { id: task.id },
          })
          console.log('Task data', data.task?.status)
          editor.updateShapeById(
            shape.id,
            {
              props: {
                pending: formatPendingStatus(data.task?.status),
              },
              meta: {
                generationTask: data.task,
              },
            },
            {
              ephemeral: true,
            }
          )

          if (data.task?.status === 'completed') {
            await handleTaskCompleted(editor, shape.id, data.task)

            toast.success(
              'A previously unfinished generation task has now completed, and the corresponding shape has been updated.'
            )
          } else if (data.task?.status === 'failed') {
            fireErrorToast(
              'Failed to generate image',
              new DetailedError(
                data.task.outputs?.message ??
                  'Generator has encountered an error while processing this request.',
                data
              )
            )
            return editor.updateShapeById(
              shape.id,
              {
                props: {
                  pending: null,
                  alert: 'Generation Failed',
                },
              },
              {
                squashing: true,
              }
            )
          } else if (data.task?.status !== 'running' && data.task?.status !== 'waiting') {
            return editor.updateShapeById(
              shape.id,
              {
                props: {
                  pending: null,
                  alert: 'Unknown Status: ' + data.task?.status,
                },
              },
              {
                squashing: true,
              }
            )
          }
        } catch (e) {
          console.error('Failed to check task', e)
          toast.error('Failed to check previously unfinished generation tasks.')
        } finally {
          toast.dismiss(id)
        }
      }
      return []
    })
  }, [editor])

  return <>{Component}</>
}

const GenerationOnCanvasControllerTaskUpdater = () => {
  const generation = useGenerationService()
  const editor = useEditor()

  useSubscription(SUBSCRIPTION_PERSONAL_EVENTS, {
    onData({ data }) {
      console.debug('Received personal event', data)
      invariant(data.data?.personalEvents?.taskUpdated, 'Invalid data')
      const task = data.data.personalEvents.taskUpdated

      const localTask = generation.pendingTasks.find((t) => t.remoteId === task.id)
      try {
        invariant(localTask, 'Unable to find local task for remote task ' + task.id)
      } catch (e) {
        // Reason for try-catch instead of throwing an error:
        // Unable to find local task is commonly found when user deleted the corresponding
        // local shape of that particular task. Thus it is not necessarily an error that needs
        // to be captured by Sentry.
        // However, we still want `invariant` to narrow the type of localTask, and thus we keep
        // `invariant` usage instead of just using a `if` statement and do early return.
        console.warn(e)
        return
      }
      editor.updateShapeById(
        localTask.localShapeId,
        {
          props: {
            pending: formatPendingStatus(task.status),
          },
          meta: {
            generationTask: task,
          },
        },
        {
          ephemeral: true,
        }
      )

      if (task.status === 'completed') {
        handleTaskCompleted(editor, localTask.localShapeId, task).finally(() => {
          generation.removePendingTask(localTask.localShapeId)
        })
      } else if (task.status === 'failed') {
        generation.removePendingTask(localTask.localShapeId)
        fireErrorToast(
          'Failed to generate image',
          new DetailedError('Failed to generate image', task)
        )
        editor.updateShapeById(
          localTask.localShapeId,
          {
            props: {
              pending: null,
              alert: 'Generation Failed',
            },
          },
          {
            ephemeral: true,
          }
        )
      } else if (task.status !== 'running' && task.status !== 'waiting') {
        editor.updateShapeById(
          localTask.localShapeId,
          {
            props: {
              pending: null,
              alert: 'Unknown Status: ' + task.status,
            },
          },
          {
            ephemeral: true,
          }
        )
      }
    },
  })

  return <></>
}

const handleTaskCompleted = async (
  editor: Editor,
  localShapeId: TLShapeId,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  task: any
) => {
  invariant(task.media, 'Task is missing media')

  const mediaIds = (task.outputs?.batch?.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (output: any) => output.mediaId as string
  ) ?? [task.media.id]) as string[]
  const medias = await Promise.all(
    mediaIds.map(async (mediaId) => {
      const { data } = await client.query({
        query: QUERY_GET_MEDIA,
        variables: { id: mediaId },
      })
      return {
        media: data.media,
        url: getPublicUrl(data.media),
      }
    })
  )

  const assetIds = medias.map((media) => {
    if (!media.url) {
      console.warn('Media URL is missing', media)
      return
    }

    const assetId = AssetRecordType.createId(media.media?.id)

    editor.createAssets([
      {
        id: assetId,
        type: 'image',
        typeName: 'asset',
        props: {
          name: 'Generated Image ' + assetId,
          src: media.url ?? '', // You could also use a base64 encoded string here
          w: media.media?.width ?? 0,
          h: media.media?.height ?? 0,
          mimeType: 'image/webp',
          isAnimated: false,
        },
        meta: {
          mediaId: media.media?.id,
          media: media.media,
        },
      },
    ])

    // Preload the image
    new Image().src = media.url

    return assetId
  })

  editor.updateShape(
    {
      id: localShapeId,
      type: 'genmedia',
      props: {
        currentAssetId: assetIds?.[0],
        assetIds: assetIds,
        pending: null,
      },
      meta: {
        generationTask: task,
      },
    },
    {
      squashing: true,
    }
  )
}
