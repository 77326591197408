import { cn } from '@/lib/utils'
import { forwardRef, Fragment } from 'react'

export interface KbdProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The commands to display in the input.
   *
   * Special keys:
   * `+` - AND combinations.
   * `,` - OR combinations.
   * `meta` - Command on macOS, Control on Windows.
   * `ctrl` - Control on macOS, Windows, and Linux.
   * `shift` - Shift on macOS, Windows, and Linux.
   * `alt` - Option on macOS, Alt on Windows, and Linux.
   */
  commands: string
}

function getCommand(command: string) {
  const isWindows = navigator.platform.includes('Win')
  return command.split('+').map((key) => {
    switch (key) {
      case 'meta':
        return isWindows ? 'Ctrl' : '⌘'
      case 'ctrl':
        return isWindows ? 'Ctrl' : '⌃'
      case 'shift':
        return isWindows ? 'Shift' : '⇧'
      case 'alt':
        return isWindows ? 'Alt' : '⌥'
      case 'enter':
        return '↵'
      case 'backspace':
        return '⌫'
      default:
        return key
    }
  })
}

const Kbd = forwardRef<HTMLDivElement, KbdProps>(({ className, commands, ...props }, ref) => {
  const keys = getCommand(commands)
  return (
    <kbd ref={ref} className={cn('inline-flex items-center select-none', className)} {...props}>
      {keys.map((command, index) => (
        <Fragment key={index}>
          <kbd className="inline-flex cursor-default rounded border border-zinc-300 bg-white px-1 py-0.5 text-xs leading-none text-black shadow-[0_2px_0_0_#e4e4e7] transition-all duration-75 active:translate-y-0.5 active:shadow-[0_0_0_0_#e4e4e7]">
            {command}
          </kbd>
          {index < keys.length - 1 && <span className="mx-0.5 text-gray-400">+</span>}
        </Fragment>
      ))}
    </kbd>
  )
})
Kbd.displayName = 'Kbd'

export { Kbd }
