import { cn } from '@/lib/utils'
import { ELEMENT_IDS, LOCALSTORAGE_KEYS } from '@/utils/consts'
import { motion } from 'framer-motion'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { useLocalStorage } from 'react-use'

export const NestedPanel: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...props
}) => {
  const [_width] = useLocalStorage(LOCALSTORAGE_KEYS.RIGHT_DRAWER_WIDTH, 320)
  const width = Math.max(_width ?? 320, 320)

  return createPortal(
    <motion.div
      className="absolute inset-y-outer-padding right-outer-padding z-canvas rounded bg-background/85 p-4 backdrop-blur-lg"
      style={{
        width,
      }}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{
        type: 'spring',
        stiffness: 350,
        damping: 35,
      }}
    >
      <div className={cn('flex-1 h-full', className)} {...props}>
        {children}
      </div>
    </motion.div>,
    document.getElementById(ELEMENT_IDS.CANVAS_ROOT)!
  )
}
