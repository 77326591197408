import { HelpIcon } from '@/components/HelpIcon'
import { BufferedInput } from '@/components/ui/buffered-input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { MAX_SD_SEED, clamp, coerceToNumber, randomInt } from '@/lib/numbers'

import { GenerationStateForm } from '@/states/Generation'
import { AnimatePresence, motion } from 'framer-motion'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const GenBoxPanelSeed = () => {
  const { t } = useTranslation()

  const { control, watch, setValue } = useFormContext<GenerationStateForm>()
  const randomMode = watch('seed') === undefined

  return (
    <>
      <div className="flex w-full items-center gap-2">
        <Label htmlFor="random-mode" className="flex items-center gap-2">
          <span>{t('gen.seed.random-mode.label')}</span>
          <HelpIcon content={t('gen.seed.random-mode.help')} />
        </Label>
        <div className="flex-1" />
        <Switch
          id="random-mode"
          name="randomMode"
          checked={randomMode}
          onCheckedChange={(v) => {
            setValue('seed', v ? undefined : randomInt(1, MAX_SD_SEED))
          }}
        />
      </div>

      <AnimatePresence mode="popLayout">
        {!randomMode && (
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FormField
              control={control}
              name="seed"
              render={({ field: { value, onChange, ...field } }) => (
                <FormItem className="my-1">
                  <FormLabel>{t('gen.seed.label')}</FormLabel>
                  <FormControl>
                    <BufferedInput<number>
                      ref={field.ref}
                      value={value!}
                      transformFromT={(v) => v.toFixed(0)}
                      transformToT={(v) => clamp(coerceToNumber(v), 1, MAX_SD_SEED)}
                      onValueChange={onChange}
                      onBlur={field.onBlur}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
