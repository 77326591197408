import { BufferedInput, BufferedInputProps } from '@/components/ui/buffered-input'
import { Label } from '@/components/ui/label'
import { ReactNode } from 'react'

export const PropertyInput = <T,>({
  label,
  ...props
}: BufferedInputProps<T> & {
  label: ReactNode
}) => {
  return (
    <div className="flex items-center gap-x-2 gap-y-1">
      <Label className="flex w-6 justify-center text-sm font-bold text-gray-500">{label}</Label>
      <BufferedInput selectAllOnClick valueCommitStrategy={['onBlur', 'onEnter']} {...props} />
    </div>
  )
}
