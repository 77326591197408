// throw an error if the environment variable is not set
const getEnv = (key: string): string => {
  const value = import.meta.env[key]
  if (value === undefined) {
    throw new Error(`expected env var ${key} to be set`)
  }
  return value
}

export const CONFIG = {
  RECAPTCHA_SITE_KEY: getEnv('VITE_RECAPTCHA_SITE_KEY'),
  DEFAULT_FONT_SIZE: 14,
  SUPPORTED_LOCALES: [
    {
      locale: 'en-US',
      tldrawLocale: 'en',
    },
    {
      locale: 'ja-JP',
      tldrawLocale: 'ja',
    },
    {
      locale: 'ko-KR',
      tldrawLocale: 'ko-kr',
    },
  ] as const,
}
