import { LoginDialogInner } from '@/components/settings/LoginDialogInner'
import { CONFIG } from '@/utils/config'
import { FC } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const LoginDialog: FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={CONFIG.RECAPTCHA_SITE_KEY}>
      <LoginDialogInner />
    </GoogleReCaptchaProvider>
  )
}
