import { ReactElement, cloneElement } from 'react'

export const joinReactElementArray = <T extends ReactElement>(
  arr: T[],
  separator: T
): ReactElement[] => {
  // Check if the array is empty, return an empty array immediately.
  if (arr.length === 0) return []

  return arr.reduce<ReactElement[]>((acc, elem, index) => {
    // Add the current element.
    acc.push(elem)

    // Add the separator after each element except the last one.
    if (index < arr.length - 1) {
      acc.push(cloneElement(separator, { key: `separator-${index}` }))
    }

    return acc
  }, [])
}
