import { Editor, EditorContent, useEditor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Bold from '@tiptap/extension-bold'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import FontSize from 'tiptap-extension-font-size'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Color } from '@tiptap/extension-color'
import FontFamily from '@tiptap/extension-font-family'
import { PSRichDialogueShape, PSRichTextShape, STYLES } from '@troph-team/tldraw'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Furigana } from './FuriganaExtension'
import FontBoldIcon from '~icons/radix-icons/font-bold'
import FontItalicIcon from '~icons/radix-icons/font-italic'
import FontUnderlineIcon from '~icons/radix-icons/underline'
import FontStrikeIcon from '~icons/radix-icons/strikethrough'
import FontFamilyIcon from '~icons/radix-icons/font-family'
import FontSizeIcon from '~icons/radix-icons/font-size'
import ColorIcon from '~icons/radix-icons/shadow'
import { HexColorPicker } from 'react-colorful'
import { AArrowUp } from 'lucide-react'

const preferredFontOrder = ['komika', 'serif', 'draw', 'sans', 'mono']

export const Tiptap = ({
  onUpdate,
  defaultValue,
}: {
  shape: PSRichDialogueShape | PSRichTextShape
  onUpdate: (props: { w?: number; h?: number; data: string }) => void
  defaultValue: string
}) => {
  const [furiganaInput, setFuriganaInput] = useState('')

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      TextStyle,
      Bold,
      Italic,
      Strike,
      Underline,
      Color,
      FontFamily,
      FontSize,
      Furigana,
    ],
    content: defaultValue.length > 0 ? JSON.parse(defaultValue) : {},
  })

  const onEditorUpdate = (data: { editor: Editor }) => {
    onUpdate({
      data: JSON.stringify(data.editor.getJSON()),
    })
  }
  useEffect(() => {
    editor?.on('update', onEditorUpdate)
  }, [])

  const fonts = [...STYLES.font]
  fonts.sort((a, b) => {
    return preferredFontOrder.indexOf(a.value) - preferredFontOrder.indexOf(b.value)
  })
  if (!editor) {
    return null
  }

  return (
    <div className="bg-white">
      {editor && (
        <div className="w-full">
          <div className="flex w-full flex-row gap-2 p-2">
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={clsx(
                editor.isActive('bold') ? 'bg-black text-white' : 'bg-white text-black',
                'rounded-full px-2 py-1 text-xs'
              )}
            >
              <FontBoldIcon />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={clsx(
                editor.isActive('italic') ? 'bg-black text-white' : 'bg-white text-black',
                'rounded-full px-2 py-1 text-xs'
              )}
            >
              <FontItalicIcon />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={clsx(
                editor.isActive('strike') ? 'bg-black text-white' : 'bg-white text-black',
                'rounded-full px-2 py-1 text-xs'
              )}
            >
              <FontStrikeIcon />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={clsx(
                editor.isActive('underline') ? 'bg-black text-white' : 'bg-white text-black',
                'rounded-full px-2 py-1 text-xs'
              )}
            >
              <FontUnderlineIcon />
            </button>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <button className="rounded-full bg-white px-2 py-1 text-xs text-black">
                  <FontFamilyIcon />
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {fonts.map((font) => (
                  <button
                    key={font.value}
                    onClick={() =>
                      editor
                        .chain()
                        .focus()
                        .setFontFamily('tldraw_' + font.value)
                        .run()
                    }
                    className={clsx(
                      editor.isActive('textStyle', {
                        attr: { fontFamily: 'tldraw_' + font.value },
                      })
                        ? 'bg-black text-white'
                        : 'bg-white text-black',
                      'rounded-full px-2 py-1 text-xs'
                    )}
                  >
                    {font.value}
                  </button>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <button className="rounded-full bg-white px-2 py-1 text-xs text-black">
                  <FontSizeIcon />
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {['5pt', '8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '22pt'].map(
                  (size) => (
                    <DropdownMenuItem
                      key={size}
                      onClick={() => editor.chain().focus().setFontSize(size).run()}
                      className={clsx(
                        editor.isActive('textStyle', {
                          attr: { fontSize: size },
                        })
                          ? 'bg-black text-white'
                          : 'bg-white text-black',
                        'rounded-full px-2 py-1 text-xs'
                      )}
                      style={{ fontSize: size }}
                    >
                      {size}
                    </DropdownMenuItem>
                  )
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <button className="rounded-full bg-white px-2 py-1 text-xs text-black">
                  <ColorIcon />
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <HexColorPicker
                  color={
                    editor.getAttributes('textStyle')?.attr?.color
                      ? editor.getAttributes('textStyle').attr.color
                      : '#000000'
                  }
                  onChange={(color) => editor.chain().focus().setColor(color).run()}
                />
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu>
              <DropdownMenuTrigger>
                <button className="rounded-full bg-white px-2 py-1 text-xs text-black">
                  <AArrowUp />
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <div className="flex flex-row gap-2 p-2">
                  <input
                    value={furiganaInput}
                    onChange={(e) => setFuriganaInput(e.target.value)}
                    className="w-20 rounded-full border border-gray-300 px-2 py-1 text-xs"
                  />
                  <button
                    onClick={() =>
                      editor.chain().focus().toggleFurigana({ furigana: furiganaInput }).run()
                    }
                    className={clsx('bg-black', 'rounded-full px-2 py-1 text-xs text-white')}
                  >
                    <ruby>
                      振<rp>(</rp>
                      <rt>ふ</rt>
                      <rp>)</rp>
                    </ruby>
                    り
                    <ruby>
                      仮<rp>(</rp>
                      <rt>が</rt>
                      <rp>)</rp>名<rp>(</rp>
                      <rt>な</rt>
                      <rp>)</rp>
                    </ruby>
                  </button>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}
      <EditorContent editor={editor} className="p-4" />
    </div>
  )
}
