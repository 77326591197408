import { useEditor } from '@/states/EditorProvider'
import { GenerationStateForm, generationStateSchema } from '@/states/Generation'
import { LOCALSTORAGE_KEYS, MODEL_VERSION_IDS } from '@/utils/consts'
import { useGenBoxOnPage } from '@/utils/shapes/genbox'
import { FC, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'sonner'

const useGenBoxSizeListener = () => {
  const editor = useEditor()
  const { genbox } = useGenBoxOnPage()
  const { control, setValue } = useFormContext<GenerationStateForm>()
  const watched = useWatch<GenerationStateForm>({
    name: ['width', 'height'],
    control,
  }) as number[]

  useEffect(() => {
    if (!genbox) return
    editor.updateShapeById(genbox.id, {
      props: {
        w: watched[0],
        h: watched[1],
      },
    })
  }, [watched])

  useEffect(() => {
    if (!genbox) return
    setValue('width', genbox.props.w)
    setValue('height', genbox.props.h)
  }, [genbox?.props.w, genbox?.props.h])
}

const useGenBoxFormAutosave = () => {
  const form = useFormContext<GenerationStateForm>()

  useEffect(() => {
    const subscription = form.watch((values) => {
      const { success, data } = generationStateSchema.safeParse(values)
      if (!success) return
      localStorage.setItem(LOCALSTORAGE_KEYS.GENBOX_FORM, JSON.stringify(data))
    })
    return () => subscription.unsubscribe()
  }, [form])
}

const useGenBoxFormStyleMixLimiter = () => {
  const { watch, setValue } = useFormContext<GenerationStateForm>()

  const isY10Model = watch('modelId') === MODEL_VERSION_IDS.Y10
  const styleMixEnabled = watch('styleMix.enabled')
  const shouldDisableStyleMix = !isY10Model && styleMixEnabled

  useEffect(() => {
    if (shouldDisableStyleMix) {
      toast.warning('StyleMix is only available for the `y10` model, and thus has been disabled.')
      setValue('styleMix.enabled', false)
    }
  }, [shouldDisableStyleMix])
}

export const GenFormController: FC = () => {
  useGenBoxSizeListener()
  useGenBoxFormAutosave()
  useGenBoxFormStyleMixLimiter()

  return null
}
