import { Input, InputProps } from '@/components/ui/input'
import { closeTo } from '@/lib/numbers'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'

type ValueCommitStrategy = 'onBlur' | 'onEnter'

export type BufferedInputProps<T> = Omit<InputProps, 'defaultValue' | 'value'> & {
  value: T
  transformFromT: (value: T) => string
  transformToT: (value: string) => T
  renderValue?: (value: T) => string
  onValueChange?: (value: T) => void
  valueCommitStrategy?: ValueCommitStrategy[]
}

const parseValueCommitStrategy = (
  valueCommitStrategy: ValueCommitStrategy[]
): Record<ValueCommitStrategy, boolean> => {
  return valueCommitStrategy.reduce((acc, strategy) => {
    acc[strategy] = true
    return acc
  }, {} as Record<ValueCommitStrategy, boolean>)
}

const _BufferedInput = <T = string,>(
  {
    value,
    transformFromT,
    transformToT,
    renderValue,
    onValueChange,
    valueCommitStrategy = ['onEnter', 'onBlur'],
    ...props
  }: BufferedInputProps<T>,
  ref: React.Ref<HTMLInputElement>
) => {
  const [focused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState(transformFromT(value))
  const lastMouseDownPosition = useRef<{ x: number; y: number } | null>(null)

  const strategy = useMemo(
    () => parseValueCommitStrategy(valueCommitStrategy),
    [valueCommitStrategy]
  )

  useEffect(() => {
    setInputValue(transformFromT(value))
  }, [value, transformFromT])

  const displayValue = renderValue ? renderValue(value) : inputValue

  return (
    <Input
      {...props}
      ref={ref}
      value={focused ? inputValue : displayValue}
      onPointerDown={(e) => {
        lastMouseDownPosition.current = { x: e.clientX, y: e.clientY }
        props.onPointerDown?.(e)
      }}
      onPointerUp={(e) => {
        if (
          lastMouseDownPosition.current &&
          closeTo(lastMouseDownPosition.current.x, e.clientX, 5) &&
          closeTo(lastMouseDownPosition.current.y, e.clientY, 5) &&
          props.selectAllOnClick
        ) {
          e.currentTarget.select()
        }
        lastMouseDownPosition.current = null
        props.onPointerUp?.(e)
      }}
      onChange={(e) => {
        setInputValue(e.target.value)
      }}
      onFocus={(e) => {
        setFocused(true)
        props.onFocus?.(e)
      }}
      onBlur={(e) => {
        setFocused(false)
        if (onValueChange && transformToT && strategy.onBlur) {
          onValueChange(transformToT(inputValue))
        }
        props.onBlur?.(e)
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && onValueChange && transformToT && strategy.onEnter) {
          onValueChange(transformToT(inputValue))
          e.currentTarget.blur()
        }
        props.onKeyDown?.(e)
      }}
    />
  )
}

// https://stackoverflow.com/a/58473012/8829241
export const BufferedInput = forwardRef(_BufferedInput) as <T>(
  p: BufferedInputProps<T> & { ref?: React.Ref<HTMLInputElement> }
) => React.ReactElement
