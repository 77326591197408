import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { useAuthDialog } from '@/states/AuthDialogProvider'
import { useGenerationService } from '@/states/Generation'
import {
  PSGenMediaShape,
  PSMarkerGeoShape,
  PSMarkerLassoShape,
  PSMarkerShape,
  stopEventPropagation,
  TLImageShape,
  track,
  useEditor,
} from '@troph-team/tldraw'
import { useState } from 'react'
import RadixIconsTransform from '~icons/radix-icons/transform'

import { Kbd } from '@/components/ui/kbd'
import { useTranslation } from 'react-i18next'
export const InFrontOfTheCanvasInpaintToolbar = track(() => {
  const { t } = useTranslation(['auth'])

  const [submitting, setSubmitting] = useState(false)
  const [prompts, setPrompts] = useState('')
  const editor = useEditor()
  const generation = useGenerationService()
  const { ensureAuthenticated } = useAuthDialog()

  const config = (() => {
    const selected = editor.getSelectedShapes()
    const markerShapes = selected.filter(
      (shape) =>
        shape.type === 'marker' || shape.type === 'marker-lasso' || shape.type === 'marker-geo'
    ) as (PSMarkerShape | PSMarkerLassoShape | PSMarkerGeoShape)[]
    if (!markerShapes.length) return null
    const baseShape = editor.getShape(markerShapes[0].parentId) as
      | PSGenMediaShape
      | TLImageShape
      | undefined
    if (!baseShape) return null

    return {
      baseShape,
      maskShapes: markerShapes,
    }
  })()

  if (!config) return null

  const { baseShape, maskShapes } = config
  const submit = async () => {
    if (submitting) return
    setSubmitting(true)
    await ensureAuthenticated({
      subtitle: t('auth:reason.generation'),
    })
    generation.submitInpaintTask(baseShape, maskShapes, {
      prompts,
    })
  }

  return (
    <div className="flex min-w-96 items-center justify-center gap-1 rounded bg-muted/80 py-1 pl-2 pr-1 shadow">
      <div className="flex items-center gap-1 [writing-mode:vertical-lr]">
        <RadixIconsTransform className="size-4 shrink-0" />
        <div className="shrink-1 text-xs">{t('inpaint-dialog.title')}</div>
      </div>
      <div className="flex-1" />
      <Textarea
        className="flex min-h-[4.375rem] items-center justify-center self-stretch rounded-sm bg-black bg-opacity-10 text-xs"
        onPointerDown={stopEventPropagation}
        placeholder={t('inpaint-dialog.prompt')}
        value={prompts}
        disabled={submitting}
        onChange={(e) => setPrompts(e.target.value)}
        onKeyDown={(e) => {
          // only if IME is not active
          if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
            submit()
          }
        }}
      />
      <div className="flex flex-col gap-0.5 self-center">
        <Button
          className="flex h-full items-center gap-1"
          disabled={!prompts || submitting}
          onClick={(e) => {
            e.stopPropagation()
            submit()
          }}
        >
          <span>{t('inpaint-dialog.action')}</span>
          <Kbd commands="enter" />
        </Button>
        <Button
          className="flex h-full items-center gap-1 text-xs"
          variant="secondary"
          disabled={submitting}
          onClick={() => editor.selectNone()}
        >
          <span>{t('action.cancel')}</span>
          <Kbd commands="esc" />
        </Button>
      </div>
    </div>
  )
})
