import { useEditor } from '@/states/EditorProvider'
import { useGenBoxOnPage } from '@/utils/shapes/genbox'
import { createShapeId, EASINGS, PSPoseShape, PSPoseShapeProps, useValue } from '@troph-team/tldraw'

export const useGenBoxPoses = () => {
  const editor = useEditor()
  const genbox = useGenBoxOnPage()

  const poseShapes = useValue(
    'genbox poses',
    () => {
      if (!genbox.genbox || !editor) return []

      const poseShapes = editor.getCurrentPageShapes().filter((shape) => {
        return shape.type === 'pose'
      }) as PSPoseShape[]

      const genboxGeometryBounds = editor.getShapePageBounds(genbox.genbox)
      if (!genboxGeometryBounds) return []

      const collidedShapes = poseShapes.filter((shape) =>
        editor.getShapePageBounds(shape)?.collides(genboxGeometryBounds)
      )

      return collidedShapes
    },
    [editor, genbox.genbox]
  )

  return {
    poseShapes,
    addPoseShape: (template?: Partial<PSPoseShapeProps>) => {
      if (!genbox.genbox) return

      const shapeId = createShapeId()
      editor
        .createShape<PSPoseShape>({
          props: template,
          id: shapeId,
          type: 'pose',
          x: genbox.genbox.x + genbox.genbox.props.w / 2,
          y: genbox.genbox.y + 100,
        })
        .select(shapeId)
        .zoomToSelection({
          duration: 200,
          easing: EASINGS.easeInOutQuint,
        })
    },
  }
}
