import { client } from '@/services/client'
import { ApolloProvider } from '@apollo/client'
import { FC, PropsWithChildren, useState } from 'react'
import { useEffectOnce } from 'react-use'

export const CacheRehydrationGuard: FC<PropsWithChildren<object>> = ({ children }) => {
  const [rehydrated, setRehydrated] = useState(false)

  useEffectOnce(() => {
    if (import.meta.hot) {
      // because we are using persistCache in apollo3-cache-persist which
      // applies side effects onto the apollo client cache, we need to
      // rehydrate the cache absolutely only once. This serves as a guard
      // to prevent rehydrating the cache multiple times, only on HMR reloads.
      //
      // ! Notice that this would cause any changes to this particular file
      // ! to not be reacted by HMR AT ALL. Refresh the page manually if you
      // ! need to see the changes of this file.
      const mark = 'pixstudio:CacheRehydrationGuard:rehydrated'
      if (import.meta.hot.data[mark] === true) {
        setRehydrated(true)
        return
      }
      import.meta.hot.data[mark] = true
    }

    try {
      // persistCache({
      //   cache: clientCache,
      //   storage: new ApolloCacheIndexedDBWrapper(1),
      // });
    } finally {
      setRehydrated(true)
    }
  })

  return rehydrated ? <ApolloProvider client={client}>{children}</ApolloProvider> : null
}
