import { InputSlider } from '@/components/ui/input-slider'
import { Label } from '@/components/ui/label'
import { RadioCard } from '@/components/ui/radio-card'
import { PSDialogueShape, STYLES, TLGeoShape, TLTextShape, useEditor } from '@troph-team/tldraw'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorPickerWithEyedrop } from './ColorPickerWithEyedrop'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion'

const preferredFontOrder = ['komika', 'serif', 'draw', 'sans', 'mono']

export const FontStylesPanel: FC<{
  shape: TLGeoShape | TLTextShape | PSDialogueShape
}> = ({ shape }) => {
  const { t } = useTranslation()

  const editor = useEditor()

  const fonts = [...STYLES.font]
  fonts.sort((a, b) => {
    return preferredFontOrder.indexOf(a.value) - preferredFontOrder.indexOf(b.value)
  })

  return (
    <>
      <Accordion type="single" collapsible className="w-full border-b-2 px-4" defaultValue="fonts">
        <AccordionItem value="fonts">
          <AccordionTrigger className="font-bold">{t('style-panel.text.title')}</AccordionTrigger>
          <AccordionContent className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-between">
                <Label>{t('style-panel.color.text')}</Label>
                <ColorPickerWithEyedrop
                  value={shape.props.textColor}
                  onChange={(textColor) =>
                    editor.updateShape({ id: shape.id, type: shape.type, props: { textColor } })
                  }
                />
              </div>
              <div className="flex items-center justify-between">
                <Label>{t('style-panel.color.text-stroke')}</Label>
                <ColorPickerWithEyedrop
                  value={shape.props.textStrokeColor}
                  onChange={(textStrokeColor) =>
                    editor.updateShape({
                      id: shape.id,
                      type: shape.type,
                      props: { textStrokeColor },
                    })
                  }
                />
              </div>
              <div className="flex w-full flex-row items-center gap-2">
                <Label>{t('style-panel.font-size')}</Label>

                <div className="flex-1">
                  <InputSlider
                    value={shape.props.fontSize}
                    onValueChange={(value) => {
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { fontSize: value },
                      })
                    }}
                    min={1}
                    max={50}
                    step={0.5}
                  />
                </div>
              </div>
              <Label className="mt-2">{t('style-panel.font')}</Label>
              <div className="mt-1 grid grid-cols-5 gap-1">
                {fonts.map(({ value }) => (
                  <RadioCard
                    className={'col-span-1'}
                    key={value}
                    icon={
                      <span className="text-xl font-bold leading-none opacity-90" data-font={value}>
                        Aa
                      </span>
                    }
                    title={t('font-style.' + value)}
                    // subtitle={
                    //   {
                    //     komika: 'Best for English',
                    //     serif: 'Best for Japanese',
                    //   }[value as string]
                    // }
                    selected={shape.props.font === value}
                    onClick={() =>
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { font: value },
                      })
                    }
                  />
                ))}
              </div>
              <Label className="mt-2">{t('style-panel.writing-mode')}</Label>
              {shape.type === 'dialogue' && (
                <div className="mt-1 grid grid-cols-6 gap-1">
                  <RadioCard
                    className="col-span-2"
                    icon={<span className="text-xl font-bold leading-none opacity-90">Aa</span>}
                    title={t('font-layout.horizontal')}
                    selected={shape.props.writingMode === 'horizontal-tb'}
                    onClick={() =>
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { writingMode: 'horizontal-tb' },
                      })
                    }
                  />
                  <RadioCard
                    className="col-span-2"
                    icon={
                      <span
                        className="text-xl font-bold leading-none opacity-90"
                        style={{ writingMode: 'vertical-lr' }}
                      >
                        Aa
                        <br />
                        Bb
                      </span>
                    }
                    title={t('font-layout.vertical-lr')}
                    selected={shape.props.writingMode === 'vertical-lr'}
                    onClick={() =>
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { writingMode: 'vertical-lr' },
                      })
                    }
                  />
                  <RadioCard
                    className="col-span-2"
                    icon={
                      <span
                        className="text-xl font-bold leading-none opacity-90"
                        style={{ writingMode: 'vertical-rl' }}
                      >
                        Aa
                        <br />
                        Bb
                      </span>
                    }
                    title={t('font-layout.vertical-rl')}
                    selected={shape.props.writingMode === 'vertical-rl'}
                    onClick={() =>
                      editor.updateShape({
                        id: shape.id,
                        type: shape.type,
                        props: { writingMode: 'vertical-rl' },
                      })
                    }
                  />
                </div>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  )
}
