import { ShapeName } from '@/components/canvas/layer/LayerListItem'
import { Button } from '@/components/ui/button'
import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { joinReactElementArray } from '@/utils/react'
import { PopoverPortal } from '@radix-ui/react-popover'
import { EASINGS, GenMediaShapeUtil, TLDefaultShape, track, useEditor } from '@troph-team/tldraw'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import RadixIconsArrowRight from '~icons/radix-icons/arrow-right'
import RadixIconsCheck from '~icons/radix-icons/check'
import RadixIconsCounterClockwiseClock from '~icons/radix-icons/counter-clockwise-clock'

import { ELEMENT_ID_SETS } from '@/utils/consts'
import { useSelectedShapeActions } from '@/utils/useSelectedShapeActions'
import LineMdLoadingTwotoneLoop from '~icons/line-md/loading-twotone-loop'

type Action = {
  label: string
  disabled?: boolean
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  onClick: () => Promise<unknown> | unknown
}

export const InFrontOfTheCanvasToolbar = track(() => {
  const { t } = useTranslation()

  const editor = useEditor()
  const shapes = editor.getSelectedShapes()
  const actions = useSelectedShapeActions()
  const firstShape = shapes?.[0] as TLDefaultShape | undefined

  if (!shapes.length || !firstShape) return null

  const parentChain = (() => {
    if (firstShape.type !== 'genmedia') return null

    const shapeUtil = editor.getShapeUtil(firstShape) as GenMediaShapeUtil
    const chain = [firstShape, ...shapeUtil.getDerivationParentChain(firstShape)]
    if (chain.length === 1) return [] // ignore if there's only self
    chain.reverse()
    return chain
  })()

  return (
    <div className="flex flex-col items-end gap-1">
      <div
        className={clsx(
          'mb-2 flex h-9 min-w-60 max-w-2xl items-center justify-center gap-0.5 rounded bg-background p-0.5 shadow-lg',
          parentChain === null && 'pl-2'
        )}
      >
        {parentChain !== null && (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                className="mr-1 size-8 p-2 transition hover:bg-zinc-200 active:bg-zinc-300"
                variant="secondary"
                disabled={parentChain.length === 0}
                disabledReason={t('action.history.disabled-no-parent')}
                tooltip={t('action.history.label', {
                  count: parentChain.length,
                })}
              >
                <RadixIconsCounterClockwiseClock className="size-4" />
              </Button>
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent
                side="left"
                sideOffset={2}
                avoidCollisions
                collisionBoundary={ELEMENT_ID_SETS.CANVAS_ACTIONS.map((e) =>
                  document.getElementById(e)
                )}
                className="flex w-auto items-center justify-center gap-1 bg-background/85 p-1 backdrop-blur-lg"
              >
                <PopoverArrow />
                {joinReactElementArray(
                  parentChain.flatMap((shape) => {
                    if (!shape.props.currentAssetId) return []
                    const asset = editor.getAsset(shape.props.currentAssetId)
                    if (!asset?.props.src) return []

                    return (
                      <button
                        key={shape.id}
                        onClick={() => {
                          editor.select(shape.id)
                          editor.zoomToSelection({
                            duration: 200,
                            easing: EASINGS.easeInOutQuint,
                          })
                        }}
                        className={clsx(
                          'relative select-none overflow-hidden',
                          shape.id !== firstShape.id && 'transition-opacity hover:opacity-80'
                        )}
                        disabled={shape.id === firstShape.id}
                      >
                        <img
                          src={asset.props.src}
                          className={clsx(
                            'max-h-48 max-w-48 shrink-0 grow object-cover',
                            shape.props.pending && 'blur-lg'
                          )}
                          alt=""
                        />
                        {shape.id === firstShape.id && (
                          <div className="absolute inset-0 flex items-end justify-end border-4 border-primary bg-primary/20">
                            <RadixIconsCheck className="m-1 size-8 rounded-full bg-primary p-1 text-white" />
                          </div>
                        )}
                        {shape.props.pending && (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <LineMdLoadingTwotoneLoop className="size-8 text-white" />
                          </div>
                        )}
                      </button>
                    )
                  }),
                  <RadixIconsArrowRight className="size-6" />
                )}
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        )}
        <div className="min-w-48 max-w-60">
          <ShapeName shape={firstShape} contentClassName="line-clamp-1" />
        </div>

        <div className="flex-1" />

        {actions.map((action) => (
          <InFrontOfTheCanvasToolbarActionItem key={action.label} {...action} />
        ))}
      </div>
    </div>
  )
})

const InFrontOfTheCanvasToolbarActionItem = (action: Action) => {
  const editor = useEditor()
  return (
    <Button
      key={action.label}
      className="size-8 p-2 transition hover:bg-zinc-200 active:bg-zinc-300"
      onClick={async () => {
        await action.onClick()
        editor.getContainer().focus()
      }}
      variant="secondary"
      tooltip={action.label}
      disabled={action.disabled}
    >
      <action.Icon className="size-4" />
    </Button>
  )
}
