import { gql } from '@/services/__generated__'

export const MUTATION_UPLOAD_MEDIA = gql(`
  mutation uploadMedia($input: UploadMediaInput!) {
    uploadMedia(input: $input) {
      uploadUrl
      externalId
      mediaId
      media {
        ...MediaBase
      }
    }
  }
`)

export const QUERY_GET_MEDIA = gql(`
  query getMedia($id: String!) {
    media(id: $id) {
      ...MediaBase
    }
  }
`)
