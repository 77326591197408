import { Button } from '@/components/ui/button'
import { FormLabel } from '@/components/ui/form'
import { GenerationStateForm, generationStateSchema } from '@/states/Generation'
import { Download, Upload } from 'lucide-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { parse, stringify } from 'yaml'

export const GenBoxPanelExportImport = () => {
  const { t } = useTranslation()

  const { getValues, setValue } = useFormContext<GenerationStateForm>()

  return (
    <div className="flex w-full flex-col gap-4 border-t pt-4">
      <div className="flex w-full items-center gap-4">
        <FormLabel>{t('gen.export-setting')}</FormLabel>
        <Button
          variant="outline"
          onClick={() => {
            const values = getValues()
            const blob = new Blob([stringify(values)], {
              type: 'application/json',
            })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'PixStudio-generation-config.yaml'
            a.click()
            URL.revokeObjectURL(url)
          }}
          className="flex-1"
        >
          <Download size={16} />
        </Button>
      </div>
      <div className="flex w-full items-center gap-4">
        <FormLabel>{t('gen.import-setting')}</FormLabel>

        <Button
          variant="outline"
          onClick={() => {
            const input = document.createElement('input')
            input.type = 'file'
            input.accept = '.yaml'
            input.onchange = (e) => {
              const file = (e.target as HTMLInputElement).files?.[0]
              if (!file) return
              const reader = new FileReader()
              reader.onload = (e) => {
                try {
                  const data = parse(e.target?.result as string)
                  const { success, error } = generationStateSchema.safeParse(data)
                  if (!success) {
                    toast.error(
                      t('gen.import-setting-error') +
                        ' ' +
                        (error && error.issues.map((issue) => issue.message).join(', '))
                    )
                    return
                  }
                  Object.entries(data).forEach(([key, value]) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    setValue(key as keyof GenerationStateForm, value as any)
                  })
                  toast.success(t('gen.import-setting-success'))
                } catch (e) {
                  // console.error(e);
                  toast.error(t('gen.import-setting-error'))
                }
              }
              reader.readAsText(file)
            }
            input.click()
          }}
          className="flex-1"
        >
          <Upload size={16} />
        </Button>
      </div>
    </div>
  )
}
