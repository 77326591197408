import * as React from 'react'
import { useEffect, useRef } from 'react'

export default function AngleTurntable({
  angle,
  setAngle,
}: {
  angle: number
  setAngle: (f: (prev: number) => number) => void
}) {
  const dialRef = useRef<HTMLDivElement>(null)
  const isDragging = useRef(false)

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    isDragging.current = true
    updateAngle(event.clientX, event.clientY)
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (isDragging.current) {
      updateAngle(event.clientX, event.clientY)
    }
  }

  const handleMouseUp = () => {
    isDragging.current = false
  }

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    isDragging.current = true
    updateAngle(event.touches[0].clientX, event.touches[0].clientY)
  }

  const handleTouchMove = (event: TouchEvent) => {
    if (isDragging.current) {
      updateAngle(event.touches[0].clientX, event.touches[0].clientY)
    }
  }

  const handleTouchEnd = () => {
    isDragging.current = false
  }

  const updateAngle = (clientX: number, clientY: number) => {
    if (dialRef.current) {
      const rect = dialRef.current.getBoundingClientRect()
      const centerX = rect.left + rect.width / 2
      const centerY = rect.top + rect.height / 2
      let angle = Math.atan2(clientY - centerY, clientX - centerX) * (180 / Math.PI)
      angle = (angle + 360) % 360
      setAngle(() => Math.round(angle))
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowRight') {
      setAngle((prev) => (prev + 1) % 360)
    } else if (event.key === 'ArrowLeft') {
      setAngle((prev) => (prev - 1 + 360) % 360)
    }
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])

  return (
    <div className="mx-auto w-full max-w-sm p-6">
      <div className="flex flex-col items-center space-y-4">
        <div
          ref={dialRef}
          className="relative h-32 w-32 rounded-full border-4 border-gray-200 dark:border-gray-700"
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          role="slider"
          aria-valuemin={0}
          aria-valuemax={360}
          aria-valuenow={angle}
          aria-label="Angle selector"
        >
          <div
            className="absolute left-1/2 top-1/2 h-0.5 w-full origin-center bg-primary"
            style={{ transform: `translate(-50%, -50%) rotate(${angle}deg)` }}
          >
            <div className="absolute right-0 size-4 -translate-y-1/2 translate-x-1/2 rounded-full bg-primary" />
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="rounded-lg bg-white px-3 py-2">{angle}°</div>
          </div>
        </div>
        {/* <div className="w-full max-w-[8rem]">
          <Label htmlFor="angle-input" className="text-sm font-medium">
            Angle
          </Label>
          <Input
            id="angle-input"
            type="number"
            value={angle}
            onChange={(e) =>
              setAngle(() => Math.max(0, Math.min(360, parseInt(e.target.value) || 0)))
            }
            className="mt-1"
            min={0}
            max={360}
          />
        </div> */}
      </div>
    </div>
  )
}
