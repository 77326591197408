import { HelpIcon } from '@/components/HelpIcon'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea'
import { GenerationStateForm } from '@/states/Generation'
import { getCaretCoordinates } from '@/utils/caretPosition'
import { LOCALSTORAGE_KEYS } from '@/utils/consts'
import { useMotionValue } from 'framer-motion'
import {
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  Ref,
  Suspense,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useLocalStorage } from 'react-use'
import PromptsCompletion, {
  PromptsCompletionData,
  PromptsCompletionRef,
} from './tag-complete/PromptsCompletion'

import { useTranslation } from 'react-i18next'
export const GenBoxPanelPrompt = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext<GenerationStateForm>()
  const prompts = watch('prompts')

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  useLayoutEffect(() => {
    const t = textareaRef.current
    if (!t) return
    t.style.height = 'auto'
    t.style.height = t.scrollHeight + 'px'
  }, [prompts])

  const completionRef = useRef<PromptsCompletionRef | null>(null)
  const [completionData, setCompletionData] = useState<PromptsCompletionData>()
  const updateCompletionData = (event: FormEvent) => {
    if (!textareaRef.current) return
    if ('key' in event && event.key === 'Escape') return
    const { value: prompts, selectionStart, selectionEnd } = textareaRef.current

    const bounding = textareaRef.current.getBoundingClientRect()
    const pos = getCaretCoordinates(textareaRef.current, selectionStart)

    const data: PromptsCompletionData = {
      prompts,
      selectionStart,
      selectionEnd,
      x: bounding.left + pos.left,
      y: bounding.top + pos.top + pos.height,
    }
    if (JSON.stringify(data) !== JSON.stringify(completionData)) setCompletionData(data)
  }
  const onBlur = (e: FocusEvent) => {
    completionRef.current?.onInputBlur(e)
  }

  const onTextAreaKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    // Works, but disable for now
    // Pressed Cmd+Enter for Mac, Ctrl+Enter for Others
    // if (e.which === 13 && (isMac ? e.metaKey : e.ctrlKey)) {
    //   e.preventDefault()
    //   submit()
    // }

    if (completionRef.current) {
      if (e.key === 'Tab') {
        // only prevent default if completion is confirmed
        if (completionRef.current.confirm()) e.preventDefault()
      }

      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        // only prevent default if completion is shifted
        if (completionRef.current.shift(e.key === 'ArrowUp' ? -1 : 1)) {
          e.preventDefault()
        }
      }
    }

    if (e.key === 'Escape') {
      setCompletionData(undefined)
    }
  }

  return (
    <FormField
      control={control}
      name="prompts"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t('gen.prompt.label')}</FormLabel>
          <FormControl>
            <div className="flex flex-col">
              <Textarea
                ref={textareaRef}
                placeholder={t('gen.prompt.placeholder')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                onKeyDown={onTextAreaKeyDown}
                onInput={updateCompletionData}
                onKeyUp={updateCompletionData}
                onMouseUp={updateCompletionData}
                onFocus={updateCompletionData}
                onBlur={onBlur}
              />

              <CompletionSwitch
                ref={completionRef}
                completionData={completionData}
                setSelection={(start, end) => {
                  const s = [
                    textareaRef.current?.selectionStart ?? 0,
                    textareaRef.current?.selectionEnd ?? 0,
                  ] as [number, number]
                  if (!textareaRef.current) return s
                  textareaRef.current.focus()
                  textareaRef.current.setSelectionRange(start, end)
                  return s
                }}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

/**
 * Prompts Completion
 */

const CompletionSwitch = forwardRef(function CompletionButton(
  {
    completionData,
    setSelection,
  }: {
    completionData?: PromptsCompletionData
    setSelection: (start: number, end: number) => [number, number]
  },
  ref: Ref<PromptsCompletionRef>
) {
  const { t } = useTranslation()
  const [enableCompletion, setEnableCompletion] = useLocalStorage<boolean>(
    LOCALSTORAGE_KEYS.GENBOX_AUTOCOMPLETE,
    true
  ) // enable autocomplete unless user explicitly disables it

  const completionLoadingProgress = useMotionValue(0)
  const [completionLoadingComplete, setCompletionLoadingComplete] = useState(false)

  return (
    <>
      <div className="-mt-1 flex select-none items-center gap-2 rounded-b border border-t-0 border-solid border-border px-3 pb-1 pt-2">
        <Label className="flex gap-1 text-sm font-normal text-foreground">
          {t('gen.prompt.autocomplete.label')}
          <HelpIcon content={t('gen.prompt.autocomplete.help')} />
        </Label>

        <div className="flex-1" />

        <Switch
          disabled={enableCompletion && !completionLoadingComplete}
          checked={!!enableCompletion}
          onCheckedChange={(checked) => {
            completionLoadingProgress.set(0)
            setEnableCompletion(checked)
          }}
        />
      </div>

      <Suspense>
        {enableCompletion && (
          <PromptsCompletion
            ref={ref}
            data={completionData}
            setSelection={setSelection}
            onLoadingProgress={(p) => {
              completionLoadingProgress.set(p)
              setCompletionLoadingComplete(p >= 1)
            }}
            onWorkerError={() => {
              setEnableCompletion(false)
            }}
          />
        )}
      </Suspense>
    </>
  )
})
