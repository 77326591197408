import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { InputSlider } from '@/components/ui/input-slider'
import { GenerationStateForm } from '@/states/Generation'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const GenBoxPanelCFGScale = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<GenerationStateForm>()

  return (
    <FormField
      control={control}
      name="cfgScale"
      render={({ field: { value, onChange, ...field } }) => (
        <FormItem className="my-1">
          <FormLabel>{t('gen.cfg-scale')}</FormLabel>
          <FormControl>
            <InputSlider
              value={value}
              onValueChange={(v) => onChange(v)}
              min={1}
              max={10}
              step={0.1}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
