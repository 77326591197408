import { InFrontOfTheCanvasWebtoonAddPageSection } from '@/components/canvas/infrontof/bottom/InFrontOfTheCanvasWebtoonAddPageSection'
import { InFrontOfTheCanvasGenMedia } from '@/components/canvas/infrontof/bottombar/InFrontOfTheCanvasGenMedia'
import { InFrontOfTheCanvasInpaintToolbar } from '@/components/canvas/infrontof/bottombar/InFrontOfTheCanvasMarkerShape'
import { InFrontOfTheCanvasFrameShape } from '@/components/canvas/infrontof/bottomright/InFrontOfTheCanvasFrameShape'
import { InFrontOfTheCanvasToolbar } from '@/components/canvas/infrontof/toolbar/InFrontOfTheCanvasToolbar'
import {
  PSGenBoxShape,
  PSGenMediaShape,
  PSMarkerGeoShape,
  PSMarkerLassoShape,
  PSMarkerShape,
  TLDefaultShape,
  TLFrameShape,
  stopEventPropagation,
  track,
  useEditor,
  useValue,
} from '@troph-team/tldraw'
import { motion } from 'framer-motion'
import { MotionButton } from '@/components/ui/motion'
import { useAuthDialog } from '@/states/AuthDialogProvider'
import { GenerationStateForm, useGenerationService } from '@/states/Generation'
import { AnimatePresence } from 'framer-motion'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const InFrontOfTheCanvas = track(() => {
  const editor = useEditor()

  const info = useValue(
    'selection bounds in screen space',
    () => {
      const screenBounds = editor.getViewportScreenBounds()
      const bounds = editor.getSelectionRotatedPageBounds()
      if (!bounds) return null

      const screenBox = editor.pageToScreenBox(bounds)
      return {
        x: screenBox.x - screenBounds.x,
        y: screenBox.y - screenBounds.y,
        width: screenBox.width,
        height: screenBox.height,
      }
    },
    [editor]
  )

  const topPositionContent = (() => {
    const shape = editor.getSelectedShapes()?.[0] as TLDefaultShape

    if (!shape) return null
    if (!info) return null

    const children = (() => {
      return <InFrontOfTheCanvasToolbar />
    })()

    if (!children) return null

    return (
      <motion.div
        key={`top-${shape.id}`}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        style={{
          x: info.x + info.width / 2,
          y: info.y,
          pointerEvents: 'all',
        }}
        className="absolute left-0 top-0 origin-top text-foreground"
        transition={{ type: 'spring', damping: 20, stiffness: 300 }}
        onPointerDown={stopEventPropagation}
      >
        <div className="relative size-px">
          <div className="absolute bottom-0 left-[50%] flex w-full translate-x-[-50%] items-center justify-center">
            {children}
          </div>
        </div>
      </motion.div>
    )
  })()

  const bottomPositionContent = (() => {
    const shapeGroup = (() => {
      const first = editor
        .getSelectedShapes()
        .find(
          (shape) =>
            shape.type === 'marker' ||
            shape.type === 'marker-lasso' ||
            shape.type === 'marker-geo' ||
            shape.type === 'genmedia' ||
            shape.type === 'genbox'
        ) as
        | PSMarkerShape
        | PSMarkerLassoShape
        | PSMarkerGeoShape
        | PSGenMediaShape
        | PSGenBoxShape
        | undefined

      if (first?.type === 'genmedia' || first?.type === 'genbox') {
        return {
          type: first.type,
          shapes: [first],
        }
      } else if (
        first?.type === 'marker' ||
        first?.type === 'marker-lasso' ||
        first?.type === 'marker-geo'
      ) {
        return {
          type: 'inpaint' as const,
          shapes: editor
            .getSelectedShapes()
            .filter(
              (shape) =>
                shape.type === 'marker' ||
                shape.type === 'marker-lasso' ||
                shape.type === 'marker-geo'
            ) as (PSMarkerShape | PSMarkerLassoShape | PSMarkerGeoShape)[],
        }
      }

      return null
    })()

    if (!shapeGroup) return null
    if (!info) return null

    const children = (() => {
      if (shapeGroup.type === 'inpaint') return <InFrontOfTheCanvasInpaintToolbar />
      if (shapeGroup.type === 'genbox') {
        return (
          <div>
            <GenerateButton />
          </div>
        )
      }
      if (
        shapeGroup.type === 'genmedia' &&
        !shapeGroup.shapes?.[0].props.pending &&
        (shapeGroup.shapes?.[0] as PSGenMediaShape).props.assetIds.length > 1
      )
        return <InFrontOfTheCanvasGenMedia />
      return null
    })()

    if (!children) return null

    return (
      <motion.div
        key={`bottom-${shapeGroup.shapes.map((s) => s.id).join('-')}`}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        style={{
          x: info.x,
          y: info.y + info.height + 8,
          width: info.width,
          pointerEvents: 'all',
        }}
        className="absolute left-0 top-0 origin-top text-foreground"
        transition={{ type: 'spring', damping: 20, stiffness: 300 }}
        onPointerDown={stopEventPropagation}
        onPointerUp={stopEventPropagation}
      >
        <div className="flex items-center justify-center">{children}</div>
      </motion.div>
    )
  })()

  const bottomRightPositionContent = (() => {
    const page = editor.getCurrentPage()
    editor.getCamera() // this is just to subscribe to the camera
    if (page.meta.type !== 'manga') return null

    const eligibleShapes = editor
      .getCurrentPageShapes()
      .filter((shape) => editor.isShapeOfType<TLFrameShape>(shape, 'frame'))
      .filter((shape) => {
        const existingShape = editor.getShapeAtPoint(
          {
            x: shape.x + shape.props.w + 24,
            y: shape.y,
          },
          {
            hitInside: true,
            hitFrameInside: true,
          }
        )
        return !existingShape
      })
    if (eligibleShapes.length === 0) return null

    return eligibleShapes.map((shape) => {
      return <InFrontOfTheCanvasFrameShape key={shape.id} shape={shape} />
    })
  })()

  const webtoonAddPageContent = (() => {
    const page = editor.getCurrentPage()
    editor.getCamera() // this is just to subscribe to the camera
    if (page.meta.type !== 'webtoon') return null

    const eligibleShapes = editor
      .getRenderingShapes()
      .filter(({ shape }) => editor.isShapeOfType<TLFrameShape>(shape, 'frame'))
      .filter(({ shape }) => {
        const shapeOnPage = editor.getShapePageBounds(shape)
        if (!shapeOnPage) return false
        const existingShape = editor.getShapeAtPoint(
          {
            x: shapeOnPage.x + shapeOnPage.w / 2,
            y: shapeOnPage.y + shapeOnPage.h + 24,
          },
          {
            hitInside: true,
            hitFrameInside: true,
          }
        )
        return !existingShape
      })
    if (eligibleShapes.length === 0) return null

    return eligibleShapes.map(({ shape }) => {
      return (
        <InFrontOfTheCanvasWebtoonAddPageSection key={shape.id} shape={shape as TLFrameShape} />
      )
    })
  })()

  return (
    <>
      {topPositionContent}
      {bottomPositionContent}
      {bottomRightPositionContent}
      {webtoonAddPageContent}
    </>
  )
})

export const GenerateButton = track(() => {
  const { t } = useTranslation()

  const editor = useEditor()
  const form = useFormContext<GenerationStateForm>()
  const generation = useGenerationService()
  const { ensureAuthenticated } = useAuthDialog()

  const shape = editor.getSelectedShapes().find((shape) => shape.type === 'genbox') as
    | PSGenBoxShape
    | undefined

  return (
    <AnimatePresence>
      {shape && (
        <MotionButton
          initial={{ opacity: 0, scale: 0.9, y: 2 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.9, y: 2 }}
          style={{ x: '-50%' }}
          className="bottom-outer-padding left-1/2 z-canvas mt-4 h-10 px-6"
          onPointerDown={stopEventPropagation}
          onClick={async (e) => {
            await ensureAuthenticated({
              subtitle: 'Please login to enable generation features.',
            })
            form.handleSubmit((data) => {
              generation.submitGenerationTask(shape, data)
            })(e)
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95, opacity: 0.8 }}
        >
          {t('action.generate')}
        </MotionButton>
      )}
    </AnimatePresence>
  )
})
