import pixchanHiPng from '@/assets/pixchan-hi.png?url'
import { withCustomizedErrorBoundary } from '@/components/global/ErrorBoundary'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useEditor } from '@/states/EditorProvider'
import { CONFIG } from '@/utils/config'
import { LOCALSTORAGE_KEYS } from '@/utils/consts'
import invariant from '@/utils/invariant'
import { track } from '@troph-team/tldraw'
import { FC, PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

export const SettingsDialog: FC<PropsWithChildren<object>> = withCustomizedErrorBoundary(
  ({ children }) => {
    const { t } = useTranslation()

    return (
      <Dialog>
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent>
          <img
            src={pixchanHiPng}
            alt="PixChan"
            className="pointer-events-none absolute -top-36 right-12 size-48 rotate-12"
          />

          <DialogHeader>
            <DialogTitle>{t('settings-dialog.title')}</DialogTitle>
          </DialogHeader>

          <div className="flex flex-col gap-2">
            <SettingsLanguage />
            <SettingsFontSize />
            <SettingsPriority />

            {import.meta.env.DEV && <SettingsDebugMode />}
          </div>
        </DialogContent>
      </Dialog>
    )
  }
)
SettingsDialog.displayName = 'SettingsDialog'

type AnySupportedLocale = (typeof CONFIG.SUPPORTED_LOCALES)[number]['locale']

const LANGUAGES: { label: string; value: AnySupportedLocale }[] = [
  {
    label: 'English',
    value: 'en-US',
  },
  {
    label: '日本語',
    value: 'ja-JP',
  },
  {
    label: '한국어',
    value: 'ko-KR',
  },
]

const SettingsLanguage: FC = () => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language

  return (
    <div className="flex items-center gap-2">
      <Label className="min-w-32 shrink-0">{t('settings-dialog.language.title')}</Label>

      <Select
        value={currentLanguage}
        onValueChange={(value) => {
          i18n.changeLanguage(value)
        }}
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {LANGUAGES.map((language) => (
              <SelectItem key={language.value} value={language.value}>
                <span>{language.label}</span>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}

const SettingsDebugMode: FC = track(() => {
  const { t } = useTranslation()
  const editor = useEditor()

  return (
    <>
      <div className="flex items-center gap-2 py-1">
        <Label className="min-w-32 shrink-0">{t('action.toggle-debug-mode.menu')}</Label>

        <Checkbox
          checked={editor.getInstanceState().isDebugMode}
          onCheckedChange={(debugMode) => {
            invariant(debugMode !== 'indeterminate', "Debug mode can't be indeterminate")
            editor.updateInstanceState({
              isDebugMode: debugMode,
            })
          }}
        />
      </div>

      <div className="text-sm text-gray-500">
        editor.getPath(): <span className="font-mono">{editor.getPath()}</span>
      </div>
    </>
  )
})
SettingsDebugMode.displayName = 'SettingsDebugMode'

const SettingsPriority: FC = () => {
  const [priority, setPriority] = useLocalStorage(LOCALSTORAGE_KEYS.GENERATION_PRIORITY, '1000')

  return (
    <div className="flex items-center gap-2">
      <Label className="min-w-32 shrink-0">Priority</Label>

      <Select value={priority} onValueChange={setPriority}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="1500">
              <span>Highest</span>
            </SelectItem>
            <SelectItem value="1000">
              <span>High</span>
            </SelectItem>
            <SelectItem value="NONE">
              <span>Normal</span>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}

const SettingsFontSize: FC = () => {
  const [fontSizeSettings, setFontSizeSettings] = useLocalStorage(
    LOCALSTORAGE_KEYS.FONT_SIZE,
    CONFIG.DEFAULT_FONT_SIZE.toString()
  )
  useEffect(() => {
    document.documentElement.style.fontSize = fontSizeSettings + 'px'
  }, [fontSizeSettings])

  return (
    <div className="flex items-center gap-2">
      <Label className="min-w-32 shrink-0">Layout Font size</Label>

      <Select value={fontSizeSettings} onValueChange={setFontSizeSettings}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {/* <SelectItem value="10">
              <span>xs</span>
            </SelectItem> */}
            <SelectItem value="12">
              <span>S</span>
            </SelectItem>
            <SelectItem value="14">
              <span>M</span>
            </SelectItem>
            <SelectItem value="16">
              <span>L</span>
            </SelectItem>
            {/* <SelectItem value="18">
              <span>xl</span>
            </SelectItem> */}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}
