import { CONFIG } from '@/utils/config'
import invariant from '@/utils/invariant'
import { useEditor, useKeyboardShortcuts, useNativeClipboardEvents } from '@troph-team/tldraw'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

const useI18nSyncLocalePreference = () => {
  const { i18n } = useTranslation()
  const editor = useEditor()

  useEffect(() => {
    const listener = () => {
      const locale = CONFIG.SUPPORTED_LOCALES.find(
        (locale) => locale.locale === i18n.language
      )?.tldrawLocale

      invariant(locale, `unsupported locale: ${i18n.language}`)

      editor.user.updateUserPreferences({
        locale: locale,
      })
    }

    listener()

    i18n.on('languageChanged', listener)
    return () => {
      i18n.off('languageChanged', listener)
    }
  }, [editor, i18n])
}

export const TldrawSideEffect = () => {
  useKeyboardShortcuts()
  useNativeClipboardEvents()
  useI18nSyncLocalePreference()

  const editor = useEditor()

  useEffect(() => {
    function handleMaxShapes({ name, count }: { name: string; pageId: string; count: number }) {
      toast.error('Maximum Shapes Reached', {
        description: `You've reached the maximum number of shapes allowed on ${name} (${count}). Please delete some shapes or move to a different page to continue.`,
      })
    }

    editor.addListener('max-shapes', handleMaxShapes)
    return () => {
      editor.removeListener('max-shapes', handleMaxShapes)
    }
  }, [editor])

  return null
}
