import { toast } from 'sonner'

export class DetailedError extends Error {
  debug: object

  constructor(message: string, debug: object) {
    super(message)
    this.name = 'DetailedError'
    this.debug = debug
  }

  toString() {
    return `${this.name}: ${this.message}`
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      debug: this.debug,
    }
  }
}

export const fireErrorToast = (title: string, error: unknown) => {
  if (error instanceof DetailedError) {
    return toast.error(title, {
      description: error.message,

      action: {
        label: 'Copy Debug Info',
        onClick: () => {
          navigator.clipboard.writeText(JSON.stringify(error.toJSON(), null, 2))
          toast.success('Debug info has been copied to clipboard')
        },
      },
    })
  }
  if (error instanceof Error) {
    return toast.error(title, {
      description: error.message,
    })
  }
  return toast.error(title, {
    description: <pre>{JSON.stringify(error, null, 2)}</pre>,
  })
}

export const formatError = (error: unknown) => {
  if (error instanceof Error) {
    return error.message
  }
  return JSON.stringify(error, null, 2)
}
