import {
  GenBoxPanelLoraNestedPanel,
  ModelContentById,
} from '@/components/canvas/shape/genbox/lora/GenBoxPanelLoraNestedPanel'
import { Button } from '@/components/ui/button'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { InputSlider } from '@/components/ui/input-slider'
import { Label } from '@/components/ui/label'
import { GenerationStateForm } from '@/states/Generation'
import { useRightDrawerState } from '@/states/RightDrawerStateProvider'
import { AnimatePresence } from 'framer-motion'
import { X } from 'lucide-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const GenBoxPanelLora = () => {
  const { t } = useTranslation()
  const { activeOverlay, openActiveOverlay } = useRightDrawerState()
  const { control } = useFormContext<GenerationStateForm>()

  return (
    <>
      <AnimatePresence>
        {activeOverlay === 'lora' && <GenBoxPanelLoraNestedPanel />}
      </AnimatePresence>
      <FormField
        control={control}
        name="loras"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('gen.lora.label')}</FormLabel>
            <FormControl>
              <div className="flex flex-col gap-1">
                <Button variant="secondary" onClick={() => openActiveOverlay('lora')}>
                  {t('gen.lora.label.add')}
                </Button>

                {field.value.length > 0 && <div className="h-px w-full bg-gray-200" />}

                <div className="flex flex-col gap-1">
                  {field.value.map((lora) => (
                    <div key={lora.modelVersionId} className="flex gap-1 rounded bg-background p-1">
                      <div className="flex flex-1 flex-col items-start justify-center">
                        <ModelContentById id={lora.modelVersionId} className="flex-1" />

                        <div className="-mt-1 flex w-full flex-col rounded-b border border-t-0 border-solid border-border p-1 pt-3">
                          <Label className="text-muted-foreground">Weight</Label>

                          <InputSlider
                            value={lora.weight}
                            onValueChange={(value) => {
                              field.onChange(
                                field.value.map((l) =>
                                  l.modelVersionId === lora.modelVersionId
                                    ? { ...l, weight: value }
                                    : l
                                )
                              )
                            }}
                            min={-0.5}
                            max={1.5}
                            step={0.05}
                          />
                        </div>
                      </div>

                      <Button
                        variant="ghost"
                        onClick={() => {
                          field.onChange(
                            field.value.filter((l) => l.modelVersionId !== lora.modelVersionId)
                          )
                        }}
                        size="icon"
                      >
                        <X className="size-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}
