import { cn } from '@/lib/utils'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { FC, PropsWithChildren, ReactElement } from 'react'
import RadixIconsChevronDown from '~icons/radix-icons/chevron-down'

const Collapsible = CollapsiblePrimitive.Root

const CollapsibleTrigger: FC<
  PropsWithChildren<{
    open: boolean
    className?: string
    ChevronComponent?: ReactElement
    disabled?: boolean
  }>
> = ({ open, children, className, disabled, ChevronComponent }) => {
  return (
    <CollapsiblePrimitive.CollapsibleTrigger asChild disabled={disabled}>
      <div
        className={cn(
          'flex items-center px-4 py-3 leading-none select-none',
          !disabled && 'cursor-pointer hover:bg-secondary active:brightness-95',
          className
        )}
      >
        {children}
        {!disabled && (ChevronComponent ? (
          ChevronComponent
        ) : (
          <RadixIconsChevronDown className={`transition-transform ${open ? 'rotate-180' : ''}`} />
        ))}
      </div>
    </CollapsiblePrimitive.CollapsibleTrigger>
  )
}

const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent

export { Collapsible, CollapsibleContent, CollapsibleTrigger }
