import { LoginDialog } from '@/components/settings/LoginDialog'
import { LoginMutation } from '@/services/__generated__/graphql'
import { useAuthToken } from '@/states/auth'
import { FetchResult } from '@apollo/client'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

class UserCancelledError extends Error {
  constructor() {
    super('User cancelled the operation')
    this.name = 'UserCancelledError'
  }
}

type AuthDialogContent =
  | {
      subtitle: string
    }
  | undefined

type AuthDialogState =
  | {
      state: 'open'
      content: AuthDialogContent

      resolve: (result: FetchResult<LoginMutation>) => void
      reject: (error: Error) => void
    }
  | {
      state: 'closed'
    }

export const AuthDialogContext = createContext<{
  state: AuthDialogState
  // void: means the user has already been authenticated
  ensureAuthenticated: (content?: AuthDialogContent) => Promise<FetchResult<LoginMutation> | void>
  dismiss: () => void
}>({
  state: { state: 'closed' },
  ensureAuthenticated: () => {
    throw new Error('Not implemented')
  },
  dismiss: () => {
    throw new Error('Not implemented')
  },
})

export const useAuthDialog = () => {
  return useContext(AuthDialogContext)
}

export const AuthDialogProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [authToken] = useAuthToken()
  const [state, setState] = useState<AuthDialogState>({ state: 'closed' })

  const ensureAuthenticated = useCallback(
    (content?: AuthDialogContent) => {
      if (authToken === null) {
        return new Promise<FetchResult<LoginMutation>>((resolve, reject) => {
          setState({
            state: 'open',
            content,
            resolve: (res) => {
              setState({ state: 'closed' })
              resolve(res)
            },
            reject,
          })
        })
      }

      return Promise.resolve()
    },
    [authToken, setState]
  )

  const value = useMemo(
    () => ({
      state,
      ensureAuthenticated,
      dismiss: () => {
        if (state.state === 'open') state.reject(new UserCancelledError())
        setState({ state: 'closed' })
      },
    }),
    [state, ensureAuthenticated]
  )

  return (
    <AuthDialogContext.Provider value={value}>
      {children}

      {state.state === 'open' && <LoginDialog />}
    </AuthDialogContext.Provider>
  )
}
