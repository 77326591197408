import { BufferedInput } from '@/components/ui/buffered-input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { RadioCard, RadioCardProps } from '@/components/ui/radio-card'
import { clamp, coerceToNumber } from '@/lib/numbers'
import { GenerationStateForm } from '@/states/Generation'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Preset = {
  label: string
  width: number
  height: number
}

type SizeOption =
  | {
      type: 'preset'
      preset: Preset
    }
  | {
      type: 'input'
      width: number
      height: number
    }

const PRESETS: Preset[] = [
  {
    label: 'editor:gen.size.preset.portrait',
    width: 768,
    height: 1280,
  },
  {
    label: 'editor:gen.size.preset.landscape',
    width: 1280,
    height: 768,
  },
  {
    label: 'editor:gen.size.preset.square',
    width: 1024,
    height: 1024,
  },
]

const SizePresetOption: FC<
  Omit<RadioCardProps, 'title' | 'subtitle' | 'icon'> & {
    preset: Preset
    selected?: boolean
  }
> = ({ preset, selected, ...props }) => {
  const { t } = useTranslation()

  return (
    <RadioCard
      icon={
        <div
          className={clsx(
            'rounded-[2px] border-2 border-solid bg-black/10 transition',
            preset.width > preset.height ? 'w-8' : 'h-8',
            selected ? 'border-white' : 'border-foreground'
          )}
          style={{
            aspectRatio: `${preset.width}/${preset.height}`,
          }}
        />
      }
      title={t(preset.label)}
      subtitle={`${preset.width} × ${preset.height}`}
      selected={selected}
      {...props}
    />
  )
}

const useGenBoxPanelSizeOption = (): SizeOption => {
  const { watch } = useFormContext<GenerationStateForm>()
  const [width, height] = watch(['width', 'height'] as const)

  return useMemo(() => {
    const matched = PRESETS.find((preset) => {
      return preset.width === width && preset.height === height
    })
    if (matched) {
      return {
        type: 'preset' as const,
        preset: matched,
      }
    }
    return {
      type: 'input' as const,
      width,
      height,
    }
  }, [width, height])
}

export const GenBoxPanelSize = () => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<GenerationStateForm>()
  const sizeOption = useGenBoxPanelSizeOption()

  return (
    <div className="flex flex-col gap-1">
      <Label>{t('gen.size.label')}</Label>
      <div className="mt-1 grid grid-cols-3 grid-rows-1 gap-1">
        {PRESETS.map((preset) => (
          <SizePresetOption
            key={`${preset.width}x${preset.height}`}
            preset={preset}
            selected={
              sizeOption.type === 'preset' &&
              sizeOption.preset.width === preset.width &&
              sizeOption.preset.height === preset.height
            }
            onClick={() => {
              setValue('width', preset.width)
              setValue('height', preset.height)
            }}
          />
        ))}
      </div>
      <div
        className={clsx(
          'flex items-center gap-1 rounded p-2 transition-colors',
          sizeOption.type === 'input' ? 'bg-primary' : 'bg-gray-200'
        )}
      >
        <FormField
          control={control}
          name="width"
          render={({ field }) => (
            <FormItem className="flex-1 gap-1">
              <FormLabel
                className={clsx(
                  'text-xs transition-colors',
                  sizeOption.type === 'input' && 'text-white'
                )}
              >
                {t('gen.size.width')}
              </FormLabel>
              <FormControl>
                <BufferedInput<number>
                  selectAllOnClick
                  ref={field.ref}
                  value={field.value}
                  transformFromT={(v) => v.toFixed(0)}
                  transformToT={(v) => clamp(coerceToNumber(v), 128, 4096)}
                  onValueChange={field.onChange}
                  onBlur={field.onBlur}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="height"
          render={({ field }) => (
            <FormItem className="flex-1 gap-1">
              <FormLabel
                className={clsx(
                  'text-xs transition-colors',
                  sizeOption.type === 'input' && 'text-white'
                )}
              >
                {t('gen.size.height')}
              </FormLabel>
              <FormControl>
                <BufferedInput<number>
                  selectAllOnClick
                  ref={field.ref}
                  value={field.value}
                  transformFromT={(v) => v.toFixed(0)}
                  transformToT={(v) => clamp(coerceToNumber(v), 128, 4096)}
                  onValueChange={field.onChange}
                  onBlur={field.onBlur}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}
