import { ELEMENT_IDS } from '@/utils/consts.ts'
import { getThenRemoveQueryParam } from '@/utils/url.ts'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { ampli } from './ampli'
import App from './App.tsx'
import './index.css'


ampli.load({
  disabled: !import.meta.env.PROD,
  environment: 'pixstudio',
  client: {
    configuration: {
      autocapture: {
        formInteractions: false,
      },
      // Set the device ID to the query parameter `pixai_did` if it exists
      deviceId: getThenRemoveQueryParam('pixai_did'),
    },
  },
})

// Create and Install Session Replay Plugin
const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 0.1,
})
amplitude.add(sessionReplayTracking)

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: 'https://ce5f129bae977fddba461abe19872db5@o374408.ingest.us.sentry.io/4507589706776576',
  integrations: [Sentry.browserTracingIntegration(), Sentry.extraErrorDataIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  release: import.meta.env.VITE_PIXSTUDIO_BUILD_GIT_SHA,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled.
  // should enable on all subdomains and apex domains of "pixai.art"
  tracePropagationTargets: ['localhost', 'pixai.art'],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
})

document.getElementById(ELEMENT_IDS.SHELL_PRELOADER_CONTENT_TEXT)!.textContent =
  'エディターアセットを読み込み中...'

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
