import { InputSlider } from '@/components/ui/input-slider'
import { Label } from '@/components/ui/label'
import { PSGenMediaShape, TLImageShape, useEditor } from '@troph-team/tldraw'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import AngleTurntable from './AngleTurntable'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion'

export const ImageStylesPanel: FC<{
  shape: PSGenMediaShape | TLImageShape
}> = ({ shape }) => {
  const { t } = useTranslation()

  const { blurDirection, blurIntensity } = shape.props

  const editor = useEditor()

  // const sizes = STYLES.size;

  return (
    <Accordion type="single" collapsible className="w-full border-b-2 px-4" defaultValue="image">
      <AccordionItem value="image">
        <AccordionTrigger className="font-bold">{t('style-panel.image')}</AccordionTrigger>
        <AccordionContent className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <Label>{t('style-panel.blurDirection')}</Label>
            <AngleTurntable
              angle={blurDirection || 0}
              setAngle={(func) => {
                editor.updateShape({
                  id: shape.id,
                  type: shape.type,
                  props: { blurDirection: func(blurDirection || 0) },
                })
              }}
            />

            <Label>{t('style-panel.blurIntensity')}</Label>

            <InputSlider
              value={blurIntensity || 0}
              onValueChange={(value) => {
                editor.updateShape({
                  id: shape.id,
                  type: shape.type,
                  props: { blurIntensity: value },
                })
              }}
              min={0}
              max={100}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
