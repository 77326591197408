import { Button, ButtonProps } from '@/components/ui/button'
import clsx from 'clsx'
import { forwardRef, ReactNode } from 'react'

export type RadioCardProps = ButtonProps & {
  title: ReactNode
  subtitle?: ReactNode
  icon?: ReactNode

  selected?: boolean
}

export const RadioCard = forwardRef<HTMLButtonElement, RadioCardProps>(
  ({ title, subtitle, icon, selected, className, ...props }, ref) => {
    return (
      <Button
        variant="outline"
        className={clsx(
          'flex size-full flex-col items-center self-stretch p-2 text-foreground transition active:brightness-90',
          selected
            ? 'bg-primary text-white hover:bg-primary hover:text-white hover:opacity-85'
            : 'bg-gray-200',
          className
        )}
        ref={ref}
        aria-selected={selected}
        {...props}
      >
        <div className="mb-2 flex size-8 max-w-8 items-center justify-center">{icon}</div>

        <div className="tracking-tight">{title}</div>

        {subtitle && (
          <div className="w-full whitespace-break-spaces text-xs font-light tabular-nums tracking-tighter opacity-80">
            {subtitle}
          </div>
        )}
      </Button>
    )
  }
)
