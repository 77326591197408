import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import {
  createEmptyFramePreset,
  createMangaFramePreset,
  getTLContentFramePresets,
} from '@/utils/shapes/frame-family/presets'
import { PopoverPortal } from '@radix-ui/react-popover'
import {
  stopEventPropagation,
  TLContent,
  TLFrameShape,
  TLShape,
  useEditor,
} from '@troph-team/tldraw'
import { motion } from 'framer-motion'
import { FC, useState } from 'react'
import RadixIconsPlus from '~icons/radix-icons/plus'

const PADDING = 24

export const InFrontOfTheCanvasFrameShape: FC<{
  shape: TLFrameShape
}> = ({ shape }) => {
  const [open, setOpen] = useState(false)
  const editor = useEditor()

  const shapeScreenBox = editor.pageToScreenBox(editor.getShapePageBounds(shape)!)
  const shapeBottomRight = {
    x: shapeScreenBox.x + shapeScreenBox.width,
    y: shapeScreenBox.y + shapeScreenBox.height,
  }

  const frames = (
    [
      {
        create: () => {
          const emptyFrame = createEmptyFramePreset()

          editor.createShapes([
            {
              ...emptyFrame,
              x: shape.x + shape.props.w + PADDING,
              y: shape.y,
            },
          ])

          return emptyFrame
        },
        preview: <div className="aspect-[595/842] h-32 w-auto border border-black bg-gray-300" />,
      },
      {
        create: () => {
          const shapes = createMangaFramePreset({
            x: shape.x + shape.props.w + PADDING,
            y: shape.y,
          })

          editor.createShapes(shapes)

          return shapes.find((shape) => shape.type === 'frame')
        },
        preview: (
          <div className="aspect-[595/842] h-32 w-auto border border-black bg-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              direction="ltr"
              width="1190"
              height="1684"
              viewBox="0 0 1190 1684"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="size-full"
            >
              <defs>
                <clipPath id="EZFeol8Rf-2K7m91d9cV_">
                  <path d="M0,0L1190,0L1190,1684L0,1684Z" />
                </clipPath>
                <clipPath id="YPiZgG5leb5qa6OpfWhJw">
                  <path d="M0,0L1190,0L1190,1684L0,1684Z" />
                </clipPath>
                <clipPath id="NCBylhHvIn019rzVGp5d5">
                  <path d="M0,0L1190,0L1190,1684L0,1684Z" />
                </clipPath>
                <clipPath id="IiZ01eHwaaWriXx_JUYuF">
                  <path d="M0,0L1190,0L1190,1684L0,1684Z" />
                </clipPath>
              </defs>
              <g clip-path="url(#EZFeol8Rf-2K7m91d9cV_)">
                <g transform="matrix(1, 0, 0, 1, 40, 40)" opacity="1">
                  <g>
                    <path
                      width="535"
                      height="791.2436974789916"
                      fill="#fcfffe"
                      stroke="#1d1d1d"
                      stroke-width="5"
                      d="M 0,0 L 535,0 L 535,687.3781512605042 L 0,831.2436974789916 Z"
                    />
                  </g>
                </g>
              </g>
              <g clip-path="url(#YPiZgG5leb5qa6OpfWhJw)">
                <g transform="matrix(1, 0, 0, 1, 615, 40)" opacity="1">
                  <g>
                    <path
                      width="535"
                      height="676.6218487394958"
                      fill="#fcfffe"
                      stroke="#1d1d1d"
                      stroke-width="5"
                      d="M 0,0 L 535,0 L 535,532.7563025210084 L 0,676.6218487394958 Z"
                    />
                  </g>
                </g>
              </g>
              <g clip-path="url(#NCBylhHvIn019rzVGp5d5)">
                <g transform="matrix(1, 0, 0, 1, 40, 911.2437)" opacity="1">
                  <g>
                    <path
                      width="535"
                      height="956.6218487394958"
                      fill="#fcfffe"
                      stroke="#1d1d1d"
                      stroke-width="5"
                      d="M 0,0 L 535,-143.86554621848745 L 535,732.7563025210084 L 0,732.7563025210084 Z"
                    />
                  </g>
                </g>
              </g>
              <g clip-path="url(#IiZ01eHwaaWriXx_JUYuF)">
                <g transform="matrix(1, 0, 0, 1, 615, 756.6218)" opacity="1">
                  <g>
                    <path
                      width="535"
                      height="1111.2436974789916"
                      fill="#fcfffe"
                      stroke="#1d1d1d"
                      stroke-width="5"
                      d="M 0,0 L 535,-143.86554621848745 L 535,887.3781512605042 L 0,887.3781512605042 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        ),
      },
      ...getTLContentFramePresets().map((preset) => ({
        create: () => {
          editor.putContentOntoCurrentPage(preset.content as TLContent, {
            point: {
              x: shape.x + shape.props.w + preset.dimensions.x / 2 + PADDING,
              y: shape.y + preset.dimensions.y / 2,
            },
            preservePosition: false,
            preserveIds: false,
            select: true,
          })
        },
        preview: (
          <div className="aspect-[595/842] h-32 w-auto border border-black bg-gray-300 [&>svg]:size-full">
            {preset.preview}
          </div>
        ),
      })),
    ] as {
      create: () => TLShape
      preview: JSX.Element
    }[]
  ).map((frame) => {
    const create = () => {
      const created = frame.create()

      editor.select(created.id)
      editor.zoomToSelection()

      setOpen(false)
    }

    return (
      <button
        className="flex items-center transition duration-150 hover:shadow-lg active:scale-95 active:brightness-90"
        onClick={create}
      >
        {frame.preview}
      </button>
    )
  })

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <motion.button
          key={`bottom-right-${shape.id}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 0.3, scale: 1 }}
          whileHover={{ opacity: 1, scale: 1 }}
          whileTap={{ opacity: 0.95, scale: 0.95 }}
          style={{
            x: shapeBottomRight.x - 4 - 16, // 16: half size
            y: shapeBottomRight.y + 20 - 16, // 16: half size
          }}
          className="pointer-events-auto absolute left-0 top-0 z-canvas text-foreground"
          transition={{ type: 'spring', damping: 20, stiffness: 300 }}
          onPointerDown={stopEventPropagation}
        >
          <div className="flex size-6 items-center justify-center rounded-full bg-primary">
            <RadixIconsPlus className="size-4 text-white" />
          </div>
        </motion.button>
      </PopoverTrigger>

      <PopoverPortal>
        <PopoverContent side="right" className="w-auto p-1" onPointerDown={stopEventPropagation}>
          <PopoverArrow />
          <div className="flex flex-col items-start gap-1 p-1 pt-0">
            <h3 className="w-full select-none py-1 font-bold">Add Page</h3>

            <div className="grid w-full grid-cols-3 items-center gap-2">{frames}</div>
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}
