import { Box2d, Editor, TLShape, TLShapeId, TLSvgOptions, getSvgAsImage } from '@troph-team/tldraw'

/** @public */
export type RenderTypes = 'png' | 'jpeg' | 'webp'
export type RenderOptions = {
  level?: 'normal' | 'pose'
  format: RenderTypes
}

type ShapeWithDimensionsInProps = TLShape & {
  props: { w: number; h: number }
}

function assertDimensionsInProps(shape: TLShape): asserts shape is ShapeWithDimensionsInProps {
  const props = shape.props as unknown as { w?: number; h?: number }
  if (!props) throw new Error('Shape is missing props.')
  if (typeof props.w !== 'number' || typeof props.h !== 'number') {
    throw new Error('Shape is missing width or height.')
  }
}

/**
 * Renders the area under the shape, excluding the shape itself.
 *
 * @public
 */
export async function renderAreaUnderShape(
  editor: Editor,
  shape: TLShape,
  { level = 'normal', format }: RenderOptions,
  opts = {} as Partial<TLSvgOptions>
) {
  assertDimensionsInProps(shape)

  const box = new Box2d(shape.x, shape.y, shape.props.w, shape.props.h)

  // get all shapes contained by or intersecting the box
  const shapeIds = editor
    .getCurrentPageShapes()
    .filter((s) => {
      if (s.id === shape.id) return false
      const pageBounds = editor.getShapeMaskedPageBounds(s)
      if (!pageBounds) return false
      return box.includes(pageBounds)
    })
    .filter((s) => (level === 'pose' ? s.type === 'pose' : s.type !== 'pose'))
    .map((s) => s.id)

  return renderShapes(editor, shapeIds, format, opts)
}

export async function renderShapes(
  editor: Editor,
  shapeIds: TLShapeId[],
  format: RenderTypes = 'png',
  opts = {} as Partial<TLSvgOptions>
) {
  if (!shapeIds.length) return

  const svg = await editor.getSvg(shapeIds, opts)
  if (!svg) {
    throw new Error('Could not construct SVG.')
  }

  switch (format) {
    case 'webp':
    case 'jpeg':
    case 'png': {
      return getSvgAsImage(svg, editor.environment.isSafari, {
        type: format,
        quality: 1,
        scale: 1,
      }).then((image) => {
        if (!image) throw Error()
        return image
      })
    }

    default:
      throw new Error(`Export type ${format satisfies never} not supported.`)
  }
}
