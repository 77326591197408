import { GenBoxPanelIPAdapter } from '@/components/canvas/shape/genbox/GenBoxPanelIPAdapter'
import { GenBoxPanelSeed } from '@/components/canvas/shape/genbox/GenBoxPanelSeed'
import { FC } from 'react'
import { GenBoxPanelPose } from './GenBoxPanelPose'

import { GenBoxPanelSteps } from '@/components/canvas/shape/genbox/advanced/GenBoxPanelSteps'
import { GenBoxPanelLora } from '@/components/canvas/shape/genbox/GenBoxPanelLora'
import { GenBoxPanelModel } from '@/components/canvas/shape/genbox/GenBoxPanelModel'
import { GenBoxPanelStyleMix } from '@/components/canvas/shape/genbox/GenBoxPanelStyleMix'
import { withCustomizedErrorBoundary } from '@/components/global/ErrorBoundary'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { GenerationStateForm } from '@/states/Generation'
import { AnimatePresence, motion } from 'framer-motion'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GenBoxPanelCFGScale } from './advanced/GenBoxPanelCFGScale'
import { GenBoxPanelExportImport } from './advanced/GenBoxPanelExportImport'
import { GenBoxPanelNegativePrompt } from './advanced/GenBoxPanelNegativePrompt'
import { GenBoxPanelSamplingMethod } from './advanced/GenBoxPanelSamplingMethod'
import { GenBoxPanelHyperSD } from './GenBoxPanelHyperSD'
import { GenBoxPanelPrompt } from './GenBoxPanelPrompt'
import { GenBoxPanelSize } from './GenBoxPanelSize'

export const GenBoxPanel: FC = withCustomizedErrorBoundary(() => {
  const { t } = useTranslation()

  const { watch } = useFormContext<GenerationStateForm>()
  const isUsingHyperSD = watch('useHyperSD')

  return (
    <div className="flex flex-col gap-3 px-4 py-3">
      <GenBoxPanelModel />
      <AnimateHideGenBoxPanel hidden={isUsingHyperSD}>
        <GenBoxPanelLora />
      </AnimateHideGenBoxPanel>
      <div className="-mx-4 h-px w-[calc(100%+2rem)] bg-gray-200" />
      <GenBoxPanelPrompt />
      <AnimateHideGenBoxPanel hidden={isUsingHyperSD}>
        <GenBoxPanelPose />
      </AnimateHideGenBoxPanel>
      <GenBoxPanelSize />
      <GenBoxPanelSeed />
      <GenBoxPanelHyperSD />
      <AnimateHideGenBoxPanel hidden={isUsingHyperSD}>
        <GenBoxPanelStyleMix />
      </AnimateHideGenBoxPanel>
      <AnimateHideGenBoxPanel hidden={isUsingHyperSD}>
        <GenBoxPanelIPAdapter />
      </AnimateHideGenBoxPanel>

      <div className="-mx-4 h-px w-[calc(100%+2rem)] bg-gray-200" />
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="advanced">
          <AccordionTrigger className="h-8">{t('gen.advanced-setting')}</AccordionTrigger>
          <AccordionContent className="mt-2 flex flex-col gap-3">
            <GenBoxPanelNegativePrompt />
            <AnimateHideGenBoxPanel hidden={isUsingHyperSD}>
              <GenBoxPanelSamplingMethod />
            </AnimateHideGenBoxPanel>
            <AnimateHideGenBoxPanel hidden={isUsingHyperSD}>
              <GenBoxPanelSteps />
            </AnimateHideGenBoxPanel>
            <AnimateHideGenBoxPanel hidden={isUsingHyperSD}>
              <GenBoxPanelCFGScale />
            </AnimateHideGenBoxPanel>
            <GenBoxPanelExportImport />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
})

GenBoxPanel.displayName = 'GenBoxPanel'

const AnimateHideGenBoxPanel = ({
  hidden,
  children,
}: {
  hidden?: boolean
  children: React.ReactNode
}) => {
  return (
    <AnimatePresence mode="popLayout">
      {!hidden && (
        <motion.div
          style={{ transformOrigin: 'top' }}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, scaleY: 0.8, height: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
