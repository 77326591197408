import { ampli } from '@/ampli'
import { withCustomizedErrorBoundary } from '@/components/global/ErrorBoundary'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { client } from '@/services/client'
import { MUTATION_LOGIN, QUERY_ME } from '@/services/user'
import { useAuthDialog } from '@/states/AuthDialogProvider'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import * as zod from 'zod'
import RadixIconsInfoCircled from '~icons/radix-icons/info-circled'

export const loginFormSchema = zod
  .object({
    email: zod.string().email(),
    password: zod.string().min(8),
  })
  .required()

export type LoginFormType = zod.infer<typeof loginFormSchema>

export const useLogin = () => {
  const { t } = useTranslation(['auth'])
  const [submitting, setSubmitting] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const submit = async (data: LoginFormType) => {
    setSubmitting(true)

    return toast.promise(
      client
        .mutate({
          mutation: MUTATION_LOGIN,
          variables: {
            input: {
              email: data.email,
              password: data.password,
              recaptchaToken: await executeRecaptcha?.('login'),
            },
          },
          refetchQueries: [QUERY_ME],
        })
        // actually setting the token is located at https://github.com/troph-team/pixstudio/blob/ebe8c51ef394690233e8b243552a65b4aebf74bf/src/services/client.ts#L117-L120
        .then((res) => {
          ampli.identify(res.data?.login?.id, {
            pixai_is_admin: res.data?.login?.isAdmin || false,
          })
          return res
        })
        .finally(() => {
          setSubmitting(false)
        }),
      {
        loading: t('auth:login.toast.loading'),
        success: (data) =>
          t('auth:login.toast.success', {
            displayName: data.data?.login?.displayName || data.data?.login?.username,
          }),
        error: (error) => t('auth:login.toast.error', { error: error.message }),
      }
    )
  }

  return [submit, submitting] as const
}

export const LoginForm: FC = withCustomizedErrorBoundary(() => {
  const { t } = useTranslation(['auth'])
  const { control } = useFormContext<LoginFormType>()

  return (
    <div className="grid gap-4 py-4 text-left">
      <div className="grid grid-cols-1 items-center gap-4">
        <FormField
          control={control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('auth:email.label')}</FormLabel>
              <FormControl>
                <Input id="email" autoComplete="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-1 items-center gap-4">
        <FormField
          control={control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('auth:password.label')}</FormLabel>
              <FormControl>
                <Input id="password" type="password" autoComplete="current-password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
})

export const LoginDialogInner: FC = withCustomizedErrorBoundary(() => {
  const { t } = useTranslation(['auth'])
  const [onSubmit, submitting] = useLogin()

  const form = useForm<LoginFormType>({
    resolver: zodResolver(loginFormSchema),
  })
  const { state, dismiss } = useAuthDialog()
  if (state.state === 'closed') return null

  return (
    <Dialog open onOpenChange={(open) => !open && dismiss()}>
      <DialogPortal>
        <DialogContent className="sm:max-w-[425px]">
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Form {...form}>
              {state.content && (
                <Alert className="mb-4">
                  <AlertDescription className="text-muted-foreground">
                    {state.content.subtitle ?? 'Please login to continue.'}
                  </AlertDescription>
                </Alert>
              )}
              <DialogHeader>
                <DialogTitle>{t('auth:login.title')}</DialogTitle>
                <DialogDescription>{t('auth:login.description')}</DialogDescription>
              </DialogHeader>
              <LoginForm />
              <DialogFooter>
                <div className="flex w-full items-center">
                  <div className="text-muted-foreground flex select-none items-start gap-2">
                    <RadixIconsInfoCircled className="size-4" />
                    <p className="text-xs">{t('auth:login.oauth.unavailable-alert')}</p>
                  </div>

                  <div className="flex-1" />

                  <Button type="submit" isLoading={submitting} disabled={submitting}>
                    {t('auth:login.action')}
                  </Button>
                </div>
              </DialogFooter>
            </Form>
          </form>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
})

LoginDialogInner.displayName = 'LoginDialogInner'
