import { humanizeNumbers } from '@/lib/strings'
import { cn } from '@/lib/utils'
import type { CompletionRequest, CompletionResponse } from '@/workers/tagComplete.worker'
import TagCompleteWorker from '@/workers/tagComplete.worker?worker&inline'
import clsx from 'clsx'
import { createPortal } from 'react-dom'

import {
  FocusEvent,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { toast } from 'sonner'

export type PromptsCompletionData = {
  prompts: string
  selectionStart: number
  selectionEnd: number
  x: number
  y: number
}

type PromptsCompletionRequest = {
  data: PromptsCompletionData
  req: CompletionRequest
  query: string
  prefix: string
  suffix: string
}

type PromptsCompletionResult = PromptsCompletionRequest & {
  completions: {
    id: number
    name: string
    post_count: number
  }[]
}

export type PromptsCompletionRef = {
  confirm: () => boolean
  shift: (count: number) => boolean
  onInputBlur: (e: FocusEvent) => void
}

// popularity color code, from least to most popular, from shallow blue gray to deep orange
const POST_COUNT_COLOR_CODES = [
  'bg-gray-400',
  'bg-gray-500',
  'bg-gray-600',
  'bg-gray-700',
  'bg-gray-800',
  'bg-gray-900',
  'bg-yellow-500',
  'bg-yellow-600',
  'bg-yellow-700',
  'bg-yellow-800',
  'bg-yellow-900',
  'bg-orange-500',
  'bg-orange-600',
  'bg-orange-700',
  'bg-orange-800',
  'bg-orange-900',
]
const POST_COUNT_COLOR_CODE_TABLE_MIN_POST_COUNT = 1
const POST_COUNT_COLOR_CODE_TABLE_MAX_POST_COUNT = 1000000
const POST_COUNT_COLOR_CODE_TABLE = (() => {
  const table: [number, number, string][] = []
  // take log10 of post count so that the color code is more evenly distributed. take into account the min and max post count
  // so the last color code should cover [1000000, inf]
  const logMin = Math.log10(POST_COUNT_COLOR_CODE_TABLE_MIN_POST_COUNT)
  const logMax = Math.log10(POST_COUNT_COLOR_CODE_TABLE_MAX_POST_COUNT)
  const logStep = (logMax - logMin) / POST_COUNT_COLOR_CODES.length
  for (let i = 0; i < POST_COUNT_COLOR_CODES.length; i++) {
    table.push([
      Math.pow(10, logMin + logStep * i),
      Math.pow(10, logMin + logStep * (i + 1)),
      POST_COUNT_COLOR_CODES[i],
    ])
  }
  return table
})()

const getColorCode = (postCount: number) => {
  for (const [min, max, color] of POST_COUNT_COLOR_CODE_TABLE) {
    if (postCount >= min && postCount < max) {
      return color
    }
  }
  return POST_COUNT_COLOR_CODES[POST_COUNT_COLOR_CODES.length - 1]
}

export default forwardRef(function PromptsCompletion(
  {
    data,
    setSelection,
    onLoadingProgress,
    onWorkerError,
  }: {
    data?: PromptsCompletionData
    setSelection: (start: number, end: number) => [number, number]
    onLoadingProgress: (progress: number) => void
    onWorkerError: (error: ErrorEvent) => void
  },
  ref: Ref<PromptsCompletionRef>
) {
  const _onLoadingProgress = useRef(onLoadingProgress)
  _onLoadingProgress.current = onLoadingProgress
  const _onWorkerError = useRef(onWorkerError)
  _onWorkerError.current = onWorkerError

  const containerRef = useRef<HTMLDivElement>(null)

  const workingData = useRef<PromptsCompletionRequest | null>(null)
  const pendingData = useRef<PromptsCompletionRequest | null>(null)
  const [result, setResult] = useState<PromptsCompletionResult | null>(null)
  const [selected, setSelected] = useState(0)

  const [worker, setWorker] = useState<Worker | null>(null)
  useEffect(() => {
    const worker = new TagCompleteWorker()

    const sendNext = () => {
      if (pendingData.current) {
        workingData.current = pendingData.current
        worker.postMessage(pendingData.current.req satisfies CompletionRequest)
        pendingData.current = null
      }
    }

    worker.onmessage = (event) => {
      const data = event.data as CompletionResponse

      switch (data.type) {
        case 'progress': {
          _onLoadingProgress.current?.(data.progress)
          break
        }
        case 'completion': {
          setResult({
            ...workingData.current!,
            completions: data.completions,
          })
          setSelected(0)
          workingData.current = null

          sendNext()

          break
        }
      }
    }

    worker.onerror = (event) => {
      setWorker(null)
      setResult(null)
      workingData.current = null
      pendingData.current = null
      toast.error(event.message)
      _onWorkerError.current?.(event)
    }

    sendNext()

    setWorker(worker)

    return () => {
      setWorker(null)
      worker.terminate()

      if (!pendingData.current) {
        pendingData.current = workingData.current
      }
      workingData.current = null

      setResult(null)
    }
  }, [])
  const queueRequest = useCallback(
    (data: PromptsCompletionRequest) => {
      if (!worker || workingData.current) {
        pendingData.current = data
      } else {
        workingData.current = data
        worker.postMessage(data.req satisfies CompletionRequest)
      }
    },
    [worker]
  )

  useEffect(() => {
    if (!data) {
      setResult(null)
      return
    }

    const before = data.prompts.slice(0, data.selectionStart)
    // search beforehand until any of , ( [ ] )
    const [, prefix = '', query = ''] = before.match(/(^|[\s\S]*[,()[\]]\s*)([^,()[\]]*)$/) ?? []
    // remove trailing comma
    const suffix = data.prompts.slice(data.selectionEnd).replace(/^\s*,\s*/, '')

    queueRequest({
      data,
      query,
      prefix,
      suffix,
      req: {
        query,
      },
    })
  }, [data, queueRequest])

  const confirm = useCallback(
    (idx = selected) => {
      if (!result || !result.completions[idx]) return false

      // prefix + completion + ', ' + suffix
      // using insertText here since it preserves undo stack
      const completion = result.completions[idx].name + ', '
      setSelection(result.prefix.length, result.data.prompts.length - result.suffix.length)
      document.execCommand('insertText', false, completion)
      setSelection(
        result.prefix.length + completion.length,
        result.prefix.length + completion.length
      )

      setResult(null)

      return true
    },
    [selected, result, setSelection]
  )

  const shift = useCallback(
    (count: number) => {
      if (!result || result.completions.length === 0) return false
      setSelected((selected + count + result.completions.length) % result.completions.length)
      return true
    },
    [result, selected]
  )

  const onInputBlur = useCallback((e: FocusEvent) => {
    // clicking on the list will also cause the input to blur,
    // so here we exclude that case
    if (!containerRef.current?.contains(e.relatedTarget as Node)) setResult(null)
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      confirm,
      shift,
      onInputBlur,
    }),
    [confirm, shift, onInputBlur]
  )

  const tabIndicator = useMemo(
    () => (
      <span className="ml-1 scale-75 rounded-md border border-solid border-gray-400 px-1 py-0.5 text-xs leading-none text-gray-400">
        TAB
      </span>
    ),
    []
  )

  if (!result) return

  return createPortal(
    <div
      ref={containerRef}
      className={cn(
        'z-popover fixed flex flex-col bg-background border border-solid border-gray-100 dark:border-gray-600 min-w-[10rem] overflow-auto max-h-[25rem] py-0.5 rounded-sm',
        (result?.completions.length ?? 0) === 0 ? 'hidden' : 'shadow-lg'
      )}
      style={{
        left: result?.data.x || -1000,
        top: result?.data.y || -1000,
      }}
    >
      {result?.completions.map((completion, index) => (
        <button
          key={completion.id}
          className={cn(
            'transform px-1 py-px w-full text-start flex items-center justify-between gap-1 text-sm select-none',
            index === selected ? 'bg-muted' : 'text-foreground'
          )}
          onClick={() => confirm(index)}
          onPointerEnter={() => setSelected(index)}
        >
          <div className="line-clamp-1 truncate">{completion.name}</div>
          <div
            className={clsx(
              'shrink-0 rounded-full px-1 py-0.5 text-[11px] leading-none text-white',
              getColorCode(completion.post_count)
            )}
          >
            {humanizeNumbers(completion.post_count)}
          </div>
          <div className="flex-1" />
          <div className="mr-1 shrink-0">{index === selected && tabIndicator}</div>
        </button>
      ))}
    </div>,
    document.body
  )
})
