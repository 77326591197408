import MangaIcon from '@/assets/manga.svg?react'
import WebtoonIcon from '@/assets/webtoon.svg?react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogPortal,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { useEditor } from '@/states/EditorProvider'
import {
  createPage,
  initializeCurrentPageAsMangaPage,
  initializeCurrentPageAsWebtoonPage,
} from '@/utils/editor'
import { TLPage, track } from '@troph-team/tldraw'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import RadixIconsFile from '~icons/radix-icons/file'
import RadixIconsFilePlus from '~icons/radix-icons/file-plus'
import RadixIconPencil from '~icons/radix-icons/pencil-2'
import RadixIconsTrash from '~icons/radix-icons/trash'

import { useConfirm, usePrompt } from '@/components/ui/AlertDialogProvider'
import { Download, Upload } from 'lucide-react'
import { ListItem } from '../../ui/ListItem'

const WorkspaceListItem = track(
  ({
    page,
    deleteDisabled,
    onSelected,
  }: {
    page: TLPage
    deleteDisabled?: boolean
    onSelected?: (page: TLPage) => void
  }) => {
    const { t } = useTranslation()
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const editor = useEditor()
    const prompt = usePrompt()
    const currentPage = editor.getCurrentPage()
    const selected = currentPage.id === page.id
    const Icon =
      {
        webtoon: WebtoonIcon,
        manga: MangaIcon,
        freeform: RadixIconsFile,
      }[page.meta.type as string] ?? RadixIconsFile

    return (
      <>
        <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
          <AlertDialogPortal>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the workspace.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  variant="destructive"
                  onClick={() => {
                    toast.success(`Workspace "${page.name}" has been deleted`)
                    editor.deletePage(page.id)
                  }}
                >
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>

        <ListItem
          className="group relative flex items-center justify-start px-4 py-2 text-sm"
          onClick={() => {
            editor.setCurrentPage(page.id)
            onSelected?.(page)
          }}
          selected={selected}
        >
          <Icon className="mr-2 size-4 shrink-0" />
          <span>{page.name}</span>
          <div className="flex-1" />
          <Button
            variant="ghost"
            className={clsx(
              'absolute right-10 hidden size-6 p-1 text-center group-hover:flex',
              selected
                ? 'text-white hover:bg-gray-200/50 hover:text-white'
                : 'text-secondary-foreground hover:bg-gray-200/50'
            )}
            onClick={async (e) => {
              e.stopPropagation()
              const rename = await prompt({
                title: t('page-menu.rename-workspace'),
                body: t('page-menu.rename-workspace-description'),
                defaultValue: page.name,
              })

              if (!rename) return
              editor.renamePage(page.id, rename)
            }}
          >
            <RadixIconPencil className="size-4" />
          </Button>
          <Button
            variant="ghost"
            className={clsx(
              'absolute right-4 hidden size-6 p-1 text-center hover:text-white group-hover:flex',
              deleteDisabled && 'cursor-not-allowed opacity-70',
              selected
                ? 'hover:bg-destructive text-white'
                : 'text-secondary-foreground hover:bg-destructive'
            )}
            onClick={(e) => {
              e.stopPropagation()
              setShowDeleteDialog(true)
            }}
            disabled={deleteDisabled}
          >
            <RadixIconsTrash className="size-4" />
          </Button>
        </ListItem>
      </>
    )
  }
)

export const WorkspaceListContainer: FC<{
  onSelected?: (page: TLPage) => void
}> = track(({ onSelected }) => {
  const { t } = useTranslation()

  const editor = useEditor()
  const pages = editor.getPages()
  const confirm = useConfirm()

  return (
    <div className="bg-background flex h-full w-80 flex-col">
      <div className="-mr-2 flex items-center pl-4 text-base">
        <span className="font-bold">{t('page-menu.title')}</span>

        <div className="flex-1" />

        <Button
          variant="ghost"
          size="icon"
          onClick={() => {
            const link = document.createElement('a')
            const file = new Blob([JSON.stringify(editor.store.getSnapshot())], {
              type: 'text/plain',
            })
            link.href = URL.createObjectURL(file)
            link.download = `workspace-export.pixstudio`
            link.click()
            URL.revokeObjectURL(link.href)
          }}
          tooltip="Export workspaces"
        >
          <Download className="size-5" />
        </Button>

        <Button
          variant="ghost"
          size="icon"
          onClick={async () => {
            const confirmed = await confirm({
              title: 'Warning',
              body: 'Are you sure you want to import a workspace? This will overwrite your current workspace.',
            })

            if (!confirmed) return

            return new Promise<void>((resolve, reject) => {
              const input = document.createElement('input')
              input.type = 'file'
              input.accept = '.pixstudio'

              // reject on cancel
              input.oncancel = () => {
                reject(new Error('User cancelled'))
              }
              input.onchange = function () {
                try {
                  const files = input.files
                  if (files !== null) {
                    if (files.length > 0) {
                      const file = files[0]
                      if (file !== null) {
                        const reader = new FileReader()
                        reader.onload = (event) => {
                          if (event.target !== null) {
                            const result = event.target.result as string
                            if (result !== null) {
                              editor.store.loadSnapshot(JSON.parse(result))
                              setTimeout(() => {
                                resolve()
                                window.location.reload()
                              }, 1000)
                            }
                          }
                        }
                        reader.readAsText(file)
                      }
                    }
                  }
                } catch (err) {
                  reject(err)
                  console.error(err)
                }
              }
              input.click()
            })
          }}
          tooltip={"Import workspaces"}
        >
          <Upload className="size-5" />
        </Button>

        <Button
          variant="ghost"
          size="icon"
          onClick={() => {
            const page = createPage(editor, 'Webtoon Workspace', {
              type: 'webtoon',
            })
            initializeCurrentPageAsWebtoonPage(editor)
            onSelected?.(page)
          }}
          tooltip={t('page-menu.create-new-webtoon')}
        >
          <WebtoonIcon className="size-5" />
        </Button>

        <Button
          variant="ghost"
          size="icon"
          onClick={() => {
            const page = createPage(editor, 'Manga Workspace', {
              type: 'manga',
            })
            initializeCurrentPageAsMangaPage(editor)
            onSelected?.(page)
          }}
          tooltip={t('page-menu.create-new-manga')}
        >
          <MangaIcon className="size-5" />
        </Button>

        <Button
          variant="ghost"
          size="icon"
          onClick={() => {
            const page = createPage(editor, 'Workspace', { type: 'freeform' })
            onSelected?.(page)
          }}
          tooltip={t('page-menu.create-new-page')}
        >
          <RadixIconsFilePlus className="size-5" />
        </Button>
      </div>

      <div className="bg-border h-px w-full" />

      <ul className="m-0 flex-1 list-none overflow-y-auto p-0">
        {pages.map((page) => (
          <WorkspaceListItem
            key={page.id}
            page={page}
            deleteDisabled={pages.length <= 1}
            onSelected={onSelected}
          />
        ))}
      </ul>
    </div>
  )
})
