import * as Sentry from '@sentry/react'
import { FC, PropsWithChildren } from 'react'
import RadixIconsInfoCircled from '~icons/radix-icons/info-circled'

const parseAndLog = (
  stack: string | null,
  eventId: string | null,
  error?: unknown
): string | null => {
  try {
    if (error) {
      console.error(
        `[ErrorBarrier | Report] ErrorBarrier (with Sentry eventId: ${eventId}) has caught a fatal error that is causing part (or all) of the app to malfunction. An ErrorBarrier has displayed. Details as follows:\n\n### componentStack:\n`,
        stack,
        '\n\n### error traceback:\n\n',
        error
      )
    }
    if (stack) {
      const regex = /withErrorBarrier\((.*)\)/gm
      let m
      const components: string[] = []

      while ((m = regex.exec(stack)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            components.push(match)
          }
        })
      }

      const errorName = (() => {
        if (typeof error === 'string') {
          return error.slice(0, 150)
        }
        if (error instanceof Error) {
          return `${error?.name}: ${error?.message}`.slice(0, 150)
        }
        return 'parse error failed'
      })()

      return `${errorName}%${components.length ? components.join('#') : '*'}`
    }
  } catch (e) {
    // silently fail: componentStack is provided with an invalid value
  }
  return null
}

export const CustomizedErrorBoundary: FC<PropsWithChildren<object>> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, eventId }) => (
        <div className="flex h-full flex-col items-center justify-center gap-2 px-4 py-8">
          <RadixIconsInfoCircled className="size-8 text-muted-foreground" />
          <div className="text-lg font-bold">Something went wrong</div>

          <div className="text-center text-xs text-muted-foreground">
            {parseAndLog(componentStack, eventId, error)}
          </div>

          <div className="text-xs text-muted-foreground">Error ID: {eventId}</div>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

// write a HOC that wraps the ErrorBoundary component around the component
// while also providing type safety for the props
export const withCustomizedErrorBoundary =
  <P extends object>(Component: FC<P>): FC<P> =>
  (props) => {
    return (
      <CustomizedErrorBoundary>
        <Component {...props} />
      </CustomizedErrorBoundary>
    )
  }
