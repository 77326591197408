export async function makeMask(
  svgElement: SVGSVGElement,
  feathering: number = 0,
  newWidth: number,
  newHeight: number
): Promise<Blob> {
  const svgNS = 'http://www.w3.org/2000/svg'

  // Create a new SVG element to hold the filter
  const defs = svgElement.querySelector('defs') ?? document.createElementNS(svgNS, 'defs')

  // Create the Gaussian blur filter
  const filter = document.createElementNS(svgNS, 'filter')
  filter.setAttribute('id', 'featheringFilter')
  defs.appendChild(filter)

  // Make the parts of the image that is not completely transparent 100% opaque
  const feColorMatrix = document.createElementNS(svgNS, 'feColorMatrix')
  feColorMatrix.setAttribute('type', 'matrix')
  feColorMatrix.setAttribute('values', '0 0 0 20 -10 0 0 0 20 -10 0 0 0 20 -10 0 0 0 20 -10')
  filter.appendChild(feColorMatrix)

  const feGaussianBlur = document.createElementNS(svgNS, 'feGaussianBlur')
  feGaussianBlur.setAttribute('in', 'SourceGraphic')
  feGaussianBlur.setAttribute('stdDeviation', feathering.toString())
  filter.appendChild(feGaussianBlur)

  // Apply the filter
  svgElement.setAttribute('filter', 'url(#featheringFilter)')

  // Find all elements with a stroke attribute and modify it to #ffffff
  const elements = svgElement.querySelectorAll('[stroke]')
  for (const element of elements) {
    element.setAttribute('stroke', '#ffffff')
    element.setAttribute('opacity', '1.0')
  }

  // Set SVG background to black
  svgElement.style.backgroundColor = 'black'

  // Convert the filtered SVG to a Blob
  const svgBlob = new Blob([new XMLSerializer().serializeToString(svgElement)], {
    type: 'image/svg+xml',
  })
  const url = URL.createObjectURL(svgBlob)

  // Create an offscreen canvas to draw the SVG
  const img = new Image()
  img.src = url

  return new Promise((resolve, reject) => {
    img.onload = async () => {
      // Create an offscreen canvas with the new dimensions
      const offscreenCanvas = new OffscreenCanvas(newWidth, newHeight)
      const ctx = offscreenCanvas.getContext('2d') as OffscreenCanvasRenderingContext2D

      // Draw the SVG onto the canvas with scaling
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight)

      // Convert the canvas to a Blob
      offscreenCanvas.convertToBlob({ type: 'image/png' }).then(resolve).catch(reject)

      // Revoke the object URL
      URL.revokeObjectURL(url)
    }

    img.onerror = (err) => {
      reject(err)
    }
  })
}
