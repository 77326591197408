import { Button } from '@/components/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { FormActivationIndicator } from '@/components/ui/FormActivationIndicator'
import { Label } from '@/components/ui/label'
import { randomId } from '@/lib/strings'
import { GenerationStateForm } from '@/states/Generation'
import { fireErrorToast } from '@/utils/errors'
import invariant from '@/utils/invariant'
import { uploadMedia } from '@/utils/uploadMedia'
import { getThumbnailUrl } from '@/utils/url'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import LineMdLoadingTwotoneLoop from '~icons/line-md/loading-twotone-loop'
import RadixIconsCross1 from '~icons/radix-icons/cross-1'
import RadixIconsFilePlus from '~icons/radix-icons/file-plus'

const readFilesAsBlobURLs = async (files: File[]) => {
  return Promise.all(
    files.map(async (file) => {
      const fileReader = new FileReader()
      return new Promise<string>((resolve, reject) => {
        fileReader.onload = (e) => {
          const result = e.target?.result
          if (result instanceof ArrayBuffer) {
            const blob = new Blob([result])
            resolve(URL.createObjectURL(blob))
          } else {
            reject(new Error('Failed to read file'))
          }
        }
        fileReader.readAsArrayBuffer(file)
      })
    })
  )
}

export const GenBoxPanelIPAdapter = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { control, getValues, watch } = useFormContext<GenerationStateForm>()

  const value = watch('ipAdapter.referenceImageMedias').filter((e) => e.mediaId)

  return (
    <Collapsible open={value.length > 0 ? true : open} onOpenChange={setOpen}>
      <CollapsibleTrigger disabled={value.length > 0} className="-mx-4 -mb-3 -mt-1 w-[calc(100%+2rem)] px-4 py-3" open={open}>
        <Label>{t('gen.ip-adapter.label')}</Label>
        <div className="flex-1" />

        <FormActivationIndicator
          active={value.length > 0}
          label={value.length > 0 ? t('gen.ip-adapter.active') : t('gen.ip-adapter.inactive')}
        />
      </CollapsibleTrigger>
      <CollapsibleContent className="mt-3">
        <FormField<GenerationStateForm, 'ipAdapter.referenceImageMedias'>
          control={control}
          name="ipAdapter.referenceImageMedias"
          render={({ field }) => {
            const handleFiles = async (fileList: FileList) => {
              const files = Array.from(fileList ?? [])
                .slice(0, 4 - field.value.length)
                .map((file) => ({ id: randomId(16), file }))
              invariant(files.length > 0, 'No files selected or too many files selected')

              const previewUrls = (await readFilesAsBlobURLs(files.map((f) => f.file))).map(
                (previewUrl, index) => ({
                  id: files[index].id,
                  previewUrl,
                })
              )

              field.onChange([...field.value, ...previewUrls])
              field.onBlur()

              const id = toast.loading(`Uploading ${files.length} files...`)
              try {
                const uploadedFiles = await Promise.all(
                  files.map(async (file) => ({
                    ...file,
                    media: await uploadMedia(file.file),
                  }))
                )

                const value = getValues().ipAdapter.referenceImageMedias ?? []

                const newValue = [
                  ...value.filter((f) => !uploadedFiles.some((file) => file.id === f.id)),
                  ...uploadedFiles.flatMap((file) => {
                    if (file.media.mediaId) {
                      const existing = value.find((f) => f.id === file.id)
                      if (existing) {
                        return [
                          {
                            id: file.id,
                            previewUrl: getThumbnailUrl(file.media.media),
                            mediaId: file.media.mediaId,
                          },
                        ]
                      }
                    }
                  }),
                ]

                field.onChange(newValue)
                field.onBlur()
              } catch (e) {
                fireErrorToast('Failed to upload files', e)
              } finally {
                toast.dismiss(id)
              }
            }

            return (
              <FormItem>
                <FormControl>
                  <div className="grid grid-cols-4 grid-rows-1 gap-1">
                    {field.value.map(({ previewUrl, mediaId }, index) => (
                      <div className="group relative aspect-square size-full border border-solid border-muted">
                        <img
                          key={index}
                          src={previewUrl}
                          alt={mediaId}
                          className="size-full object-cover"
                        />
                        {previewUrl && !mediaId && (
                          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <LineMdLoadingTwotoneLoop className="text-white" />
                          </div>
                        )}
                        <button
                          className="absolute right-0 top-0 hidden bg-black bg-opacity-50 p-1 text-white group-hover:block"
                          onClick={() => {
                            field.onChange(field.value.filter((_, i) => i !== index))
                          }}
                        >
                          <RadixIconsCross1 />
                        </button>
                      </div>
                    ))}
                    {field.value.length < 4 && (
                      <Button
                        className="flex size-full flex-col items-center gap-1 object-cover py-3"
                        variant="outline"
                        style={{
                          gridColumn: `span ${4 - field.value.length}`,
                        }}
                        ref={field.ref}
                        onClick={async () => {
                          const input = document.createElement('input')
                          input.type = 'file'
                          input.multiple = true
                          input.accept = 'image/*'
                          input.click()

                          input.onchange = async (e) => {
                            invariant(e.target instanceof HTMLInputElement, 'Invalid target')

                            invariant(e.target.files instanceof FileList, 'Invalid files')

                            handleFiles(e.target.files)
                          }
                        }}
                        onDrop={(e) => {
                          e.preventDefault()
                          handleFiles(e.dataTransfer.files)
                        }}
                        onDragOver={(e) => {
                          e.preventDefault()
                          e.dataTransfer.dropEffect = 'copy'
                        }}
                      >
                        <RadixIconsFilePlus className="text-muted-foreground" />

                        <span className="mb-1 text-[10px] leading-none text-foreground">
                          {t('gen.ip-adapter.click-or-drop')}
                        </span>

                        <span className="text-[10px] leading-none text-muted-foreground">
                          ({field.value.length} / 4)
                        </span>
                      </Button>
                    )}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
      </CollapsibleContent>
    </Collapsible>
  )
}
