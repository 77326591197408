import { cn } from '@/lib/utils'
import { useEditor } from '@/states/EditorProvider'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import {
  getIndexAbove,
  getIndexBelow,
  getIndexBetween,
  TLShape,
  TLShapeId,
  track,
} from '@troph-team/tldraw'
import clsx from 'clsx'
import { FC, Fragment, useMemo, useState } from 'react'

import { useDelayedMount } from '@/utils/useDelayedMount'
import { createPortal } from 'react-dom'
import LineMdLoadingTwotoneLoop from '~icons/line-md/loading-twotone-loop'
import { LayerListItem } from './LayerListItem'

type TLShapeTree = TLShape & {
  children: TLShapeTree[]
}

const treeizeShapes = (shapes: TLShape[]): TLShapeTree[] => {
  const shapeMap = new Map<string, TLShapeTree>()
  const rootShapes: TLShapeTree[] = []

  shapes.forEach((shape) => {
    shapeMap.set(shape.id, { ...shape, children: [] })
  })

  shapes.forEach((shape) => {
    const parent = shapeMap.get(shape.parentId)
    if (parent) {
      parent.children.push(shapeMap.get(shape.id)!)
    } else {
      rootShapes.push(shapeMap.get(shape.id)!)
    }
  })

  return rootShapes
}

// sortShapeTree is a helper function that sorts a tree of shapes by their index, recursively
const sortShapeTree = (tree: TLShapeTree[]): TLShapeTree[] => {
  return tree
    .map((shape) => {
      return {
        ...shape,
        children: sortShapeTree(shape.children),
      }
    })
    .sort((a, b) => b.index.localeCompare(a.index))
}

const LayerList: FC<{
  className?: string
  root?: boolean
  tree: TLShapeTree[]
  dark?: boolean
}> = track(({ className, root, tree, dark }) => {
  const filteredTree = tree.filter((shape) => shape.type !== 'genbox')
  return (
    <div className={cn('flex flex-col', className)}>
      <SortableContext
        strategy={verticalListSortingStrategy}
        items={filteredTree.map((shape) => shape.id)}
      >
        {filteredTree.map((shape) => (
          <Fragment key={`fragment-${shape.id}`}>
            <LayerListItem
              key={`shape-${shape.id}`}
              shape={shape}
              className={clsx(!root && 'pl-3')}
              root={root}
              dark={dark}
            />
            {shape.children.length > 0 && (
              <LayerList tree={shape.children} key={`children-${shape.id}`} dark={dark} />
            )}
          </Fragment>
        ))}
      </SortableContext>
    </div>
  )
})

export const LayerListContainer = track(({ dark }: { dark?: boolean }) => {
  const delayedMount = useDelayedMount()

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const editor = useEditor()
  const shapes = editor.getCurrentPageShapesSorted()
  const activeShape = activeId ? editor.getShape(activeId as TLShapeId) : undefined

  const sortedTree = useMemo(() => {
    return sortShapeTree(treeizeShapes(shapes))
  }, [shapes])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    })
  )

  function handleDragEnd(event: DragEndEvent) {
    setActiveId(null)

    const { active, over } = event

    if (!over || active.id === over.id) return

    const activeShape = editor.getShape(active.id as TLShapeId)
    const overShape = editor.getShape(over.id as TLShapeId)

    if (!activeShape || !overShape) return

    const activeIndex = shapes.findIndex((shape) => shape.id === active.id)
    const overIndex = shapes.findIndex((shape) => shape.id === over.id)

    if (activeIndex === -1 || overIndex === -1) return

    let newIndex: string

    if (activeIndex < overIndex) {
      // Moving down
      newIndex = getIndexBetween(
        overShape.index,
        shapes[overIndex + 1]?.index || getIndexAbove(overShape.index)
      )
    } else {
      // Moving up
      newIndex = getIndexBetween(
        shapes[overIndex - 1]?.index || getIndexBelow(overShape.index),
        overShape.index
      )
    }

    editor.updateShapes([
      {
        id: activeShape.id,
        type: activeShape.type,
        index: newIndex,
      },
    ])
  }

  return (
    <div className="relative flex flex-col">
      {delayedMount ? (
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          onDragStart={(event) => setActiveId(event.active.id)}
          onDragEnd={handleDragEnd}
        >
          <LayerList tree={sortedTree} root className="flex-1" dark={dark} />
          {createPortal(
            <DragOverlay>
              {activeShape ? (
                <LayerListItem
                  dark={dark}
                  shape={activeShape}
                  className={clsx('opacity-50 shadow-md', dark ? 'bg-zinc-900' : 'bg-zinc-100')}
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      ) : (
        <div className="flex size-full items-center justify-center">
          <LineMdLoadingTwotoneLoop className="size-6" />
        </div>
      )}
    </div>
  )
})
