import { GenFormController } from '@/components/GenFormController'
import { GenerationOnCanvasController } from '@/components/GenerationOnCanvasController'
import { TldrawSideEffect } from '@/components/TldrawSideEffect'
import { CanvasCTA } from '@/components/canvas/CanvasCTA'
import { Form } from '@/components/ui/form'
import translationsEN from '@/locales/en-US/default.json'
import translationsJA from '@/locales/ja-JP/default.json'
import translationsKO from '@/locales/ko-KR/default.json'
import { EditorContext } from '@/states/EditorProvider'
import { GenerationStateForm } from '@/states/Generation'
import { useGenBoxForm } from '@/states/useGenBoxForm'
import { ELEMENT_IDS, LOCALSTORAGE_KEYS } from '@/utils/consts'
import { initializeCurrentPageAsEmptyPage } from '@/utils/editor'
import invariant from '@/utils/invariant'
import { startViewTransition } from '@/utils/startViewTransition'
import * as Sentry from '@sentry/react'
import { DefaultErrorFallback, Editor, Tldraw } from '@troph-team/tldraw'
import '@troph-team/tldraw/tldraw.css'
import { useContext, useEffect } from 'react'
import LineMdLoadingTwotoneLoop from '~icons/line-md/loading-twotone-loop'
import { Toolbar } from './Toolbar'
import { RightDrawer } from './canvas/RightDrawer'
import { InFrontOfTheCanvas } from './canvas/infrontof/InFrontOfTheCanvas'

const LoadingScreen = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-2 text-white">
      <LineMdLoadingTwotoneLoop className="size-8 shrink-0" />
      <div>Loading...</div>
    </div>
  )
}

function prepareTranslationBlobUrls() {
  const assets: Record<'en' | 'ja' | 'ko-kr', Record<string, string>> = {
    en: translationsEN,
    ja: translationsJA,
    'ko-kr': translationsKO,
  }
  const urls = (Object.keys(assets) as Array<keyof typeof assets>).reduce((acc, lang) => {
    const blob = new Blob([JSON.stringify(assets[lang])], {
      type: 'application/json',
    })
    const url = URL.createObjectURL(blob)
    return { ...acc, [lang]: url }
  }, {} as Record<string, string>)
  return urls
}

const assetUrls = {
  translations: prepareTranslationBlobUrls(),
}

export function Canvas() {
  const { setEditor } = useContext(EditorContext)
  const form = useGenBoxForm()

  const removeOverlay = () => {
    try {
      const preloaderContentText = document.getElementById(ELEMENT_IDS.SHELL_PRELOADER_CONTENT_TEXT)
      invariant(
        preloaderContentText,
        'unable to find preloader content text within the current DOM tree'
      )
      preloaderContentText.textContent = '最終確認中...'

      const actuallyRemoveOverlay = () => {
        try {
          const preloader = document.getElementById(ELEMENT_IDS.SHELL_PRELOADER)
          invariant(preloader, 'unable to find preloader within the current DOM tree')
          preloader.remove()
        } catch (e) {
          console.warn('Unable to remove preloader:', e)
        }
      }

      setTimeout(() => {
        if (import.meta.env.DEV) {
          // it gets pretty annoying when HMR
          actuallyRemoveOverlay()
        } else {
          startViewTransition(() => {
            actuallyRemoveOverlay()
          })
        }
      }, 0)
    } catch (e) {
      console.warn('Unable to remove preloader:', e)
    }
  }

  const handleMount = (editor: Editor) => {
    removeOverlay()

    setEditor(editor)
    const meta = editor.getDocumentSettings().meta as Record<string, unknown> | undefined
    if (meta?.initialized === undefined) {
      initializeCurrentPageAsEmptyPage(editor)
      editor.user.updateUserPreferences({
        isDarkMode: false,
        isSnapMode: true,
      })
      editor.updateInstanceState({ isGridMode: true })
      editor.updateDocumentSettings({
        gridSize: 8,
        meta: { initialized: true },
      })
    }
  }

  return (
    <Form<GenerationStateForm> {...form}>
      <div className="relative size-full bg-zinc-900" id={ELEMENT_IDS.CANVAS_ROOT}>
        <Tldraw
          onMount={handleMount}
          hideUi
          assetUrls={assetUrls}
          persistenceKey={LOCALSTORAGE_KEYS.CANVAS}
          components={{
            Background: () => <div className="z-[var(--layer-background)] bg-zinc-900" />,
            Spinner: LoadingScreen,
            LoadingScreen,
            InFrontOfTheCanvas,
            ErrorFallback: (props) => {
              useEffect(() => {
                removeOverlay()
                Sentry.captureException(props.error, {
                  tags: { component: 'Canvas', from: 'ErrorFallback' },
                })
              }, [props.error])

              return <DefaultErrorFallback {...props} />
            },
            ErrorScreen: ({ children }) => {
              useEffect(() => {
                removeOverlay()
                Sentry.captureException(new Error(children), {
                  tags: { component: 'Canvas', from: 'ErrorScreen' },
                })
              }, [children])

              return <DefaultErrorFallback error={new Error(children)} />
            },
          }}
        >
          <Toolbar />
          <TldrawSideEffect />
          <CanvasCTA />
          <RightDrawer />
          <GenerationOnCanvasController />
          <GenFormController />
        </Tldraw>
      </div>
    </Form>
  )
}
