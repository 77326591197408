import { Button, ButtonProps } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { SwitchProps } from '@radix-ui/react-switch'
import { motion } from 'framer-motion'
import { forwardRef } from 'react'

export const MotionButton = motion(
  forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => <Button {...props} ref={ref} />)
)

export const MotionSwitch = motion(
  forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => <Switch {...props} ref={ref} />)
)
