import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { toast } from 'sonner'

import { HexAlphaColorPicker } from 'react-colorful'

import { Button } from '@/components/ui/button'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DropperIcon from '~icons/teenyicons/dropper-outline'

export const ColorPickerWithEyedrop: FC<{
  value: string
  onChange: (color: string) => void
}> = ({ value, onChange }) => {
  const { t } = useTranslation()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="flex h-8 w-28 items-center overflow-hidden rounded-lg bg-white text-xs tabular-nums">
          <div
            className="h-full w-10"
            style={{
              backgroundColor: value,
            }}
          ></div>
          <div className="flex-1 text-center">{value}</div>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          window.EyeDropper && (
            <Button
              className="mb-2 w-full"
              variant="outline"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const eyeDropper = new EyeDropper()

                eyeDropper
                  .open()
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .then((result: any) => {
                    onChange(result.sRGBHex)
                  })
                  .catch(() => {
                    toast.error(t('style-panel.color.failed-to-pick'))
                  })
              }}
            >
              <DropperIcon />
            </Button>
          )
        }

        <HexAlphaColorPicker color={value} onChange={onChange} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
