import { Mark, mergeAttributes } from '@tiptap/core'

export interface FuriganaOptions {
  HTMLAttributes: Record<string, string>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    furigana: {
      toggleFurigana: (attributes: { furigana: string }) => ReturnType

      setFurigana: (attributes: { furigana: string }) => ReturnType

      removeFurigana: () => ReturnType
    }
  }
}

export const Furigana = Mark.create<FuriganaOptions>({
  name: 'furigana',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      furigana: {
        default: null,

        renderHTML: (attributes) => {
          return {
            // "data-color": attributes.color,
            // style: `background-color: ${attributes.color}; color: inherit`,
            text: attributes.furigana,
          }
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'ruby',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ruby',
      mergeAttributes(this.options.HTMLAttributes),
      ['rb', 0],
      [
        'rt',
        {
          contenteditable: false,
          style: 'user-select: none',
        },
        HTMLAttributes.text,
      ],
    ]
  },

  addCommands() {
    return {
      toggleFurigana:
        (attributes: { furigana: string }) =>
        ({ commands }) => {
          return commands.toggleMark(this.name, attributes)
        },

      setFurigana:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes)
        },
      removeFurigana:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        },
    }
  },
})
