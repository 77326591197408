import clsx from 'clsx'

export function FormActivationIndicator({ active, label }: { active?: boolean; label: string }) {
  return (
    <div
      className={clsx(
        'select-none rounded-full px-2 py-1 text-2xs uppercase leading-none tracking-tight',
        active ? 'bg-green-600 text-white' : 'bg-gray-200 text-foreground/90'
      )}
    >
      {label}
    </div>
  )
}
