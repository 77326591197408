import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { GenerationModelType } from '@/services/__generated__/graphql'
import { GenerationStateForm } from '@/states/Generation'

import { compact } from '@/utils/compact'
import { MODEL_IDS } from '@/utils/consts'
import { getNodesFromConnection } from '@/utils/graphql'
import { getThumbnailUrl } from '@/utils/url'
import { useQuery } from '@apollo/client'
import { TooltipPortal } from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { uniqBy } from 'lodash-es'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  QUERY_GENERATION_MODEL,
  QUERY_GENERATION_MODELS,
  QUERY_MY_BOOKMARKED_GENERATION_MODELS,
} from '../../../../services/model'

type SomeModel = { title?: string; type: GenerationModelType }

const KNOWN_OPEN_SOURCE_MODELS = ['anything', 'animagine', 'stable diffusion', 'majicmix', 'SD3']

const MODEL_TYPES = [
  {
    matcher: (model: SomeModel) =>
      KNOWN_OPEN_SOURCE_MODELS.some((m) => model.title?.toLowerCase().includes(m) ?? false) &&
      model.type === GenerationModelType.SdV1Model,
    title: 'SD',
    className: 'bg-gray-500',
  },
  {
    matcher: (model: SomeModel) =>
      KNOWN_OPEN_SOURCE_MODELS.some((m) => !!model.title?.toLowerCase().includes(m)) &&
      model.type === GenerationModelType.SdxlModel,
    title: 'SDXL',
    className: 'bg-gray-600',
  },
  {
    matcher: (model: SomeModel) =>
      !KNOWN_OPEN_SOURCE_MODELS.some((m) => !!model.title?.toLowerCase().includes(m)) &&
      model.type === GenerationModelType.SdxlModel,
    title: 'PixPro',
    className: 'bg-purple-600',
  },
  {
    matcher: (model: SomeModel) =>
      KNOWN_OPEN_SOURCE_MODELS.some((m) => !!model.title?.toLowerCase().includes(m)) &&
      model.type === GenerationModelType.Sd3MediumModel,
    title: 'SD3',
    className: 'bg-gray-700',
  },
  {
    matcher: (model: SomeModel) =>
      !KNOWN_OPEN_SOURCE_MODELS.some(
        (m) =>
          // model.title.toLowerCase().includes(m)
          model.title?.toLowerCase().includes(m) ?? false
      ) && model.type === GenerationModelType.SdV1Model,
    title: 'PixStd',
    className: 'bg-blue-500',
  },
  // {
  //   id: GenerationModelType.Lora,
  //   title: "Adapter",
  //   className: "bg-green-600",
  // },
  // {
  //   id: GenerationModelType.Vae,
  //   title: "VAE",
  //   className: "bg-yellow-600",
  // },
]

const PREFERRED_MODEL_ORDER: GenerationModelType[] = [
  GenerationModelType.SdxlModel,
  GenerationModelType.Sd3MediumModel,
  GenerationModelType.SdV1Model,
]

export const GenBoxPanelModel = () => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<GenerationStateForm>()
  const { data: presetModelsData, loading: presetModelsLoading } = useQuery(
    QUERY_GENERATION_MODELS,
    {
      variables: {
        feed: 'preset',
      },
    }
  )
  const { data: extraModelData, loading: extraModelLoading } = useQuery(QUERY_GENERATION_MODEL, {
    variables: {
      id: MODEL_IDS.Y10,
    },
  })
  const { data: myBookmarkedGenerationModels, loading: myBookmarkedGenerationModelsLoading } =
    useQuery(QUERY_MY_BOOKMARKED_GENERATION_MODELS, {
      variables: {
        first: 36,
        modelTypes: [GenerationModelType.AnyModel],
      },
    })

  const anyLoading = presetModelsLoading || extraModelLoading || myBookmarkedGenerationModelsLoading

  const models = anyLoading
    ? []
    : uniqBy(
      compact([
        extraModelData?.generationModel,
        ...getNodesFromConnection(presetModelsData?.generationModels),
        ...getNodesFromConnection(myBookmarkedGenerationModels?.me?.bookmarkedGenerationModels),
      ]),
      (model) => model.id
    )
      .filter((model) => model.type !== GenerationModelType.Vae)
      .sort((a, b) => {
        // Sort by preferred order, and put unknown models at the end.
        const aIndex = PREFERRED_MODEL_ORDER.indexOf(a.type)
        const bIndex = PREFERRED_MODEL_ORDER.indexOf(b.type)
        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex
        }
        if (aIndex !== -1) {
          return -1
        }
        if (bIndex !== -1) {
          return 1
        }
        return a.title.localeCompare(b.title)
      })

  return (
    <FormField
      control={control}
      name="modelId"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t('gen.model.label')}</FormLabel>
          <FormControl>
            <Select
              value={field.value}
              onValueChange={(value) => {
                field.onChange(value)
                const type = models.find((m) => m.latestAvailableVersion?.id === value)?.type
                setValue(
                  'modelType',
                  type as GenerationModelType.SdxlModel | GenerationModelType.SdV1Model
                )
              }}
              disabled={anyLoading}
            >
              <SelectTrigger>
                <SelectValue placeholder="Fetching models..." />
              </SelectTrigger>
              <SelectContent>
                {models.map((model) => {
                  const config = MODEL_TYPES.find((config) => config.matcher(model))

                  if (!model.latestAvailableVersion?.id) return

                  return (
                    <Tooltip key={model.id}>
                      <TooltipTrigger asChild>
                        <SelectItem
                          value={model.latestAvailableVersion?.id}
                          disabled={!model.latestAvailableVersion}
                          textValue={model.title}
                        >
                          <div className="flex flex-1 items-center gap-2 text-left">
                            <img
                              src={getThumbnailUrl(model.media)}
                              className="size-6 rounded-sm object-cover"
                            />
                            <div className="leading-none text-secondary-foreground">
                              {model.title}
                            </div>
                            {config && (
                              <div
                                className={clsx(
                                  'inline-block shrink-0 rounded-sm px-1 py-0.5 text-right font-mono text-[11px] leading-none tracking-tighter text-white',
                                  config?.className
                                )}
                              >
                                {config?.title}
                              </div>
                            )}
                          </div>
                        </SelectItem>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent side="left" className="flex flex-col gap-1 p-1">
                          <img
                            src={getThumbnailUrl(model.media)}
                            className="h-full w-56 rounded-sm"
                          />

                          <div
                            className={clsx(
                              'flex items-center rounded-sm px-1 py-0.5 text-white',
                              config?.className
                            )}
                          >
                            <div className="truncate text-xs">{model.title}</div>

                            <div className="flex-1" />

                            {config && (
                              <div
                                className={clsx(
                                  'inline-block border-l border-solid border-white/50 pl-1 font-mono text-[11px] leading-none tracking-tighter'
                                )}
                              >
                                {config?.title}
                              </div>
                            )}
                          </div>
                          <TooltipArrow />
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  )
                })}
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
