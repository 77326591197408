import { Label } from '@/components/ui/label'
import { PSDialogueShape, PSRichDialogueShape, TLGeoShape, useEditor } from '@troph-team/tldraw'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorPickerWithEyedrop } from './ColorPickerWithEyedrop'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { InputSlider } from '@/components/ui/input-slider'

export const BackgroundAndStrokePanel: FC<{
  shape: TLGeoShape | PSDialogueShape | PSRichDialogueShape
}> = ({ shape }) => {
  const { t } = useTranslation()

  const editor = useEditor()

  return (
    <Accordion type="single" collapsible className="w-full border-b-2 px-4" defaultValue="color">
      <AccordionItem value="color">
        <AccordionTrigger className="font-bold">{t('style-panel.color.title')}</AccordionTrigger>
        <AccordionContent className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <Label>{t('style-panel.color.background')}</Label>
              <ColorPickerWithEyedrop
                value={shape.props.backgroundColor}
                onChange={(backgroundColor) =>
                  editor.updateShape({ id: shape.id, type: shape.type, props: { backgroundColor } })
                }
              />
            </div>
            <div className="flex items-center justify-between">
              <Label>{t('style-panel.color.stroke')}</Label>
              <ColorPickerWithEyedrop
                value={shape.props.strokeColor}
                onChange={(strokeColor) =>
                  editor.updateShape({ id: shape.id, type: shape.type, props: { strokeColor } })
                }
              />
            </div>

            <div className="flex w-full flex-row items-center gap-2">
              <Label>{t('style-panel.stroke-width')}</Label>

              <div className="flex-1">
                <InputSlider
                  value={shape.props.strokeWidth}
                  onValueChange={(value) => {
                    editor.updateShape({
                      id: shape.id,
                      type: shape.type,
                      props: { strokeWidth: value },
                    })
                  }}
                  min={0}
                  max={25}
                />
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
