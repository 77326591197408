export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max)
}

export const coerceToNumber = (value: string | number, defaultValue: number = 0) => {
  if (typeof value === 'number') {
    return value
  }

  const parsed = parseFloat(value)
  return isNaN(parsed) || !isFinite(parsed) ? defaultValue : parsed
}

export const closeTo = (a: number, b: number, epsilon: number = 0.00001) => {
  return Math.abs(a - b) < epsilon
}

export function roundMultipleOf(value: number, multipleOf: number) {
  return Math.round(value / multipleOf) * multipleOf
}

export function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const MAX_SD_SEED = Number.MAX_SAFE_INTEGER
