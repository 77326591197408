import { Form } from '@/components/ui/form'
import { GenerationStateForm, generationStateSchema } from '@/states/Generation'
import { LOCALSTORAGE_KEYS } from '@/utils/consts'
import { zodResolver } from '@hookform/resolvers/zod'
import { UseFormReturn, useForm } from 'react-hook-form'
import { toast } from 'sonner'

type Form = UseFormReturn<GenerationStateForm>

const getPlainDefaults = () => ({
  prompts:
    'highres, 4k, high quality, anime style, 1girl, long hair, blue eyes, white dress, holding a sword, standing in a field of flowers',
  negativePrompts:
    'worst quality, low quality, manga, portrait, low contrast, painting, surrealism, camera, anime, illustration, low poly, plain, simple, monochrome, industrial, mechanical, geometric patterns, drab, boring, moody, serious',
  steps: 25,
  width: 768,
  height: 1280,
  modelId: '1709400693561386681', // Sunflower
  batchSize: 4,
  cfgScale: 7,
  loras: [],
  samplingMethod: 'Euler a',
  useHyperSD: false,
  ipAdapter: {
    referenceImageMedias: [],
  },

  styleMix: {
    enabled: false,
    weight: 1.0,
  },
})

const getFormDefaultValues = (): GenerationStateForm => {
  try {
    const saved = localStorage.getItem(LOCALSTORAGE_KEYS.GENBOX_FORM)
    if (!saved) {
      return getPlainDefaults()
    }
    const parsed = JSON.parse(saved) as GenerationStateForm
    const parseMergedDefaults = { ...getPlainDefaults(), ...parsed }
    console.info('Loaded saved form', parsed, parseMergedDefaults)
    return generationStateSchema.parse(parseMergedDefaults)
  } catch (e) {
    toast.error(
      'Unable to parse saved generation parameters: data structure may have changed since your last visit. Using defaults.'
    )
    console.warn('Failed to load saved form, using defaults', e)
  }

  return getPlainDefaults()
}

export const useGenBoxForm = () => {
  const defaultValues = getFormDefaultValues()
  return useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: async (data, context, options) => {
      // you can debug your validation schema here
      return zodResolver(generationStateSchema)(data, context, options)
    },
    defaultValues,
  }) as Form
}
