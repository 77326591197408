import { Toaster } from '@/components/ui/sonner'
import { TooltipProvider } from '@/components/ui/tooltip'
import { CacheRehydrationGuard } from '@/services/persistWrapper'
import { AuthDialogProvider } from '@/states/AuthDialogProvider'
import { EditorProvider } from '@/states/EditorProvider'
import { GenerationStateProvider } from '@/states/Generation'
import { RightDrawerStateProvider } from '@/states/RightDrawerStateProvider'
import './i18n'
import { AlertDialogProvider } from './components/ui/AlertDialogProvider'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { LOCALSTORAGE_KEYS } from './utils/consts'
import { CONFIG } from './utils/config'
import { AppRoot } from './components/AppRoot'

export default function App() {
  const [fontSizeSettings] = useLocalStorage(
    LOCALSTORAGE_KEYS.FONT_SIZE,
    CONFIG.DEFAULT_FONT_SIZE.toString()
  )
  useEffect(() => {
    document.documentElement.style.fontSize = fontSizeSettings + 'px'
  }, [])

  return (
    <CacheRehydrationGuard>
      <TooltipProvider delayDuration={0}>
        <EditorProvider>
          <GenerationStateProvider>
            <AuthDialogProvider>
              <AlertDialogProvider>
                <RightDrawerStateProvider>
                  <Toaster />
                  <AppRoot />
                </RightDrawerStateProvider>
              </AlertDialogProvider>
            </AuthDialogProvider>
          </GenerationStateProvider>
        </EditorProvider>
      </TooltipProvider>
    </CacheRehydrationGuard>
  )
}
