import { NestedPanel } from '@/components/global/NestedPanel'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import { GenerationModelType, ListGenerationModelsQuery } from '@/services/__generated__/graphql'
import {
  QUERY_GENERATION_MODEL_VERSION,
  QUERY_MY_BOOKMARKED_GENERATION_MODELS,
} from '@/services/model'
import { GenerationStateForm } from '@/states/Generation'
import { useRightDrawerState } from '@/states/RightDrawerStateProvider'
import { ConnectionNode, getNodesFromConnection } from '@/utils/graphql'
import { getThumbnailUrl } from '@/utils/url'
import { useLoadable } from '@/utils/useLoadable'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import { forwardRef, Fragment, HTMLAttributes, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import RadixIconsCheck from '~icons/radix-icons/check'

export type ModelContentProps = HTMLAttributes<HTMLElement> & {
  model?: ConnectionNode<ListGenerationModelsQuery['generationModels']> | null
  loading?: boolean
  selected?: boolean
  as?: React.ElementType
}

export const ModelContent = forwardRef<HTMLButtonElement, ModelContentProps>(
  ({ model, className, loading, selected, as = 'div', ...props }: ModelContentProps, ref) => {
    const l = useLoadable<ConnectionNode<ListGenerationModelsQuery['generationModels']>>({
      data: model,
      isLoading: loading,
    })

    const Comp = as
    const { watch } = useFormContext<GenerationStateForm>()
    const modelType = watch('modelType')
    const { t } = useTranslation()
    return (
      <Comp
        className={cn(
          'flex items-center gap-2 text-left p-1 pr-2 border h-[4.5rem] w-full rounded transition relative',
          !loading && as === 'button' && 'hover:bg-white active:brightness-90',
          className
        )}
        ref={ref}
        {...props}
      >
        {l.state === 'loading' ? (
          <Fragment key="loading">
            <div className="size-16 rounded-sm bg-gray-200 object-cover" />
            <div className="flex flex-1 flex-col items-start gap-1 leading-none text-secondary-foreground">
              <div className="h-4 w-full bg-gray-200" />

              <div className="h-3 w-28 bg-gray-200" />
            </div>
          </Fragment>
        ) : (
          <Fragment key="loaded">
            <img
              src={getThumbnailUrl(l.data.media)}
              className="size-16 rounded-sm object-cover"
              draggable={false}
              alt={l.data.title}
            />
            <div className="flex flex-1 flex-col items-start gap-1 leading-none text-secondary-foreground">
              {(l.data.type === GenerationModelType.SdxlLora ||
                l.data.type === GenerationModelType.UserSdxlLora) && (
                  <span
                    className={clsx(
                      'inline-block shrink-0 rounded-sm px-1 py-0.5 text-right font-mono text-[11px] leading-none tracking-tighter text-white',
                      modelType === GenerationModelType.SdV1Model ? 'bg-red-500' : 'bg-purple-600'
                    )}
                  >
                    {modelType === GenerationModelType.SdV1Model
                      ? t('gen.lora.not-compatible')
                      : 'PixPro'}
                  </span>
                )}
              {(l.data.type === GenerationModelType.Lora ||
                l.data.type === GenerationModelType.UserLora) && (
                  <span
                    className={clsx(
                      'inline-block shrink-0 rounded-sm px-1 py-0.5 text-right font-mono text-[11px] leading-none tracking-tighter text-white',
                      modelType === GenerationModelType.SdxlModel ? 'bg-red-500' : 'bg-blue-500'
                    )}
                  >
                    {modelType === GenerationModelType.SdxlModel
                      ? t('gen.lora.not-compatible')
                      : 'PixStd'}
                  </span>
                )}
              <div className="flex w-full items-center justify-between gap-2">
                <span className="line-clamp-1 w-full" title={l.data.title}>
                  {l.data.title}
                </span>
              </div>

              <span className="text-xs text-gray-500">
                {l.data.refCount} Refs | {l.data.likedCount} Likes
              </span>
            </div>
          </Fragment>
        )}
        {selected && (
          <div className="absolute left-0 top-0 flex size-full items-center justify-center">
            <RadixIconsCheck className="size-10 text-black" />
          </div>
        )}
      </Comp>
    )
  }
)

export const ModelContentById = ({
  id,
  className,
  ...props
}: {
  id: string
} & HTMLAttributes<HTMLDivElement>) => {
  const query = useQuery(QUERY_GENERATION_MODEL_VERSION, {
    variables: {
      id,
    },
  })

  return (
    <ModelContent
      model={query.data?.generationModelVersion?.model}
      loading={query.loading}
      className={className}
      {...props}
    />
  )
}

const useDebouncedState = <T,>(initialValue: T, delay: number) => {
  const [value, setValue] = useState(initialValue)
  const [debouncedValue, setDebouncedValue] = useState(initialValue)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])

  return [debouncedValue, setValue] as const
}

export const GenBoxPanelLoraNestedPanel = () => {
  const { t } = useTranslation()
  const { closeActiveOverlay } = useRightDrawerState()
  const [keyword, setKeyword] = useDebouncedState('', 300)
  const { data, loading } = useQuery(QUERY_MY_BOOKMARKED_GENERATION_MODELS, {
    variables: {
      first: 36,
      modelTypes: [
        GenerationModelType.AnyLora,
        GenerationModelType.AnySdxlLora,
        GenerationModelType.AnyUserLora,
      ],
      keyword: keyword || undefined,
    },
    fetchPolicy: 'cache-and-network',
  })
  const { setValue, watch } = useFormContext<GenerationStateForm>()
  const loras = watch('loras')
  const currentPrompt = watch('prompts')

  const models = getNodesFromConnection(data?.me?.bookmarkedGenerationModels).filter(
    (l) => l.latestAvailableVersion?.id
  )

  return (
    <NestedPanel className="flex flex-col items-start justify-start gap-2">
      <div className="flex items-center gap-2">
        <div className="flex flex-col justify-start">
          <h4 className="text-xs text-muted-foreground">{t('gen.lora.label.add.overline')}</h4>
          <h1 className="text-lg font-bold">{t('gen.lora.label.add')}</h1>
        </div>
      </div>

      <div className="flex w-full flex-col gap-2">
        <Input placeholder={t('gen.lora.search')} onChange={(e) => setKeyword(e.target.value)} />
      </div>

      <div className="size-full space-y-2 overflow-y-auto">
        {loading
          ? Array.from({ length: 20 }).map((_, i) => <ModelContent key={`loading-${i}`} loading />)
          : models.map((lora) => {
            const selected = loras.some(
              (l) => l.modelVersionId === lora.latestAvailableVersion!.id
            )

            return (
              <div className="flex-1" key={lora.id}>
                <ModelContent
                  as="button"
                  selected={selected}
                  className={clsx(
                    'select-none',
                    selected && 'pointer-events-none cursor-not-allowed opacity-50'
                  )}
                  model={lora}
                  onClick={() => {
                    setValue('loras', [
                      ...loras,
                      {
                        modelVersionId: lora.latestAvailableVersion!.id,
                        weight: 0.75,
                      },
                    ])
                    if (lora.latestAvailableVersion!.extra.triggerWords) {
                      setValue(
                        'prompts',
                        currentPrompt +
                        (!currentPrompt ||
                          currentPrompt.endsWith(',') ||
                          currentPrompt.endsWith(', ')
                          ? ''
                          : ', ') +
                        lora.latestAvailableVersion!.extra.triggerWords
                      )
                    }
                    closeActiveOverlay('lora')
                  }}
                />
              </div>
            )
          })}
      </div>
    </NestedPanel>
  )
}
