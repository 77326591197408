import { InputSlider } from '@/components/ui/input-slider'
import { Label } from '@/components/ui/label'
import { PSFocusLinesShape, useEditor } from '@troph-team/tldraw'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const FocusLinesStylesControls: FC<{
  shape: PSFocusLinesShape
}> = ({ shape }) => {
  const { t } = useTranslation()

  const editor = useEditor()

  return (
    <div className="mt-2 flex flex-col gap-1">
      <Label>{t('style-panel.focuslines.center-x')}</Label>

      <InputSlider
        onValueChange={(value) => {
          editor.updateShape({
            id: shape.id,
            type: shape.type,
            props: {
              centerX: value,
            },
          })
        }}
        value={shape.props.centerX}
        min={0}
        max={1}
        step={0.1}
      />
      <Label>{t('style-panel.focuslines.center-y')}</Label>

      <InputSlider
        onValueChange={(value) => {
          editor.updateShape({
            id: shape.id,
            type: shape.type,
            props: {
              centerY: value,
            },
          })
        }}
        value={shape.props.centerY}
        min={0}
        max={1}
        step={0.1}
      />
      <Label>{t('style-panel.focuslines.center-width')}</Label>

      <InputSlider
        onValueChange={(value) => {
          editor.updateShape({
            id: shape.id,
            type: shape.type,
            props: {
              centerWidth: value,
            },
          })
        }}
        value={shape.props.centerWidth}
        min={0}
        max={1}
        step={0.1}
      />
      <Label>{t('style-panel.focuslines.center-height')}</Label>

      <InputSlider
        onValueChange={(value) => {
          editor.updateShape({
            id: shape.id,
            type: shape.type,
            props: {
              centerHeight: value,
            },
          })
        }}
        value={shape.props.centerHeight}
        min={0}
        max={1}
        step={0.1}
      />
    </div>
  )
}
