import { gql } from '@/services/__generated__'

export const QUERY_GENERATION_MODELS = gql(`
  query ListGenerationModels(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $orderBy: String
    $isNsfw: Boolean
    $isSafeSearch: Boolean
    $isPrivate: Boolean
    $tag: String
    $type: GenerationModelType
    $types: [GenerationModelType]
    $timeRange: DateRange
    $keyword: String
    $authorName: String
    $feed: String
    # filter
    $authorId: ID
  ) {
    generationModels(
      before: $before
      after: $after
      first: $first
      last: $last
      orderBy: $orderBy
      isNsfw: $isNsfw
      isSafeSearch: $isSafeSearch
      isPrivate: $isPrivate
      tag: $tag
      type: $type
      types: $types
      timeRange: $timeRange
      keyword: $keyword
      authorName: $authorName
      feed: $feed
      authorId: $authorId
    ) {
      edges {
        node {
          ...GenerationModelInFeed
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
`)

export const QUERY_MY_BOOKMARKED_GENERATION_MODELS = gql(`
  query ListMyBookmarkedGenerationModels(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $modelType: GenerationModelType
    $modelTypes: [GenerationModelType]
    $keyword: String
  ) {
    me {
      bookmarkedGenerationModels(
        before: $before
        after: $after
        first: $first
        last: $last
        modelType: $modelType
        modelTypes: $modelTypes
        keyword: $keyword
      ) {
        edges {
          node {
            ...GenerationModelInFeed
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        totalCount
      }
    }
  }
`)

export const QUERY_GENERATION_MODEL = gql(`
  query QueryGenerationModel($id: ID!) {
    generationModel(id: $id) {
      ...GenerationModelInFeed
    }
  }
`)

export const QUERY_GENERATION_MODEL_VERSION = gql(`
  query QueryGenerationModelVersion($id: ID!) {
    generationModelVersion(id: $id) {
      ...ModelFull
    }
  }
`)

export const FRAGMENT_MODEL_FULL = gql(`
  fragment ModelFull on GenerationModelVersion {
    ...GenerationModelVersionDetail
    model {
      ...GenerationModelInFeed
    }
  }
`)
