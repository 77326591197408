export const LOCALSTORAGE_KEYS = {
  PIXAI_JWT: 'pixstudio-pixai-jwt',
  GENBOX_FORM: 'pixstudio-genbox-form',
  GENBOX_AUTOCOMPLETE: 'pixstudio-genbox-autocomplete',
  GENERATION_PRIORITY: 'pixstudio-generation-priority',
  RIGHT_DRAWER_WIDTH: 'pixstudio-right-drawer-width',
  CANVAS: 'pixstudio-canvas-state',
  FONT_SIZE: 'pixstudio-font-size',
}

export const ELEMENT_IDS = {
  CANVAS_ROOT: 'pixstudio-canvas-root',
  CANVAS_TOOLBAR: 'pixstudio-canvas-toolbar',
  SHELL_PRELOADER: 'pixstudio-shell-preloader',
  SHELL_PRELOADER_CONTENT_TEXT: 'pixstudio-shell-preloader-content-text',
}

export const ELEMENT_ID_SETS = {
  CANVAS_ACTIONS: [ELEMENT_IDS.CANVAS_TOOLBAR],
}

export const MODEL_IDS = {
  /** "y10" model by nyanko */
  Y10: '1772043569905266745',
}

export const MODEL_VERSION_IDS = {
  /** "y10" model by nyanko */
  Y10: '1772043571096449082',
}
