export interface Connection<T> {
  edges?: (Edge<T> | undefined | null)[] | null
}

export interface Edge<T> {
  node?: T | null
}

export const getNodesFromConnection = <T>(connection?: Connection<T> | null): T[] => {
  return connection?.edges?.map((edge) => edge?.node).filter<T>((i): i is T => i as any) ?? []
}

export type ConnectionNode<C extends Connection<any>> = C extends Connection<infer T> ? T : never
