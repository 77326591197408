import { gql } from './__generated__/gql'

export const SUBSCRIPTION_PERSONAL_EVENTS = gql(`
  subscription subscribePersonalEvents {
    personalEvents {
      taskUpdated {
        id
        userId
        parameters
        outputs
        status
        priority
        runnerId
        startedAt
        endAt
        createdAt
        updatedAt
        retryCount
        media {
          ...MediaBase
        }
      }
    }
  }
`)

export const MUTATION_CREATE_GENERATION_TASK = gql(`
  mutation createGenerationTask($parameters: JSONObject!) {
    createGenerationTask(parameters: $parameters) {
      id
      status
      parameters
    }
  }
`)

export const QUERY_GET_TASK_BY_ID = gql(`
  query getTaskById($id: ID!) {
    task(id: $id) {
      ...TaskDetail
    }
  }
`)
